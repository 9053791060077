import {
  ConfirmDialog,
  TableCellProps,
  WbBadge,
  WbCardActionButton,
  WbTable,
  WbTableFilters,
} from '@agilelab/plugin-wb-platform';
import { RoleAssignments } from '@agilelab/plugin-wb-rbac-common';
import React from 'react';
import { ErrorPanel, Progress } from '@backstage/core-components';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useAssignmentsContext } from './AssignmentsContext';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(
  theme => ({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexBox: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  }),
  { name: 'AssignmentsTable' },
);

interface AssignmentsTableProps {}

export const DeleteConfirmDialog = () => {
  const {
    selectedItems,
    setSelectedItems,
    removeAssignments,
    setOpenConfirmDialog,
    openConfirmDialog,
  } = useAssignmentsContext();

  return (
    <ConfirmDialog
      title="Remove permission"
      description={`Are you sure you want to remove ${selectedItems.length} assignments from this role`}
      onConfirm={() => {
        removeAssignments(selectedItems.map(i => i.userOrGroups.urn));
        setSelectedItems([]);
        setOpenConfirmDialog(false);
      }}
      open={openConfirmDialog}
      onClose={() => setOpenConfirmDialog(false)}
      confirmButtonText="Remove"
    />
  );
};

const AssignmentsActions: React.FC = () => {
  const {
    selectedItems,
    setOpenConfirmDialog,

    canEditRolePermission,
  } = useAssignmentsContext();

  return (
    <>
      <Box display="flex" style={{ gap: '8px' }} height="fit-content">
        {canEditRolePermission && !!selectedItems?.length && (
          <WbCardActionButton
            label="Remove selected"
            startIcon={<CancelIcon />}
            onClick={() => setOpenConfirmDialog(true)}
            color="secondary"
            size="small"
            variant="outlined"
          />
        )}
      </Box>
    </>
  );
};

export const AssignmentsCardHeader: React.FC = () => {
  const classes = useStyles();
  const { selectedItems, setSelectedItems, filters, setFilters } =
    useAssignmentsContext();

  return (
    <Box className={classes.header}>
      <Box className={classes.flexBox}>
        <WbTableFilters
          style={{ marginBottom: '0' }}
          searchValue={filters.text}
          onSearch={search => {
            setFilters({ text: search });
          }}
          onClear={() => setFilters({})}
        />
        {selectedItems && selectedItems?.length > 0 && (
          <Box className={classes.flexBox}>
            <Typography variant="body2" style={{ fontWeight: 'medium' }}>
              {selectedItems.length} Assignments selected
            </Typography>
            <WbCardActionButton
              variant="text"
              label="Clear selection"
              onClick={() => setSelectedItems([])}
            />
          </Box>
        )}
      </Box>
      <AssignmentsActions />
    </Box>
  );
};

export const AssignmentsTable: React.FC<AssignmentsTableProps> = () => {
  const {
    selectedItems,
    setSelectedItems,
    assignmentsLoaded,
    canEditRolePermission,
  } = useAssignmentsContext();

  const classes = useStyles();

  const columns: TableCellProps<RoleAssignments>[] = [
    {
      field: 'userOrGroups',
      cellProps: {
        size: 'small',
        align: 'left',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'user and groups',
      fieldRender: field => (
        <Box className={classes.flexBox}>
          {field.userOrGroups.entity.kind === 'User' ? (
            <PersonIcon fontSize="small" />
          ) : (
            <GroupIcon fontSize="small" />
          )}
          <Typography variant="body2" style={{ fontWeight: '500' }}>
            {(field.userOrGroups?.entity.spec?.profile as any)?.displayName ??
              field.userOrGroups.entity.metadata.name}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'scope',
      cellProps: {
        size: 'small',
        align: 'left',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'scope',
      fieldRender: field => (
        <Box key={`${field.roleId}-scopes`} className={classes.flexBox}>
          {field.scope.length ? (
            field.scope.map(s => (
              <WbBadge key={s.entity.metadata.name}>
                {s.entity.metadata.name}
              </WbBadge>
            ))
          ) : (
            <WbBadge key="all">All</WbBadge>
          )}
        </Box>
      ),
    },
  ];

  if (assignmentsLoaded.error)
    return (
      <ErrorPanel
        error={
          assignmentsLoaded.error instanceof Error
            ? assignmentsLoaded.error
            : new Error('Something went wrong')
        }
      />
    );

  const getRowId = (r: RoleAssignments) => r.userOrGroups.urn;

  return (
    <WbTable<RoleAssignments>
      selection={canEditRolePermission ? 'multiple' : undefined}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      styles={{
        container: { height: '100%', flex: '1 1 0' },
      }}
      components={{
        tableLoader: {
          loading: assignmentsLoaded.loading,
          loader: <Progress />,
        },
        tableContent: {
          columns,
          rows: assignmentsLoaded.value,
          getRowId,
        },
      }}
    />
  );
};
