import _ from 'lodash';

export type PathValuePair = {
  path: string;
  value: any;
};

export function upsertValues(
  obj: Record<string, any>,
  paths: PathValuePair[],
): Record<string, any> {
  const newObj = _.cloneDeep(obj);

  paths.forEach(({ path, value }) => {
    // Remove 'root_' prefix
    const cleanPath = path.replace(/^root_/, '');

    // Convert path to lodash-compatible path format
    const pathSegments = cleanPath.split('_').map((segment, index, array) => {
      if (/^\d+$/.test(segment)) {
        return parseInt(segment, 10);
      }
      // Convert first index to array notation if necessary
      if (index === 1 && array.length > 2) {
        return `[${segment}]`;
      }
      return segment;
    });

    // Ensure arrays where necessary
    if (pathSegments.length > 1 && pathSegments[1] === '[0]') {
      _.set(newObj, pathSegments[0], [{}]);
      pathSegments[1] = '0';
    }

    _.set(newObj, pathSegments.join('.'), value);
  });

  return newObj;
}

export function getValue(
  formData: Record<string, any>,
  path: string,
): any | null {
  // Remove 'root_' prefix
  const cleanPath = path.replace(/^root_/, '');

  // Convert path to lodash-compatible path format
  const pathSegments = cleanPath.split('_').map((segment, index, array) => {
    if (/^\d+$/.test(segment)) {
      return parseInt(segment, 10);
    }
    // Convert first index to array notation if necessary
    if (index === 1 && array.length > 2) {
      return `[${segment}]`;
    }
    return segment;
  });

  return _.get(formData, pathSegments.join('.'), null);
}
