import { AlertApi } from '@backstage/core-plugin-api';
import { CustomErrorBase } from '@backstage/errors';

export function handleWittyError(error: unknown, alertApi: AlertApi): void {
  if (error instanceof Error) {
    alertApi.post({
      message: `Witty is experiencing some problems. Please contact the Platform Team. Reason: ${error.message}`,
      severity: 'error',
    });
  } else if (typeof error === 'string') {
    alertApi.post({
      message: `Witty is experiencing some problems. Please contact the Platform Team. Reason: ${error}`,
      severity: 'error',
    });
  } else if (error instanceof CustomErrorBase) {
    alertApi.post({
      message: `Witty is experiencing some problems. Please contact the Platform Team. Reason: ${error.message}`,
      severity: 'error',
    });
  } else {
    let errorString: string = '[Could not stringify error]';
    try {
      errorString = JSON.stringify(error);
    } catch (e) {
      // ignore
    }
    alertApi.post({
      message: `Witty is experiencing some problems. Please contact the Platform Team. Reason: ${errorString}`,
      severity: 'error',
    });
  }
}
