import {
  WittyAssistantChatRequest,
  WittyAssistantChatResponse,
} from '@agilelab/plugin-wb-witty-common';
import {
  ApiRef,
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

export type WittyAssistantApi = {
  chat(request: WittyAssistantChatRequest): Promise<WittyAssistantChatResponse>;
};

export class WittyAssistantClient implements WittyAssistantApi {
  constructor(
    private readonly fetchApi: FetchApi,
    private readonly discoveryApi: DiscoveryApi,
  ) {}

  async chat(
    request: WittyAssistantChatRequest,
  ): Promise<WittyAssistantChatResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('witty');

    const response = await this.fetchApi.fetch(`${baseUrl}/v1/chat`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(request),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }
}

export const wittyAssistantApiRef: ApiRef<WittyAssistantApi> =
  createApiRef<WittyAssistantApi>({
    id: 'wb-witty-assistant',
  });
