import {
  builderSoftwareCatalogView,
  cgpEntityEdit,
  cgpEntityView,
  CompletePermission,
  platformCustomViewEditPermission,
  platformSettingsEditPermission,
  practiceShaperEditPermission,
  UserPermissionsMap,
} from '@agilelab/plugin-wb-rbac-common';
import {
  configApiRef,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  catalogEntityCreatePermission,
  catalogEntityReadPermission,
  catalogLocationCreatePermission,
  catalogLocationReadPermission,
} from '@backstage/plugin-catalog-common/alpha';
import React, { useContext, useMemo, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { useIsGuestUser } from '../hooks';

export interface Features {
  listPageEnabled: boolean;
  meshSupervisionEnabled: boolean;
  governanceEnabled: boolean;
  catalogEnabled: boolean;
  templatesEnabled: boolean;
  blueprintsEnabled: boolean;
  builderEnabled: boolean;
  softwareCatalogEnabled: boolean;
  platformSettingsEnabled: boolean;
  platformSupervisionEnabled: boolean;
  practiceShaperEnabled: boolean;
  customViewEditEnabled: boolean;
  dataContractsPageEnabled: boolean;
  externalResourcesEnabled: boolean;
  marketplaceCatalogEnabled: boolean;
  marketplaceGraphEnabled: boolean;
  marketplaceEnabled: boolean;
  prototypePageEnabled: boolean;
  notificationsMenuEnabled: boolean;
  dataContractsOverviewPageEnabled: boolean;
  wittyAssistantEnabled: boolean;
}

export interface EnabledFeaturesType {
  loading: boolean;
  error: Error | undefined;
  data: Features;
}

export const EnabledFeatures = React.createContext<EnabledFeaturesType>(
  {} as EnabledFeaturesType,
);

export interface EnabledFeaturesProviderProps {
  children?: React.ReactNode;
}

export const EnabledFeaturesProvider: React.FC<
  EnabledFeaturesProviderProps
> = ({ children }) => {
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);
  const fetchApi = useApi(fetchApiRef);

  const permissionsEnabled =
    configApi.getOptionalBoolean('permission.enabled') ?? false;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [permissions, setPermissions] = useState<UserPermissionsMap>(
    UserPermissionsMap.fromPermissions([]),
  );

  useAsync(async () => {
    try {
      const identity = await identityApi.getCredentials();

      const response = await fetchApi.fetch(
        `${await discoveryApi.getBaseUrl('rbac')}/permissions`,
        {
          headers: identity.token
            ? { authorization: `Bearer ${identity.token}` }
            : {},
        },
      );

      const data = (await response.json()) as {
        userPermissions: CompletePermission[];
      };

      setPermissions(UserPermissionsMap.fromPermissions(data.userPermissions));
      setLoading(false);
    } catch (fetchError) {
      setError(fetchError);
    }
  }, [discoveryApi, identityApi]);

  const { data: isGuestLogin } = useIsGuestUser();
  const noGuestLogin = !isGuestLogin;

  const enabled = {
    config: {
      listPageEnabled:
        configApi.getOptionalBoolean('mesh.marketplace.ui.listPage.enabled') ??
        false,

      meshSupervision:
        configApi.getOptionalBoolean(
          'mesh.marketplace.ui.meshSupervisionPage.enabled',
        ) ?? true,

      platformSupervision:
        configApi.getOptionalBoolean('supervisionDemo.enabled') ?? false,

      prototypePageEnabled:
        configApi.getOptionalBoolean('mesh.builder.ui.prototypePage.enabled') ??
        false,

      blueprints:
        configApi.getOptionalBoolean(
          'mesh.builder.scaffolder.blueprints.enabled',
        ) ?? true,

      platformSettings:
        configApi.getOptionalBoolean('mesh.platformSettings.enabled') ?? false,

      dataContractsPage:
        configApi.getOptionalBoolean(
          'mesh.marketplace.ui.dataContracts.page.enabled',
        ) ?? false,

      dataContractsOverviewPage:
        configApi.getOptionalBoolean(
          'mesh.marketplace.ui.dataContractsOverview.page.enabled',
        ) ?? false,

      externalResources:
        configApi.getOptionalBoolean('mesh.externalResources') ?? false,

      marketplaceEnabled:
        configApi.getOptionalBoolean('mesh.marketplace.enabled') ?? true,

      wittyAssistantEnabled:
        configApi.getOptionalBoolean('witty.assistant.enabled') ?? false,
    },

    permission: {
      templates:
        !permissionsEnabled ||
        permissions.hasPermission(catalogEntityCreatePermission.name) ||
        permissions.hasPermission(catalogLocationCreatePermission.name),

      catalog:
        !permissionsEnabled ||
        permissions.hasPermission(catalogEntityReadPermission.name) ||
        permissions.hasPermission(catalogLocationReadPermission.name),

      platformSettings:
        !permissionsEnabled ||
        permissions.hasPermission(platformSettingsEditPermission.name),

      practiceShaper:
        !permissionsEnabled ||
        permissions.hasPermission(practiceShaperEditPermission.name),

      governance:
        !permissionsEnabled ||
        permissions.hasPermission(cgpEntityEdit.name) ||
        permissions.hasPermission(cgpEntityView.name),

      softwareCatalog:
        !permissionsEnabled ||
        permissions.hasPermission(builderSoftwareCatalogView.name),
      customViewEdit:
        !permissionsEnabled ||
        permissions.hasPermission(platformCustomViewEditPermission.name),
    },
  };

  // Marketplace sidebar
  const marketplaceCatalogEnabled = noGuestLogin;
  const marketplaceGraphEnabled = noGuestLogin;

  const dataContractsPageEnabled =
    noGuestLogin && enabled.config.dataContractsPage;

  const marketplaceEnabled =
    enabled.config.marketplaceEnabled &&
    (marketplaceGraphEnabled ||
      marketplaceCatalogEnabled ||
      dataContractsPageEnabled);

  const dataContractsOverviewPageEnabled =
    noGuestLogin && enabled.config.dataContractsOverviewPage;

  // Builder sidebar
  const catalogEnabled = noGuestLogin && enabled.permission.catalog;
  const templatesEnabled = noGuestLogin && enabled.permission.templates;
  const blueprintsEnabled =
    noGuestLogin && enabled.permission.templates && enabled.config.blueprints;
  const builderEnabled =
    catalogEnabled || templatesEnabled || blueprintsEnabled;
  const softwareCatalogEnabled = enabled.permission.softwareCatalog;
  const prototypePageEnabled = enabled.config.prototypePageEnabled;

  // Governance sidebar
  const governanceEnabled = noGuestLogin && enabled.permission.governance;

  // Notifications and Settings sidebar
  const meshSupervisionEnabled = noGuestLogin && enabled.config.meshSupervision;
  const practiceShaperEnabled =
    noGuestLogin && enabled.permission.practiceShaper;

  const customViewEditEnabled =
    noGuestLogin && enabled.permission.customViewEdit;

  const platformSupervisionEnabled =
    noGuestLogin && enabled.config.platformSupervision;

  const notificationsMenuEnabled = noGuestLogin;
  const platformSettingsEnabled =
    noGuestLogin &&
    enabled.config.platformSettings &&
    enabled.permission.platformSettings;

  // External resource sidebar
  const externalResourcesEnabled =
    noGuestLogin && enabled.config.externalResources;

  const listPageEnabled = enabled.config.listPageEnabled;

  // Witty
  const wittyAssistantEnabled = enabled.config.wittyAssistantEnabled;

  const contextValue = useMemo(
    () => ({
      loading,
      error,
      data: {
        listPageEnabled,
        meshSupervisionEnabled,
        platformSupervisionEnabled,
        practiceShaperEnabled,
        customViewEditEnabled,
        governanceEnabled,
        catalogEnabled,
        templatesEnabled,
        blueprintsEnabled,
        builderEnabled,
        softwareCatalogEnabled,
        prototypePageEnabled,
        platformSettingsEnabled,
        dataContractsPageEnabled,
        externalResourcesEnabled,
        marketplaceCatalogEnabled,
        marketplaceGraphEnabled,
        marketplaceEnabled,
        notificationsMenuEnabled,
        dataContractsOverviewPageEnabled,
        wittyAssistantEnabled,
      },
    }),
    [
      loading,
      error,
      meshSupervisionEnabled,
      platformSupervisionEnabled,
      practiceShaperEnabled,
      customViewEditEnabled,
      governanceEnabled,
      catalogEnabled,
      templatesEnabled,
      blueprintsEnabled,
      builderEnabled,
      softwareCatalogEnabled,
      prototypePageEnabled,
      platformSettingsEnabled,
      dataContractsPageEnabled,
      externalResourcesEnabled,
      marketplaceCatalogEnabled,
      marketplaceGraphEnabled,
      marketplaceEnabled,
      listPageEnabled,
      notificationsMenuEnabled,
      dataContractsOverviewPageEnabled,
      wittyAssistantEnabled,
    ],
  );

  return (
    <EnabledFeatures.Provider value={contextValue}>
      {children}
    </EnabledFeatures.Provider>
  );
};

export const useEnabledFeatures = () => useContext(EnabledFeatures);
