import { WitboostEntity } from '@agilelab/plugin-wb-builder-common';
import {
  NO_EDIT_TEMPLATE_FOUND_ERROR,
  tooManyEditTemplatesError,
} from '@agilelab/plugin-wb-platform-common';
import { CatalogApi } from '@backstage/catalog-client';
import { Entity } from '@backstage/catalog-model';

export async function checkExistingEditTemplate(options: {
  catalogApi: CatalogApi;
  entity: Entity;
  setErrorMessage: (message: string) => void;
}) {
  const { catalogApi, setErrorMessage, entity } = options;
  try {
    const { items } = await catalogApi.getEntities({
      filter: {
        kind: 'EditTemplate',
        'spec.useCaseTemplateId': (entity as WitboostEntity).spec.mesh
          .useCaseTemplateId,
      },
    });
    if (items.length === 0) {
      setErrorMessage(NO_EDIT_TEMPLATE_FOUND_ERROR);
    } else {
      const foundTemplateVersions = new Set<string>();

      for (const templateEntity of items) {
        if (foundTemplateVersions.has(templateEntity.apiVersion)) {
          setErrorMessage(tooManyEditTemplatesError(templateEntity.apiVersion));
          return;
        }
        foundTemplateVersions.add(templateEntity.apiVersion);
      }
    }
  } catch (error) {
    setErrorMessage(error.message);
  }
}

export function generateTestTooltip(
  hasPermissions: boolean,
  actionError: string | undefined,
  loading: boolean,
) {
  if (loading)
    return 'Descriptor is still loading, please wait until it is ready';
  if (actionError) {
    return actionError;
  }
  if (!hasPermissions) {
    return 'You are not allowed run tests for the selected release and environment.';
  }
  return 'Test the current descriptor for the selected environment. The test will verify that the descriptor is compliant with all the governance policies';
}
