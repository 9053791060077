import React from 'react';

export const WittyEmptyChatIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={536}
    height={184}
    viewBox="0 0 536 184"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.25} filter="url(#filter0_f_84_691)">
      <ellipse
        cx={268}
        cy={163.5}
        rx={77}
        ry={12.5}
        fill="url(#paint0_radial_84_691)"
      />
    </g>
    <rect x={189} y={1} width={158} height={158} rx={79} fill="white" />
    <rect
      x={189}
      y={1}
      width={158}
      height={158}
      rx={79}
      stroke="url(#paint1_linear_84_691)"
      strokeWidth={2}
      strokeDasharray="4 4"
    />
    <path
      d="M313.976 26.8058C314.24 26.1759 315.234 26.1759 315.499 26.8058C316.048 28.1146 316.826 29.7229 317.605 30.4939C318.389 31.2696 319.949 32.0453 321.203 32.5894C321.821 32.8576 321.821 33.8085 321.203 34.0767C319.949 34.6207 318.389 35.3964 317.605 36.1721C316.826 36.943 316.048 38.5514 315.499 39.8602C315.234 40.4901 314.24 40.4901 313.976 39.8602C313.427 38.5514 312.648 36.943 311.869 36.1721C311.09 35.4012 309.465 34.6302 308.143 34.0867C307.507 33.8251 307.507 32.841 308.143 32.5794C309.465 32.0358 311.09 31.2648 311.869 30.4939C312.648 29.7229 313.427 28.1146 313.976 26.8058Z"
      fill="url(#paint2_linear_84_691)"
    />
    <path
      d="M289.917 26.8538C289.595 26.1598 288.464 26.1598 288.142 26.8538C287.777 27.6417 287.336 28.4414 286.895 28.8793C286.455 29.3172 285.65 29.7551 284.857 30.1184C284.159 30.4383 284.159 31.5613 284.857 31.8812C285.65 32.2444 286.455 32.6823 286.895 33.1202C287.336 33.5581 287.777 34.3578 288.142 35.1457C288.464 35.8397 289.595 35.8397 289.917 35.1457C290.282 34.3578 290.723 33.5581 291.163 33.1202C291.61 32.6765 292.396 32.2328 293.158 31.8668C293.836 31.5413 293.836 30.4584 293.158 30.1328C292.396 29.7668 291.61 29.3231 291.163 28.8793C290.723 28.4414 290.282 27.6417 289.917 26.8538Z"
      fill="url(#paint3_linear_84_691)"
    />
    <path
      d="M301.511 36.0998C301.29 35.5243 300.412 35.5243 300.191 36.0998C299.256 38.5343 297.457 42.7681 295.659 44.5429C293.86 46.3177 289.57 48.0926 287.104 49.0161C286.52 49.2344 286.52 50.1009 287.104 50.3192C289.57 51.2424 293.86 53.017 295.659 54.7919C297.457 56.5668 299.256 60.8007 300.191 63.2351C300.412 63.8105 301.29 63.8105 301.511 63.2351C302.447 60.8007 304.245 56.5668 306.044 54.7919C307.847 53.0127 311.921 51.2332 314.242 50.3121C314.809 50.0871 314.809 49.2479 314.242 49.023C311.921 48.1018 307.847 46.3224 306.044 44.5429C304.245 42.7681 302.447 38.5343 301.511 36.0998Z"
      fill="url(#paint4_linear_84_691)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M289.154 40.1402C282.804 36.9054 275.615 35.0815 268 35.0815C242.227 35.0815 221.333 55.975 221.333 81.7485C221.333 107.522 242.227 128.416 268 128.416C293.773 128.416 314.667 107.522 314.667 81.7485C314.667 74.3397 312.94 67.3341 309.868 61.1121C306.859 65.2098 304.038 71.9105 302.456 76.0285C302.015 77.1759 300.265 77.1759 299.824 76.0285C297.959 71.1749 294.374 62.7337 290.788 59.1951C287.203 55.6564 278.651 52.1183 273.733 50.2777C272.57 49.8425 272.57 48.1148 273.733 47.6796C278.01 46.0783 285.037 43.1929 289.154 40.1402ZM295.344 81.7485C295.344 96.8503 283.101 109.093 268 109.093C252.899 109.093 240.656 96.8503 240.656 81.7485C240.656 66.6467 252.899 54.4045 268 54.4045C283.101 54.4045 295.344 66.6467 295.344 81.7485Z"
      fill="url(#paint5_linear_84_691)"
    />
    <defs>
      <filter
        id="filter0_f_84_691"
        x={183}
        y={143}
        width={170}
        height={41}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={4}
          result="effect1_foregroundBlur_84_691"
        />
      </filter>
      <radialGradient
        id="paint0_radial_84_691"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(268 163.5) scale(77 474.32)"
      >
        <stop stopColor="#B0BEC5" />
        <stop offset={1} stopColor="#B0BEC5" stopOpacity={0} />
      </radialGradient>
      <linearGradient
        id="paint1_linear_84_691"
        x1={188}
        y1={80}
        x2={348}
        y2={80}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE72D3" />
        <stop offset={1} stopColor="#7C4DFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_84_691"
        x1={231.833}
        y1={36.8333}
        x2={313.5}
        y2={123.167}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE72D3" />
        <stop offset={1} stopColor="#7C4DFF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_84_691"
        x1={231.833}
        y1={36.8333}
        x2={313.5}
        y2={123.167}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE72D3" />
        <stop offset={1} stopColor="#7C4DFF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_84_691"
        x1={231.833}
        y1={36.8333}
        x2={313.5}
        y2={123.167}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE72D3" />
        <stop offset={1} stopColor="#7C4DFF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_84_691"
        x1={231.833}
        y1={36.8333}
        x2={313.5}
        y2={123.167}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE72D3" />
        <stop offset={1} stopColor="#7C4DFF" />
      </linearGradient>
    </defs>
  </svg>
);
