import { Outcome, Severity } from '@agilelab/plugin-wb-governance-common';
import {
  Box,
  BoxProps,
  createStyles,
  makeStyles,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import React from 'react';
import {
  KoFilledIcon,
  NoResourcesFoundIcon,
  OkFilledIcon,
} from './outcomeIcons';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      width: '1.3em',
      height: '1.3em',
    },
  }),
);

export type OutcomeIconProps = {
  outcome: Outcome | Severity;
} & BoxProps;

export const OutcomeIcon: React.FC<OutcomeIconProps> = ({
  outcome,
  ...boxProps
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const outcomeIcon: Record<Outcome | Severity, JSX.Element> = {
    [Outcome.Ok]: (
      <OkFilledIcon
        htmlColor={theme.palette.success.main}
        className={classes.icon}
      />
    ),
    [Outcome.Error]: (
      <Tooltip title="Failed with severity Error. You need to solve the errors reported since they will prevent the deployment">
        <KoFilledIcon
          htmlColor={theme.palette.error.main}
          className={classes.icon}
        />
      </Tooltip>
    ),
    [Outcome.Info]: (
      <Tooltip title="Failed with severity Info. This policy is just a warning, and will not block the deployment, but remember to check what errors made it fail">
        <ErrorIcon
          className={classes.icon}
          htmlColor={theme.palette.info.main}
        />
      </Tooltip>
    ),
    [Outcome.Warning]: (
      <Tooltip title="Failed with severity Warning. This policy is just a warning, and will not block the deployment, but remember to check what errors made it fail">
        <WarningIcon
          className={classes.icon}
          htmlColor={theme.palette.warning.main}
        />
      </Tooltip>
    ),
    [Outcome.NotBlockingError]: (
      <Tooltip title="Failed. This policy is failed but it is not blocking the deployment. Check and solve the errors.">
        <ErrorIcon
          className={classes.icon}
          htmlColor={theme.palette.warning.main}
        />
      </Tooltip>
    ),
    [Outcome.NotExecuted]: (
      <Tooltip title="Failed. You need to solve the errors reported since they will prevent the deployment">
        <KoFilledIcon
          htmlColor={theme.palette.error.main}
          className={classes.icon}
        />
      </Tooltip>
    ),
    [Outcome.NoResource]: (
      <Tooltip title="Failed with No resource. You need to solve the errors reported since they will prevent the deployment">
        <NoResourcesFoundIcon
          className={classes.icon}
          htmlColor={theme.palette.error.main}
        />
      </Tooltip>
    ),
    [Outcome.Pending]: (
      <Tooltip title="Pending.">
        <TimelapseIcon
          className={classes.icon}
          htmlColor={theme.palette.info.main}
        />
      </Tooltip>
    ),
  };

  return (
    <Box
      display="flex"
      style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
      {...boxProps}
    >
      {outcomeIcon[outcome]}
    </Box>
  );
};
