import { Typography, useTheme } from '@material-ui/core';
import React, { CSSProperties } from 'react';

type WbFieldLabelProps = {
  label: string;
  style?: CSSProperties;
};

export const WbFieldLabel = ({ label, style }: WbFieldLabelProps) => {
  const theme = useTheme();
  const noUnderscoresLabel = label.replace(/^__/, '');

  return (
    <Typography
      style={{
        ...style,
        color: theme.palette.secondary.dark,
        fontWeight: 600,
        display: 'block',
      }}
      variant="caption"
    >
      {noUnderscoresLabel.toUpperCase()}
    </Typography>
  );
};
