import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Deploy } from '../types';
import { useControlPanel } from '../useControlPanel';
import { format, parseISO } from 'date-fns';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '../../../api';
import {
  Dag,
  DagStatus,
  isRunningStatus,
  TaskAction,
} from '@agilelab/plugin-wb-builder-common';
import { extractDagName } from '../utils';
import { customAlertApiRef } from '@agilelab/plugin-wb-platform';
import { StepIcon } from './useStepIcon';

type DeployComponentProps = {
  deploy: Deploy;
  loadDeploys: () => Promise<void>;
};

export const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    paddingLeft: '45px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '17px',
      borderLeft: '2px solid #a2a5b5',
      width: '1px',
      height: '100%',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '33px',
      left: '19px',
      borderTop: '2px solid #a2a5b5',
      width: '15px',
    },
    '&:last-child::before': {
      top: '-5px',
      height: '40px',
    },
  },
  statusWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
  },
  deployButton: {
    marginLeft: theme.spacing(2),
  },
  stepperContainer: {
    display: 'flex',
    gap: 30,
    alignItems: '',
  },
  stepperWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      position: 'relative',
      marginRight: theme.spacing(1),
      padding: 0,
    },
    '& > button:not(:last-child)::after': {
      content: '""',
      position: 'absolute',
      top: '11px',
      right: '-10px',
      width: '10px',
      borderBottom: '2px solid',
      borderBottomColor: theme.palette.grey[500],
    },
  },
}));

function Steps(props: { steps: Dag[]; deploy: Deploy }) {
  const { steps } = props;
  const classes = useStyles();
  const {
    setSelectedStep,
    setSelectedDeploy,
    setIsLogsDrawerOpen,
    selectedDeploy,
  } = useControlPanel();

  useEffect(() => {
    if (!selectedDeploy || props.deploy.id === selectedDeploy.id)
      setSelectedDeploy(props.deploy);
  }, [props.deploy, setSelectedDeploy, selectedDeploy]);

  return (
    <Box className={classes.stepperContainer}>
      <Box className={classes.stepperWrapper}>
        {steps.map((dag, i) => {
          const stepName = extractDagName(dag);

          return (
            <Tooltip title={stepName} key={i}>
              <IconButton
                key={i}
                disableRipple
                onClick={() => {
                  setSelectedStep(dag.id);
                  setSelectedDeploy(props.deploy);
                }}
              >
                <StepIcon status={dag.status} />
              </IconButton>
            </Tooltip>
          );
        })}
      </Box>

      <Button
        onClick={() => {
          setSelectedDeploy(props.deploy);
          setIsLogsDrawerOpen(true);
        }}
        variant="outlined"
        color="primary"
        size="small"
      >
        Log File
      </Button>
    </Box>
  );
}

const DeployRow: React.FC<{ deploy: Deploy }> = ({ deploy }) => {
  const classes = useStyles();
  const theme = useTheme();

  function getProvisioningLabel(deployEntity: Deploy): string {
    if (
      deployEntity.action === TaskAction.UNPROVISION ||
      deployEntity.action === TaskAction.UNPROVISION_DATAPRODUCT
    ) {
      return 'Undeploy';
    }
    return 'Deploy';
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.statusWrapper}>
        <Typography
          style={{
            color:
              // eslint-disable-next-line no-nested-ternary
              deploy.status === DagStatus.PASSED
                ? theme.palette.success.main
                : isRunningStatus(deploy.status)
                ? theme.palette.accent.main
                : theme.palette.error.main,
          }}
        >
          <strong>
            {getProvisioningLabel(deploy).toUpperCase()} {deploy.status}
          </strong>{' '}
          {deploy.version ?? deploy.idRelease}
        </Typography>
        <Typography variant="caption">
          <strong>{getProvisioningLabel(deploy)} started at</strong>
        </Typography>
        <Typography variant="caption">
          {format(parseISO(deploy.deployDate), 'yyyy/MM/dd HH:mm:ss')}
        </Typography>
      </Box>
      <Steps steps={deploy.steps} deploy={deploy} />
    </Box>
  );
};

const wait = (ms: number = 1000) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

const poll = async <T,>(
  fn: () => Promise<T>,
  fnCondition: (result: T) => boolean,
  onResult: (result: T) => void,
  onFinish?: () => void,
  ms?: number,
) => {
  let result = await fn();
  onResult(result);

  while (fnCondition(result)) {
    await wait(ms);
    result = await fn();
    onResult(result);
  }

  onFinish?.();
};

function DeployComponentDynamic(props: {
  deploy: Deploy;
  loadDeploys: () => Promise<void>;
}) {
  const { deploy, loadDeploys } = props;
  const {
    setReleaseDeployingId,
    setReleaseUndeployingId,
    refetch,
    releaseDeployingId,
    releaseUndeployingId,
  } = useControlPanel();
  const panelCatalog = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const configApi = useApi(configApiRef);
  const pollingInterval =
    configApi.getOptionalNumber(
      'mesh.provisioner.provisioning.statusPolling.interval',
    ) ?? 3000;

  const [runningDeploy, setRunningDeploy] = useState<Deploy>(deploy);

  useEffect(() => {
    const loadDeploy = () =>
      panelCatalog
        .getProvisioningPlan(deploy.id)
        .then(async provisioningPlan => {
          const result = {
            ...deploy,
            deployEndDate: provisioningPlan.dag.stopTime,
            status: provisioningPlan.dag.status,
            steps: provisioningPlan.dag.dependsOnTasks,
          };
          return result;
        });

    if (releaseDeployingId !== '' || releaseUndeployingId !== '') {
      try {
        poll(
          loadDeploy,
          d => isRunningStatus(d.status),
          d => {
            setRunningDeploy(d);
          },
          () => {
            setReleaseDeployingId('');
            setReleaseUndeployingId('');
            loadDeploys();
            refetch();
          },
          pollingInterval,
        );
      } catch (error) {
        alertApi.post({ error, severity: 'error' });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <DeployRow deploy={runningDeploy} />;
}

export function DeployComponent(props: DeployComponentProps) {
  const { deploy } = props;

  if (isRunningStatus(deploy.status)) {
    return (
      <DeployComponentDynamic deploy={deploy} loadDeploys={props.loadDeploys} />
    );
  }
  return <DeployRow deploy={deploy} />;
}
