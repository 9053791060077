import React, { useContext, useEffect } from 'react';
import { TableCellProps, WbTableComponents, WbTableSort } from '../types';

export interface WbTableContextProviderProps<T> {
  children?: React.ReactNode;
  components: WbTableComponents<T>;
  onSort: WbTableSort<T> | undefined;
  selection?: 'single' | 'multiple';
  selectedItems?: T[];
  setSelectedItems?: React.Dispatch<React.SetStateAction<T[]>>;
  checkIfDisabled?: (row: T) => boolean;
  tooltipIfDisabled?: string;
  treeProps?: {
    getRowChildren: (row: T) => T[];
    expandedRowsIds: string[];
    onExpandChange: (newExpandedIds: string[]) => void;
  };
  getRowId?: (row: T) => string;
  rowDynamicClassName?: (row: T) => string | undefined;
  onRowClick?: (
    element: T,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
}

export interface WbTableContextType<T> extends WbTableContextProviderProps<T> {
  columns: Array<TableCellProps<T>>;
  disableRowHighlightOnHover: boolean;
  rows: Array<T>;
  selection?: 'single' | 'multiple';
  selectedItems?: T[];
  setSelectedItems?: React.Dispatch<React.SetStateAction<T[]>>;
  checkIfDisabled?: (row: T) => boolean;
  tooltipIfDisabled?: string;
}

export const WbTableContext =
  React.createContext<WbTableContextType<any> | null>(null);

export const WbTableContextProvider = <T,>({
  children,
  components,
  selectedItems,
  setSelectedItems,
  selection,
  checkIfDisabled,
  tooltipIfDisabled,
  onSort,
}: WbTableContextProviderProps<T>) => {
  const columns = components?.tableContent?.columns ?? [];
  const rows = components?.tableContent?.rows ?? [];
  const onRowClick = components.tableContent?.onRowClick;
  const rowDynamicClassName = components.tableContent?.rowDynamicClassName;
  const treeProps = components.tableContent?.treeProps;
  const getRowId = components.tableContent?.getRowId;
  const disableRowHighlightOnHover =
    components.tableContent?.disableRowHighlightOnHover ?? false;

  useEffect(() => {
    if (selection === 'single') setSelectedItems?.([rows[0]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WbTableContext.Provider
      value={{
        components,
        columns,
        rows,
        onSort,
        selection,
        selectedItems,
        setSelectedItems,
        tooltipIfDisabled,
        checkIfDisabled,
        onRowClick,
        rowDynamicClassName,
        treeProps,
        getRowId,
        disableRowHighlightOnHover,
      }}
    >
      {children}
    </WbTableContext.Provider>
  );
};

export const useWbTableContext = <T,>() => {
  const context = useContext(WbTableContext);
  if (!context) {
    throw new Error(
      'useWbTableContext must be used within a WbTableContextProvider',
    );
  }
  return context as WbTableContextType<T>;
};
