import {
  CustomViewComponent,
  CustomViewPageCategory,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import componentPreview from './preview/builder_component/builder_component.png';
import componentThumbnail from './preview/builder_component/builder_component_thumbnail.png';
import entityPreview from './preview/builder_entity_content/builder_entity_content.png';
import entityThumbnail from './preview/builder_entity_content/builder_entity_content_thumbnail.png';

CustomViewPages.register({
  id: 'builder_entity_content',
  meta: {
    label: 'Builder Entity Content',
    category: CustomViewPageCategory.BUILDER,
    preview: {
      thumbnail: { src: entityThumbnail },
      items: [{ src: entityPreview }],
    },
  },
  function: function Entity() {
    return (
      <>
        <CustomViewComponent type="catalog_warnings" />
        <CustomViewComponent type="include" id="builder_system_general" />
      </>
    );
  },
});
CustomViewPages.register({
  id: 'builder_component',
  meta: {
    label: 'Component Detail Page',
    category: CustomViewPageCategory.BUILDER,
    preview: {
      thumbnail: { src: componentThumbnail },
      items: [{ src: componentPreview }],
    },
  },
  function: function Entity() {
    return (
      <>
        <CustomViewComponent type="catalog_warnings" />
        <CustomViewComponent type="include" id="builder_component_general" />
        <CustomViewComponent type="catalog_components" />
      </>
    );
  },
});
