import {
  PanelNames,
  WbDiffEditor,
  WbLogsViewer,
} from '@agilelab/plugin-wb-platform';
import { Content, StructuredMetadataTable } from '@backstage/core-components';
import { JsonObject } from '@backstage/types';
import { Box, Paper } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import React from 'react';
import yaml from 'yaml';
import { AdditionalStep, getReviewData, Logs } from './utils';
import { CodeComparisonSkeleton } from '../wizard-common/CodeComparisonSkeleton';

export const reviewStep = (
  title: string,
  nextButtonText: string,
  formState: Record<string, any>,
  steps: Array<{
    title: string;
    description?: string;
    schema: JsonObject;
  }>,
  handleFormReset: () => void,
  handleCreate: () => Promise<void>,
): AdditionalStep => ({
  title,
  component: (
    <Content>
      <Paper
        style={{ backgroundColor: 'white', wordBreak: 'break-word' }}
        square
        elevation={0}
      >
        <StructuredMetadataTable
          dense
          metadata={getReviewData(formState, steps)}
        />
        <Box mb={4} />
      </Paper>
    </Content>
  ),
  disableStepButtons: false,
  backButtonText: 'Back',
  nextButtonText: nextButtonText,
  analyticsEvent: 'create',
  resetButtonText: 'Reset',
  resetAction: handleFormReset,
  nextAction: handleCreate,
});

export const processingStep = (
  logs: Logs,
  isProcessingComplete: boolean,
  className: string,
  taskFailed: boolean,
  nextButtonText: string = 'Next step',
): AdditionalStep => ({
  title: 'Processing',
  component: (
    <Box className={className}>
      <WbLogsViewer {...logs} />
    </Box>
  ),
  disableStepButtons: !isProcessingComplete,
  customDisableStepButtons: { disableBack: false },
  backButtonText: 'Back',
  nextButtonText: nextButtonText,
  taskFailed,
});

export const comparisonStep = (options: {
  originalData: Record<string, any> | undefined;
  modifiedData: Record<string, any> | undefined;
  isLoading: boolean;
  classes: ClassNameMap<'button' | 'saveButton' | 'comparisonPageHeader'>;
  additionalActions: JSX.Element[];
  leftPanelTitle?: string;
  rightPanelTitle?: string;
  header?: JSX.Element;
}): AdditionalStep => ({
  title: 'Preview and Save',
  component: (
    <Box>
      {options.header}
      <PanelNames
        leftPanelTitle={options.leftPanelTitle ?? 'Source'}
        rightPanelTitle={options.rightPanelTitle ?? 'Modified'}
      />

      {options.isLoading ? (
        <CodeComparisonSkeleton height="calc(100vh - 305px)" />
      ) : (
        <WbDiffEditor
          height="calc(100vh - 305px)"
          width="100%"
          theme="diffEditorWizard"
          language="yaml"
          original={yaml.stringify(options.originalData)}
          modified={yaml.stringify(options.modifiedData)}
          options={{
            selectOnLineNumbers: true,
            contextmenu: false,
            readOnly: true,
            diffCodeLens: false,
            originalEditable: false,
            glyphMargin: true,
            linkedEditing: false,
            minimap: {
              enabled: false,
            },
          }}
        />
      )}
    </Box>
  ),
  additionalActions: options.additionalActions,
  resetButtonText: 'Start over',
  disableStepButtons: false,
});
