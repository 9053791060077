import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const DataContractIcon = createSvgIcon(
  <svg viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.66712 30.5987C6.64855 30.5987 5.78267 30.2422 5.06947 29.5292C4.35649 28.816 4 27.9502 4 26.9316V22.5977H8.0005V3.92871H28.003V26.9316C28.003 27.9502 27.6465 28.816 26.9335 29.5292C26.2203 30.2422 25.3544 30.5987 24.3359 30.5987H7.66712ZM24.3339 28.3761C24.7426 28.3761 25.0859 28.2378 25.3637 27.9611C25.6415 27.6841 25.7804 27.341 25.7804 26.9316V6.15132H10.2231V22.5977H22.8914V26.9316C22.8914 27.341 23.0296 27.6841 23.3061 27.9611C23.5826 28.2378 23.9252 28.3761 24.3339 28.3761ZM12.001 12.3741V10.1518H24.0025V12.3741H12.001ZM12.001 16.3746V14.1523H24.0025V16.3746H12.001Z"
      fill="#4E6980"
    />
  </svg>,
  'DataContractIcon',
);
