import { SkeletonParameters } from '@agilelab/plugin-wb-catalog-extension-common';
import { DagStatus } from './deployment';
import z from 'zod';

export type ReverseProvisioningStatus = {
  status: DagStatus;
};

export type ReverseProvisioningPlansAndMerged = {
  environment: string;
  dag: {
    status: DagStatus;
    dependsOnTasks?: [
      {
        name: string;
        status: DagStatus;
        result: {
          updates?: Record<any, any>;
        };
      },
    ];
  };
  unwrappedCatalogInfo?: Record<string, any>;
  preMergedCatalogInfo?: Record<string, any>;
};

export const GetReverseProvisioningUpdatesRequestSchema = z.object({
  reverseProvisioningOperationId: z.string(),
  /** Compatibility settings */
  compatibility: z
    .object({
      skeletonEntities: z
        .object({
          /** 
          * Used to map legacy reverse provisioning updates (targeting catalog info fields) to skeleton parameter updates.
          *
          * @example
          *  To generate an update to parameter `dataContractSchema` when an update to catalog info field `spec.mesh.dataContract.schema` is received:
          *  ```
              { 'spec.mesh.dataContract.schema': 'dataContractSchema' }
          *  ```
          */
          pathToParameterMap: z.record(z.string()).optional(),
          /**
           * Used in case of legacy reverse provisioning updates targeting catalog info fields.
           * If set to true, updates that cannot be mapped to a skeleton parameter (e.g., through `pathToParameterMap`) will be saved as skeleton overrides.
           */
          saveCatalogInfoUpdatesAsOverrides: z
            .boolean()
            .optional()
            .default(false),
        })
        .optional(),
    })
    .optional(),
});

export type GetReverseProvisioningUpdatesRequest = z.infer<
  typeof GetReverseProvisioningUpdatesRequestSchema
>;

export type GetReverseProvisioningUpdatesResponse = {
  skeletonUpdates?: {
    parameters?: {
      parameters?: SkeletonParameters['parameters'];
      overrides?: SkeletonParameters['overrides'];
    };
  };
};
