import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'rbac',
});

export const rolesPermissionsRouteRef = createSubRouteRef({
  id: 'wb-rbac-roles-permissions',
  parent: rootRouteRef,
  path: '/roles-permissions/*',
});

export const roleDetailRouteRef = createSubRouteRef({
  id: 'wb-rbac-role-detail',
  parent: rootRouteRef,
  path: '/roles-permissions/roles/:roleId/*',
});
