import React from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import {
  AiIcon,
  WbCardActionButton,
  WbCardActionButtonProps,
} from '@agilelab/plugin-wb-platform';

type AiGenerateActionButtonProps = Omit<
  WbCardActionButtonProps,
  'startIcon' | 'label'
>;

const useStyles = makeStyles<Theme, { disabled: boolean }>(
  theme => ({
    aiButton: {
      color: theme.palette.accent.main,
      backgroundColor: theme.palette.white,
      border: 'solid 1px transparent',
      background: props =>
        props.disabled
          ? `linear-gradient( #ffffff, #ffffff) padding-box,linear-gradient(90deg, ${theme.palette.grey[200]}, ${theme.palette.grey[200]}) border-box`
          : 'linear-gradient( #ffffff, #ffffff) padding-box,linear-gradient(90deg, #EE72D3, #7C4DFF) border-box',
    },
    aiIcon: {
      color: props =>
        props.disabled ? theme.palette.grey[400] : theme.palette.accent.main,
    },
  }),
  { name: 'AiGenerateActionButton' },
);

export const AiGenerateActionButton = ({
  disabled = false,
  ...props
}: AiGenerateActionButtonProps) => {
  const classes = useStyles({ disabled });

  return (
    <WbCardActionButton
      disabled={disabled}
      variant="outlined" // can be overridden with props
      {...props}
      startIcon={<AiIcon className={classes.aiIcon} />}
      label="Generate"
      className={clsx(classes.aiButton, props.className)}
    />
  );
};
