import {
  Entity,
  entityEnvelopeSchemaValidator,
} from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/WitboostEntity.v1alpha1.schema.json';
import { err, ok, Result } from '@agilelab/plugin-wb-platform-common';

export interface WitboostEntity extends Entity {
  spec: {
    mesh: {
      name: string;
    } & Record<string, any>;
  } & Record<string, any>;
}

export const applyWitboostEntity =
  entityEnvelopeSchemaValidator<WitboostEntity>(schema);

export function safeApplyWitboostEntity(
  data: unknown,
): Result<WitboostEntity, TypeError> {
  try {
    return ok(applyWitboostEntity(data));
  } catch (error) {
    if (error instanceof TypeError) {
      return err(error);
    }
    throw error;
  }
}

export const witboostEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
