import {
  SelectableTable,
  WbMultiSelectionPickerDrawer,
  WbTableFilters,
} from '@agilelab/plugin-wb-platform';
import React, { useEffect, useState } from 'react';
import { PermissionEntity } from '@agilelab/plugin-wb-rbac-common';
import { useRolePermissionsTableContext } from './RolePermissionsTableContext';
import { useAsyncFn } from 'react-use';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import { rbacApiRef } from '../../../plugin';
import { Filters } from '../Roles/RolesTableContext';

export const RolePermissionsDrawer: React.FC = () => {
  const rbacApi = useApi(rbacApiRef);
  const identityApi = useApi(identityApiRef);
  const {
    selectedPermissions,
    setSelectedPermissions,
    defaultColumns,
    openManageDrawer,
    updateRole,
    setOpenManageDrawer,
  } = useRolePermissionsTableContext();

  const [allPermissionsFilters, setAllPermissionsFilters] = useState<Filters>(
    {},
  );
  const [platformPermissionsState, loadPlatformPermissions] =
    useAsyncFn(async () => {
      const result = await rbacApi.getPlatformPermissions({
        searchKeyword: allPermissionsFilters.text,
        options: {
          token: (await identityApi.getCredentials()).token,
        },
      });

      return result.permissions;
    }, [allPermissionsFilters]);

  useEffect(() => {
    loadPlatformPermissions();
  }, [loadPlatformPermissions]);

  const getRowId = (row: PermissionEntity) => row.id;

  return (
    <WbMultiSelectionPickerDrawer<PermissionEntity>
      openDrawer={openManageDrawer}
      setOpenDrawer={setOpenManageDrawer}
      title="Manage permissions"
      selectedValues={selectedPermissions}
      setSelectedValues={setSelectedPermissions}
      contentHeader={<></>}
      onApply={updateRole}
    >
      <WbTableFilters
        style={{ marginBottom: '0', paddingLeft: '8px' }}
        searchValue={allPermissionsFilters.text}
        onSearch={search => {
          setAllPermissionsFilters({ text: search });
        }}
        onClear={() => setAllPermissionsFilters({})}
      />
      <SelectableTable<PermissionEntity>
        loading={platformPermissionsState.loading}
        data={platformPermissionsState.value ?? []}
        columns={defaultColumns}
        getRowId={getRowId}
      />
    </WbMultiSelectionPickerDrawer>
  );
};
