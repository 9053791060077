import {
  RoleEntity,
  rbacRoleEditPermission,
} from '@agilelab/plugin-wb-rbac-common';
import {
  useApi,
  identityApiRef,
  configApiRef,
  alertApiRef,
} from '@backstage/core-plugin-api';
import React, { useContext, useEffect, useState } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { rbacApiRef } from '../../..';
import { usePermission } from '@backstage/plugin-permission-react';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { RoleFormType } from '../RoleForm';

export interface OverviewContextProviderProps {
  roleId: string;
  children?: React.ReactNode;
  setName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export interface OverviewContextType extends OverviewContextProviderProps {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;

  error: Error | undefined;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;

  canEditRolePermission: boolean;
  editRole: (form: RoleFormType) => Promise<RoleEntity[]>;
  editRoleState: AsyncState<RoleEntity[] | undefined>;

  loadRole: () => Promise<RoleEntity>;
  roleState: AsyncState<RoleEntity | undefined>;
}

export const OverviewContext = React.createContext<OverviewContextType>(
  {} as OverviewContextType,
);

export const OverviewContextProvider = ({
  roleId,
  setName,
  children,
}: OverviewContextProviderProps) => {
  const rbacApi = useApi(rbacApiRef);
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);
  const alertApi = useApi(alertApiRef);

  // check if permission check is enabled
  const permissionsEnabled =
    configApi.getOptionalBoolean('permission.enabled') ?? false;

  const editPermissionAllowed = usePermission({
    permission: rbacRoleEditPermission,
  }).allowed;

  const canEditRolePermission = !permissionsEnabled || editPermissionAllowed;

  const [error, setError] = useState<Error>();
  const [openDrawer, setOpenDrawer] = useState(false);

  const [roleState, loadRole] = useAsyncFn(async () => {
    const response = await rbacApi.getRole({
      roleId,
      options: { token: (await identityApi.getCredentials()).token },
    });

    if (response.displayName) {
      setName(response.displayName);
    }
    return response;
  }, [roleId]);

  useEffect(() => {
    loadRole();
  }, [loadRole]);

  const [editRoleState, editRole] = useAsyncFn(async (form: RoleFormType) => {
    try {
      await rbacApi.editRole({
        role: {
          id: roleId,
          displayName: form.displayName,
          description: form.description,
        },
        options: { token: (await identityApi.getCredentials()).token },
      });
      setOpenDrawer(false);

      alertApi.post({
        message: `Role successfully updated.`,
        severity: 'success',
      });

      loadRole();
    } catch (e) {
      alertApi.post({
        message: e,
        severity: 'error',
      });
    }
    return [];
  }, []);

  useEffect(() => {
    if (editRoleState.error) setError(editRoleState.error);
  }, [editRoleState.error]);

  return (
    <OverviewContext.Provider
      value={{
        roleId,
        loadRole,
        roleState,
        setName,
        error,
        setError,
        openDrawer,
        setOpenDrawer,
        canEditRolePermission,
        editRole,
        editRoleState,
      }}
    >
      {children}
    </OverviewContext.Provider>
  );
};

export const useOverviewContext = () => {
  const context = useContext(OverviewContext);
  if (!context) {
    throw new Error(
      'useOverviewContext must be used within a OverviewContextProvider',
    );
  }
  return context as OverviewContextType;
};
