import { ApiRef, createApiRef } from '@backstage/core-plugin-api';

import { CustomAlertApi } from './AlertApi/CustomAlertApi';
import { MeshAuditApi } from './AuditApi/MeshAuditApi';
import { CustomPresentationRetrieverApi } from '@agilelab/plugin-wb-platform-common';

export const customAlertApiRef: ApiRef<CustomAlertApi> = createApiRef({
  id: 'wb-alert',
});

export { CustomAlertApiForwarder } from './AlertApi/CustomAlertApiForwarder';

export type { CustomAlertApi } from './AlertApi/CustomAlertApi';

export const auditApiRef: ApiRef<MeshAuditApi> = createApiRef({
  id: 'wb-audit',
});

export type { MeshAuditApi } from './AuditApi/MeshAuditApi';

export { MeshAuditClient } from './AuditApi/MeshAuditClient';

export { mapToAuditEntry } from './AuditApi/utils';
export * from './CustomPresentationRetriever';

export const CustomPresentationRetrieverApiRef: ApiRef<CustomPresentationRetrieverApi> =
  createApiRef({
    id: 'wb-custom-presentation-retriever',
  });
