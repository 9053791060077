import React from 'react';

export const OutlinedEmptyStateIcon = () => {
  return (
    <svg
      width="176"
      height="204"
      viewBox="0 0 176 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.25" filter="url(#filter0_f_71_10743)">
        <ellipse
          cx="88"
          cy="183"
          rx="77"
          ry="12.5"
          fill="url(#paint0_radial_71_10743)"
        />
      </g>
      <rect x="1" y="1.5" width="174" height="174" rx="87" fill="white" />
      <rect
        x="1"
        y="1.5"
        width="174"
        height="174"
        rx="87"
        stroke="#B0E5EC"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <path
        d="M41.4401 34.98L34.6667 41.7533L57.1734 64.3133L40.0001 77.6466L48.6934 84.42L88.0001 114.98L99.2001 106.287L106.827 113.913L88.0001 128.527L48.6934 97.9667L40.0001 104.687L88.0001 142.02L114.4 121.487L134.56 141.647L141.333 134.873L41.4401 34.98ZM127.253 84.42L136 77.6466L88.0001 40.3133L72.4801 52.42L114.453 94.4466L127.253 84.42ZM129.653 109.647L136 104.687L128.373 97.06L122.027 101.967L129.653 109.647Z"
        fill="#B0E5EC"
      />
      <defs>
        <filter
          id="filter0_f_71_10743"
          x="3"
          y="162.5"
          width="170"
          height="41"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4"
            result="effect1_foregroundBlur_71_10743"
          />
        </filter>
        <radialGradient
          id="paint0_radial_71_10743"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(88 183) scale(77 474.32)"
        >
          <stop stopColor="#B0BEC5" />
          <stop offset="1" stopColor="#B0BEC5" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};
