import {
  CustomViewComponent,
  CustomViewPageCategory,
  CustomViewPages,
  useDataPath,
} from '@agilelab/plugin-wb-platform';
import React, { ReactElement } from 'react';
import preview from './preview/builder_system_general/builder_system_general.png';
import thumbnail from './preview/builder_system_general/builder_system_general_thumbnail.png';

CustomViewPages.register({
  id: 'builder_system_general',
  meta: {
    label: 'System General Information',
    category: CustomViewPageCategory.BUILDER,
    preview: {
      thumbnail: { src: thumbnail },
      items: [{ src: preview }],
    },
  },
  function: function DataProductGeneral() {
    const data = useDataPath('');
    let genFields: ReactElement[] = [
      <CustomViewComponent type="string" label="URN" path="urn" colSpan="2" />,
      <CustomViewComponent
        type="string"
        label="Description"
        path="entity.metadata.description"
        default="No description"
        colSpan="4"
      />,
    ];
    if (!data?.isPracticeShaperEntity) {
      genFields.push(
        <CustomViewComponent
          type="link"
          label="Owner"
          path="_relations.ownedByRelations"
          showWhenExists="_relations.ownedByRelations.length"
          kind="group"
          colSpan="2"
        />,
      );
      genFields.push(
        <CustomViewComponent
          type="string"
          label="Lifecycle"
          path="entity.spec.lifecycle"
          showWhenHasValue
          colSpan="2"
        />,
      );
      genFields.push(
        <CustomViewComponent
          type="string"
          label="Type"
          path="entity.spec.type"
          colSpan="2"
          showWhenExists="entity.spec.type"
        />,
      );
    }
    if (data?._types.isSystem)
      genFields.push(
        <CustomViewComponent
          type="link"
          label="Domain"
          path="_relations.partOfDomainRelations"
          kind="domain"
          colSpan="2"
        />,
      );
    if (
      data?._types.isAPI ||
      data?._types.isComponent ||
      data?._types.isResource
    )
      genFields.push(
        <CustomViewComponent
          type="link"
          label="System"
          path="_relations.partOfSystemRelations"
          kind="system"
          colSpan="2"
          default="No System"
        />,
      );
    if (data?._types.isComponent)
      genFields.push(
        <CustomViewComponent
          type="link"
          label="Parent Component"
          path="_relations.partOfComponentRelations"
          kind="domain"
          colSpan="2"
          default="No Parent Component"
        />,
      );
    if (data?._types.isLocation && data?.targets.length > 0)
      genFields.push(
        <CustomViewComponent
          type="link_grid_list"
          label="Targets"
          path="targets"
        />,
      );

    if (data?.isPracticeShaperEntity && !!data?.entity.spec) {
      genFields = genFields.concat(
        <CustomViewComponent
          type="automatic_fields_list"
          path="entity.spec"
          exclude={['belongsTo', 'partOfDomain', 'partOfSystem']}
          defaults={{
            _default: { colSpan: '2' },
            description: { colSpan: '4' },
          }}
        />,
      );

      if (data?.entity.kind === 'ComponentType') {
        genFields.push(
          <CustomViewComponent
            type="link"
            label="Part of system"
            path="entity.spec.partOfSystem"
            colSpan="2"
            showWhenExists="entity.spec.partOfSystem"
          />,
        );

        genFields.push(
          <CustomViewComponent
            type="link"
            mode="linear"
            label="Traits"
            path="implementsRelations"
            colSpan="2"
            showWhenExists="implementsRelations"
          />,
        );
      }

      if (data?.entity.kind === 'SystemType') {
        genFields.push(
          <CustomViewComponent
            type="link"
            label="part of domain"
            path="entity.spec.partOfDomain"
            colSpan="2"
            showWhenExists="entity.spec.partOfDomain"
          />,
        );
        genFields.push(
          <CustomViewComponent
            type="link"
            label="belongs to"
            path="entity.spec.belongsTo"
            colSpan="2"
            showWhenExists="entity.spec.belongsTo"
          />,
        );
        genFields.push(
          <CustomViewComponent
            type="link"
            mode="linear"
            label="Traits"
            path="implementsRelations"
            colSpan="2"
            showWhenExists="implementsRelations"
          />,
        );
      }
    }
    if (data?.entity.kind === 'DomainType') {
      genFields.push(
        <CustomViewComponent
          type="link"
          mode="linear"
          label="Traits"
          path="implementsRelations"
          colSpan="2"
          showWhenExists="implementsRelations"
        />,
      );
    }
    if (data?.entity.kind === 'TaxonomyType') {
      genFields.push(
        <CustomViewComponent
          type="link"
          mode="linear"
          label="Traits"
          path="implementsRelations"
          colSpan="2"
          showWhenExists="implementsRelations"
        />,
      );
    }

    if (data?._types.isSystem || data?._types.isHeadlessComponent)
      genFields.push(
        <CustomViewComponent
          type="link"
          label="{{ taxonomyLabel }}"
          path="taxonomies"
          colSpan="2"
        />,
      );
    if (data?._types.isSystem)
      genFields.push(
        <CustomViewComponent
          type="string"
          label="Partially Deployable In"
          path="partiallyDeployableIn"
          showWhenHasValue
          colSpan="2"
        />,
      );

    return (
      <CustomViewComponent type="catalog_system_card">
        <CustomViewComponent type="container">
          {genFields}
          <CustomViewComponent
            type="hline"
            showWhen={{
              value: '{{ tags.length }}',
              notEquals: 0,
            }}
          />
          <CustomViewComponent
            type="tags"
            label="Tags"
            path="tags"
            showWhen={{
              value: '{{ tags.length }}',
              notEquals: 0,
            }}
          />
        </CustomViewComponent>
      </CustomViewComponent>
    );
  },
});
