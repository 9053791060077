import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import { customAlertApiRef } from '@agilelab/plugin-wb-platform';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import React from 'react';
import useInterval from 'react-use/lib/useInterval';
import { useEditorPageContext } from '../context/useEditorPageContext';
import { Test } from '../types';
import { panelCatalogApiRef } from '../../../api';
import { TestTableLoadingRow } from './TestTableLoadingRow';

interface Props {
  test: Test;
}

export const AsyncTestTableRow: React.FC<Props> = ({ test }) => {
  const panelCatalog = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const configApi = useApi(configApiRef);
  const { fetchLastTest, fetchTests } = useEditorPageContext();
  const pollingInterval =
    configApi.getOptionalNumber(
      'mesh.provisioner.validation.statusPolling.interval',
    ) ?? 3000;

  useInterval(async () => {
    panelCatalog
      .getValidateDescriptorStatus(test.id)
      .then(response => {
        if (response.status !== DagStatus.RUNNING) {
          fetchLastTest();
          fetchTests();
        }
      })
      .catch(error => {
        alertApi.post({ error, severity: 'error' });
      });
  }, pollingInterval);

  return <TestTableLoadingRow startDate={test.startDate} />;
};
