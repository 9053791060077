import { usePublishEvent } from '@agilelab/plugin-wb-events';
import { externalLinkClickedTopic } from '@agilelab/plugin-wb-events-common';
import { Link, Tooltip } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

interface WbLinkProps {
  /**
   * the route you want to navigate to.
   * if it starts with `/`, it places the route after the host,
   * otherwise it appends the route after the current link.
   * @example current route: `localhost:3000/other_route`
   *          route = `/marketplace/test` -> will navigate to `localhost:3000/marketplace/test`
   *          route = `marketplace/test` -> will navigate to `localhost:3000/other_route/marketplace/test`
   */
  route?: string;
  /**
   * optionally provide an external link, it will have priority.
   * @example https://testsite.com
   */
  externalLink?: string;
  /**
   * optionally provide a target for the link
   * @example _blank
   */

  hrefTarget?: string;
  /**
   * the text to display instead of the raw link.
   * If not provided, show raw link instead.
   */
  text?: string;
  /**
   * defaults to `always`
   */
  underlineLink?: 'none' | 'hover' | 'always';
  className?: string;
  showTooltip?: boolean;
}

function isExternalLink(link?: string): boolean {
  if (link && link.match(/^https{0,1}\:\/\//)) {
    const linkHost = link.replace(/^https?:\/\//, '').split('/')[0];
    const currentHost = document.URL.replace(/^https?:\/\//, '').split('/')[0];

    return linkHost !== currentHost;
  }
  return false;
}

export const WbLink: React.FC<WbLinkProps> = ({
  route,
  externalLink,
  hrefTarget,
  text,
  underlineLink,
  className,
  showTooltip,
}) => {
  const { publish } = usePublishEvent({ generatorSystem: 'platform' });
  const location = useLocation();

  const toDisplay = text ?? externalLink ?? route ?? '';

  const commonProps = {
    className,
    style: { cursor: 'pointer' },
    underline: underlineLink ?? 'always',
    children: toDisplay,
  };
  const eventClick = async () => {
    if (isExternalLink(externalLink)) {
      publish(externalLinkClickedTopic, {
        externalUrl: externalLink || '',
        currentPage: location.pathname,
      });
    }
  };
  return (
    <Tooltip title={showTooltip ? toDisplay : ''}>
      {/* if the link is external render it as standard link with href, otherwise as react-router Link with to */}
      {externalLink || hrefTarget ? (
        <Link
          {...commonProps}
          href={externalLink}
          onClick={eventClick}
          target={hrefTarget || ''}
        />
      ) : (
        <Link {...commonProps} component={RouterLink} to={route ?? ''} />
      )}
    </Tooltip>
  );
};
