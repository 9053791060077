import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSessionStorage } from 'react-use';

export enum MainPageType {
  MARKETPLACE = 'mainPage-marketplace',
  GOVERNANCE = 'mainPage-governance',
  BUILDER = 'mainPage-builder',
}

/**
 * This hook stores the last visited page of a certain main page (e.g. marketplace, governance, builder)
 * in the session storage. It returns a tuple, where the first element is the path of the last visited page
 * and the second element is a function to set the last visited page.
 * The function takes an optional parameter, which is the path of the page to be set as the last visited page.
 * If no parameter is given, the current location will be taken as the last visited page.
 * @param mainPage The main page to store the last visited page for.
 * @returns A tuple containing the last visited page and a function to set the last visited page.
 */
export const useLastVisitedMainPage = (
  mainPage: MainPageType,
): [string, (value?: string) => void] => {
  const [lastVisitedPage, setLastVisitedPage] =
    useSessionStorage<string>(mainPage);
  const location = useLocation();
  function setVisitedPage(path?: string) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setLastVisitedPage(path ?? location.pathname);
    }, [path]);
  }

  return [lastVisitedPage, setVisitedPage];
};
