import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { useCallback, useState } from 'react';
import { wittyAutocompleteApiRef } from '../api';
import { useWittyEnabled } from './useWittyEnabled';
import { useWittyTable } from '../contexts';
import { handleWittyError } from './handleWittyError';

export type WittyAutocompleteHookOptions = {
  fieldId: string;
  schema: Record<string, any>;
  onChange: (value: any) => void;
};

export type WittyAutocompleteHook = {
  enabled: boolean;
  loading: boolean;
  suggestText: () => Promise<void>;
};

/**
 *
 *
 * @public
 */
export function useWittyAutocomplete({
  fieldId,
  schema,
  onChange,
}: WittyAutocompleteHookOptions): WittyAutocompleteHook {
  const witty = useApi(wittyAutocompleteApiRef);
  const wittyTable = useWittyTable();
  const alertApi = useApi(alertApiRef);
  const [loading, setLoading] = useState<boolean>(false);

  const { enabled, wittyForm } = useWittyEnabled(schema);

  const suggestText = useCallback(async () => {
    if (!wittyForm || !wittyTable) {
      alertApi.post({
        severity: 'error',
        message:
          'Witty is not enabled on this form. Please make sure that the form has a valid schema and that the Witty plugin is enabled.',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await witty.autocomplete({
        tablePath: wittyTable.tablePath,
        formData: wittyForm.formData,
        suggestFields: [{ type: 'text', path: fieldId }],
        optionsSets: {},
        additionalContext: wittyTable.additionalContext,
      });

      if (!response.suggestions[fieldId]) {
        // applies when suggestion is empty
        setLoading(false);
        return;
      }

      onChange(response.suggestions[fieldId]);
    } catch (error: any) {
      handleWittyError(error, alertApi);
    } finally {
      setLoading(false);
    }
  }, [alertApi, fieldId, onChange, witty, wittyForm, wittyTable]);

  return {
    enabled,
    loading,
    suggestText,
  };
}
