import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/ComponentType.v2alpha1.schema.json';

declare interface ComponentTypeEntity extends Entity {
  apiVersion: 'witboost.com/v2';
  kind: 'ComponentType';
  metadata: {
    displayName?: string;
    pluralizedDisplayName?: string;
    displayPriority?: number;
    systemDetails?: {
      taxonomy?: string;
    };
  } & EntityMeta;
  spec: {
    abstract: boolean;
    resourceTypeId?: string;
    shoppable?: boolean;
    consumable?: boolean;
    belongsTo?: string;
    partOfDomain?: string;
    partOfComponent?: string;
    partOfSystem?: string;
    readsFrom?: string[];
    dependsOn?: string[];
    extends?: string[];
    ownedBy?: string;
    implements?: string[];
    schema?: object;
  };
}

export type { ComponentTypeEntity as ComponentType };

export type HeadlessComponentType = ComponentTypeEntity & ProjectType;

export const componentTypeEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);

export function isComponentType(entity: Entity): entity is ComponentTypeEntity {
  return (
    entity.apiVersion === 'witboost.com/v2' && entity.kind === 'ComponentType'
  );
}
