import { z } from 'zod';
import { Entity } from '@backstage/catalog-model';

import { CompletePermission } from './model';

/**
 * Container header for all witboost user permissions
 * It has the form of:
 *
 * {
 *  "catalog.entity.read": [
 *    "urn:dmb:dmn:finance",
 *    "urn:dmb:dmn:organization",
 *    "urn:dmb:dmn:marketing"
 *  ],
 *  "catalog.entity.create": [
 *    "urn:dmb:dmn:finance",
 *    "urn:dmb:dmn:organization",
 *    "urn:dmb:dmn:marketing"
 *  ],
 * }
 *
 *
 */
export type MeshPermissionsHeader = {
  permissions: PermissionsMap;
};

export type PermissionsMap = {
  [permissionId: string]: PermissionScope[];
};

export const PERMISSION_WILDCARD: string = '*';
export const PermissionScopeSchema = z.union([
  z.string().startsWith('urn:dmb:'),
  z.literal(PERMISSION_WILDCARD),
]);

export type PermissionScope = z.infer<typeof PermissionScopeSchema>;

export class UserPermissionsMap {
  constructor(private readonly map: PermissionsMap) {}

  static fromPermissions(
    permissions: CompletePermission[],
  ): UserPermissionsMap {
    return new UserPermissionsMap(
      permissions.reduce((acc, permission) => {
        if (!acc[permission.id]) acc[permission.id] = [];
        if (permission.entityRef) acc[permission.id].push(permission.entityRef);
        return acc;
      }, {} as PermissionsMap),
    );
  }

  hasPermission(permissionId: string): boolean {
    return this.map[permissionId] ? true : false;
  }

  getScopes(permissionId: string): string[] {
    return this.map[permissionId] ?? [];
  }

  getEntrySet(): PermissionsMap {
    return this.map;
  }
}
export interface RoleViewFilters {
  text?: string;
  limit?: number;
}

export interface PermissionViewFilters {
  text?: string;
  limit?: number;
}

export interface RoleAssignments {
  roleId: string;
  scope: { urn: string; entity: Entity }[];
  userOrGroups: { urn: string; entity: Entity };
}
