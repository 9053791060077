import { useEffect, useRef } from 'react';

/**
 * Listens for changes to the search parameters in the URL and calls the provided
 * callback with the previous and current search parameters when a change is detected.
 *
 * @param onSearchParamChange - Function to call with the previous and current search
 *   parameters when a change is detected. This function should accept two string
 *   parameters: the previous search parameters and the current search parameters.
 */
export function useSearchParamChangeObserver(
  onSearchParamChange: (
    previousSearchParams: string,
    currentSearchParams: string,
  ) => void,
): void {
  const previousUrlRef = useRef(window.location.href);

  useEffect(() => {
    const checkUrlChange = () => {
      const currentUrl = window.location.href;
      const previousUrl = previousUrlRef.current;
      const currentSearchParams = new URL(currentUrl).search;
      const previousSearchParams = new URL(previousUrl).search;

      if (currentSearchParams !== previousSearchParams) {
        onSearchParamChange(previousSearchParams, currentSearchParams);
      }
      previousUrlRef.current = currentUrl;
    };

    const observer = new MutationObserver(checkUrlChange);
    const config = { childList: true, subtree: true };

    // Start observing the body for changes indicating possible URL changes
    observer.observe(document.body, config);

    // Additionally, listen for the popstate event for history navigation changes
    window.addEventListener('popstate', checkUrlChange);

    // Clean up observer and event listener on component unmount
    return () => {
      observer.disconnect();
      window.removeEventListener('popstate', checkUrlChange);
    };
  }, [onSearchParamChange]); // Ensure effect rerun if the callback function changes
}
