import { useLocation } from 'react-router';
import { useSessionStorage } from 'react-use';

/**
 * Returns a session-scoped query string for the given path.
 *
 * @param path the path to scope the query string to. If not given, the current
 *             location's path is used.
 *
 * @returns a tuple containing the query string and a setter function. The
 *          setter function takes an optional value which is the query string
 *          to set. If the value is not given, the current location's search
 *          string is used.
 */
export const useSessionQueryString = (
  path?: string,
): [string, (value: string) => void] => {
  const location = useLocation();

  const [sessionQueryString, setSessionQueryString] = useSessionStorage<string>(
    `[sessionQueryString]${path ?? location.pathname}`,
    '',
  );

  function setQueryString(value?: string) {
    const queryString = value ?? location.search;
    const newQueryString = queryString.startsWith('?')
      ? queryString
      : `?${queryString}`;

    setSessionQueryString(newQueryString);
  }

  return [sessionQueryString, setQueryString];
};
