import React, { createContext, ReactNode, useContext, useMemo } from 'react';

export type WittyTableRow = {
  tableRowPath: string;
  canAutocomplete: boolean;
  isProvided?: boolean;
};

export type WittyTableRowContextProviderProps = {
  tableRowPath: string;
  canAutocomplete: boolean;
};

const defaultContext: WittyTableRow = {
  tableRowPath: '',
  canAutocomplete: false,
};

export const WittyTableRowContext =
  createContext<WittyTableRow>(defaultContext);

export const useWittyTableRow = (): WittyTableRow | undefined => {
  const context = useContext(WittyTableRowContext);

  if (!context.isProvided) {
    return undefined;
  }

  return context;
};

export const WittyTableRowContextProvider = ({
  children,
  ...props
}: {
  children: ReactNode;
} & WittyTableRowContextProviderProps) => {
  const value = useMemo(
    () => ({
      ...props,
      isProvided: true,
    }),
    [props],
  );

  return (
    <WittyTableRowContext.Provider value={value}>
      {children}
    </WittyTableRowContext.Provider>
  );
};
