import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const LogicallyReadsFromIcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <line
      x1="4"
      y1="12"
      x2="20"
      y2="12"
      stroke="currentColor"
      strokeWidth="2"
      strokeDasharray="6 4"
    />
  </svg>,
  'LogicallyReadsFromIcon',
);
