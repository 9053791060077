import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { useCallback, useState } from 'react';
import { wittyAutocompleteApiRef } from '../api';
import { useWittyEnabled } from './useWittyEnabled';
import { useWittyTable } from '../contexts';
import { handleWittyError } from './handleWittyError';

export type WittyAutoselectHookOptions = {
  fieldId: string;
  schema: Record<string, any>;
  onSelect: (_: any, val: any) => void;
  optionsSetId: string | null;
};

export type WittyAutoselectHook = {
  enabled: boolean;
  loading: boolean;
  suggestOptions: () => Promise<void>;
};

/**
 *
 *
 * @public
 */
export function useWittyAutoselect({
  fieldId,
  schema,
  onSelect,
  optionsSetId,
}: WittyAutoselectHookOptions): WittyAutoselectHook {
  const witty = useApi(wittyAutocompleteApiRef);
  const alertApi = useApi(alertApiRef);
  const [loading, setLoading] = useState<boolean>(false);

  const { enabled, wittyForm } = useWittyEnabled(schema);

  const wittyTable = useWittyTable();

  const suggestOptions = useCallback(async () => {
    try {
      if (!wittyForm || !wittyTable || !optionsSetId) {
        alertApi.post({
          severity: 'error',
          message:
            'Witty is not enabled for this form. Please make sure that the form has a valid schema and that the Witty plugin is enabled.',
        });
        return;
      }

      setLoading(true);

      const options = await wittyTable.getOptionsSet(optionsSetId);

      const response = await witty.autocomplete({
        tablePath: wittyTable.tablePath,
        formData: wittyForm.formData,
        suggestFields: [
          { path: fieldId, type: 'multiselect', optionsSet: optionsSetId },
        ],
        optionsSets: { [optionsSetId]: options },
        additionalContext: wittyTable.additionalContext,
      });

      if (
        !response.suggestions[fieldId] ||
        response.suggestions[fieldId].length === 0
      ) {
        setLoading(false);
        return;
      }

      const suggestedOptions = response.suggestions[fieldId];

      onSelect(suggestedOptions, suggestedOptions);
    } catch (error) {
      handleWittyError(error, alertApi);
    } finally {
      setLoading(false);
    }
  }, [alertApi, fieldId, onSelect, witty, wittyForm, wittyTable, optionsSetId]);

  if (!wittyForm) {
    // fallback so that down below we don't need to check for wittyForm existence
    return {
      enabled: false,
      loading: false,
      suggestOptions: async () => {},
    };
  }

  return {
    enabled: Boolean(enabled && optionsSetId),
    loading,
    suggestOptions,
  };
}
