/*
Custom Presentation Retriver
*/
import {
  handleFailedResponse,
  CustomPresentationRetrieverApi,
  GetEntriesParams,
  GetEntryParams,
  RetrieveResponse,
  GetEntriesResponse,
  GetEntryResponse,
  WriteEntryParams,
  WriteEntryResponse,
  DeleteEntriesParams,
  DeleteEntriesResponse,
  PatchEntryParams,
  PatchEntryResponse,
} from '@agilelab/plugin-wb-platform-common';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import qs from 'qs';
import yaml from 'yaml';

export class CustomPresentationRetriever
  implements CustomPresentationRetrieverApi
{
  static cacheDownloads: Record<string, Promise<Response>> = {};
  static cacheText: Record<string, Promise<string>> = {};
  static cache: Record<string, any> = {};
  private readonly discoveryApi;
  private readonly fetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  static emptyCache() {
    CustomPresentationRetriever.cache = {};
    CustomPresentationRetriever.cacheDownloads = {};
    CustomPresentationRetriever.cacheText = {};
  }

  getIncludes(view: any) {
    const getIncludes: Function = this.getIncludes.bind(this);
    return (view.children || [])
      .filter(
        (node: any) =>
          (node.type || '') === 'include' || (node.include || '') !== '',
      )
      .concat(
        (view.children || [])
          .map(getIncludes)
          .reduce((a: [], b: []) => a.concat(b), []),
      );
  }

  async getEntries(params: GetEntriesParams): Promise<GetEntriesResponse> {
    const query = qs.stringify(params);
    const baseUrl = await this.discoveryApi.getBaseUrl('platform');
    const url = `${baseUrl}/customizations?${query}`;

    const response = await this.fetchApi.fetch(url);
    await handleFailedResponse(response);
    return response.json();
  }

  async getEntry({ id }: GetEntryParams): Promise<GetEntryResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('platform');
    const url = `${baseUrl}/customizations/${id}`;

    const response = await this.fetchApi.fetch(url);
    await handleFailedResponse(response);

    const result = await response.json();

    if (!result) throw Error('Customization could not be found');
    return result;
  }

  async deleteEntries(
    params: DeleteEntriesParams,
  ): Promise<DeleteEntriesResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('platform');
    const query = qs.stringify(params);
    const url = `${baseUrl}/customizations?${query}`;

    const response = await this.fetchApi.fetch(url, {
      method: 'DELETE',
    });
    await handleFailedResponse(response);

    const result = await response.json();

    return result;
  }

  async patchEntry(params: PatchEntryParams): Promise<PatchEntryResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('platform');
    const url = `${baseUrl}/customizations/${params.id}`;

    const response = await this.fetchApi.fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params.body),
    });

    await handleFailedResponse(response);

    const result = await response.json();

    return result;
  }

  async writeEntry(params: WriteEntryParams): Promise<WriteEntryResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('platform');

    const url = `${baseUrl}/customizations`;

    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    await handleFailedResponse(response);

    const result = await response.json();

    return result;
  }

  async retrieve(
    id: string,
    typeId: string = '',
    templateId: string = '',
  ): Promise<RetrieveResponse> {
    const getIncludes: Function = this.getIncludes.bind(this);
    const retrieve: Function = this.retrieve.bind(this);

    const baseUrl = await this.discoveryApi.getBaseUrl('platform');

    const cacheKey = [id, typeId, templateId].filter(a => !!a).join('/');

    if (cacheKey in CustomPresentationRetriever.cache)
      return CustomPresentationRetriever.cache[cacheKey];
    if (!(cacheKey in CustomPresentationRetriever.cacheDownloads)) {
      const url = `${baseUrl}/custom_presentation/${cacheKey}`;
      CustomPresentationRetriever.cacheDownloads[cacheKey] =
        this.fetchApi.fetch(url);
    }
    const result: Response | undefined =
      await CustomPresentationRetriever.cacheDownloads[cacheKey].catch(
        () => undefined,
      );
    // if another call already resolved the processing
    if (cacheKey in CustomPresentationRetriever.cache)
      return CustomPresentationRetriever.cache[cacheKey];
    if (result) {
      if (result.status === 200) {
        if (!(cacheKey in CustomPresentationRetriever.cacheText))
          CustomPresentationRetriever.cacheText[cacheKey] = result.text();
        const data = yaml.parse(
          await CustomPresentationRetriever.cacheText[cacheKey],
        );
        CustomPresentationRetriever.cache[cacheKey] = data.view;
        await Promise.all(
          (
            await Promise.all(getIncludes(data.view))
          ).map(async (subView: any) => {
            const subnode = await retrieve(
              subView.id || subView.include,
              subView.typeId === undefined ? typeId : subView.typeId,
              subView.templateId === undefined
                ? templateId
                : subView.templateId,
            );
            if (subnode) {
              subView.children = subnode.children;
            }
            return true;
          }),
        );
        return data.view;
      }
    }
    return null;
  }
}
