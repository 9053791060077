import {
  RoleAssignments,
  RoleSubjectEntity,
  rbacRoleEditPermission,
} from '@agilelab/plugin-wb-rbac-common';
import {
  useApi,
  identityApiRef,
  configApiRef,
  alertApiRef,
} from '@backstage/core-plugin-api';
import React, { useContext, useEffect, useState } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { rbacApiRef } from '../../..';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { usePermission } from '@backstage/plugin-permission-react';
import { Entity } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { transformUrnToWitboostId } from '@agilelab/plugin-wb-builder-common';

export interface Filters {
  text?: string;
}

export interface AssignmentsContextProviderProps {
  children?: React.ReactNode;
  roleId: string;
}

export interface AssignmentsContextType
  extends AssignmentsContextProviderProps {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;

  canEditRolePermission: boolean;

  error: Error | undefined;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;

  openConfirmDialog: boolean;
  setOpenConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>;

  removeAssignments: (assignmentSubjects: string[]) => Promise<string[]>;
  removeAssignmentsState: AsyncState<string[]>;

  loadAssignments: () => Promise<RoleAssignments[]>;
  assignmentsLoaded: AsyncState<RoleAssignments[] | undefined>;

  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;

  selectedItems: RoleAssignments[];
  setSelectedItems: React.Dispatch<React.SetStateAction<RoleAssignments[]>>;
}

export const AssignmentsContext = React.createContext<AssignmentsContextType>(
  {} as AssignmentsContextType,
);

export const AssignmentsContextProvider = ({
  children,
  roleId,
}: AssignmentsContextProviderProps) => {
  const rbacApi = useApi(rbacApiRef);
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  // check if permission check is enabled
  const permissionsEnabled =
    configApi.getOptionalBoolean('permission.enabled') ?? false;

  const editPermissionAllowed = usePermission({
    permission: rbacRoleEditPermission,
  }).allowed;

  const canEditRolePermission = !permissionsEnabled || editPermissionAllowed;

  const [error, setError] = useState<Error>();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [filters, setFilters] = useState<Filters>({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [assignmentsLoaded, loadAssignments] = useAsyncFn(async () => {
    const fetchAssignments = async (): Promise<RoleAssignments[]> => {
      const res: RoleSubjectEntity[] = await rbacApi.getRolesSubjects({
        searchKeyword: filters.text,
        filters: { roleId },
        options: {
          token: (await identityApi.getCredentials()).token,
        },
      });

      const entityMap = new Map<string, Entity>();

      // Fetch entity objects for entityRefs and subjects
      await Promise.all(
        res.map(async item => {
          if (item.entityRef && !entityMap.has(item.entityRef)) {
            const urn = item.entityRef;
            const entity = await catalogApi.getEntityByRef(
              transformUrnToWitboostId(urn)!,
            );
            if (entity) {
              entityMap.set(item.entityRef, entity);
            }
          }
          if (item.subject && !entityMap.has(item.subject)) {
            const entity = await catalogApi.getEntityByRef(item.subject);
            if (entity) {
              entityMap.set(item.subject, entity);
            }
          }
        }),
      );

      // Transform RoleSubjectEntity[] into RoleAssignments[] grouped by subject
      const grouped = res.reduce((acc, item) => {
        if (!item.subject) return acc;
        const entity = entityMap.get(item.subject);

        if (!acc[item.subject]) {
          acc[item.subject] = {
            roleId: item.roleId!,
            scope: [],
            userOrGroups: { urn: item.subject, entity: entity! },
          };
        }

        if (item.entityRef) {
          acc[item.subject].scope.push({
            urn: item.entityRef,
            entity: entityMap.get(item.entityRef)!,
          });
        }

        return acc;
      }, {} as Record<string, RoleAssignments>);

      return Object.values(grouped);
    };

    return fetchAssignments();
  }, [filters]);

  useEffect(() => {
    loadAssignments();
  }, [loadAssignments]);

  const [removeAssignmentsState, removeAssignments] = useAsyncFn(
    async (assignmentSubjects: string[]) => {
      try {
        const token = (await identityApi.getCredentials()).token;

        const results = await Promise.allSettled(
          assignmentSubjects.map(subject =>
            rbacApi.deleteRolesSubjects({
              filters: { roleId, subject },
              options: { token },
            }),
          ),
        );

        const failed = results.filter(result => result.status === 'rejected');

        if (failed.length === assignmentSubjects.length) {
          alertApi.post({
            message: `${failed.length} permission(s) failed to remove.`,
            severity: 'error',
          });
        } else {
          alertApi.post({
            message: `Assignments successfully removed`,
            severity: 'success',
          });
        }

        loadAssignments();
      } catch (e) {
        alertApi.post({
          message: e.message || 'An error occurred',
          severity: 'error',
        });
      }
      return [];
    },
    [],
  );

  return (
    <AssignmentsContext.Provider
      value={{
        roleId,
        loadAssignments,
        assignmentsLoaded,
        canEditRolePermission,
        error,
        setError,
        openConfirmDialog,
        setOpenConfirmDialog,
        openDrawer,
        setOpenDrawer,
        removeAssignments,
        removeAssignmentsState,
        filters,
        setFilters,
        selectedItems,
        setSelectedItems,
      }}
    >
      {children}
    </AssignmentsContext.Provider>
  );
};

export const useAssignmentsContext = () => {
  const context = useContext(AssignmentsContext);
  if (!context) {
    throw new Error(
      'useAssignmentsContext must be used within a AssignmentsContextProvider',
    );
  }
  return context as AssignmentsContextType;
};
