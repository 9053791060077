import { Link } from '@backstage/core-components';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
  Box,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { explodeTreeRows } from '../utils';
import { WbExpandToggle, WbExpandToggleProps } from '../../WbExpandToggle';
import { TableCellProps, TableContentProps } from '../types';
import { useWbTableContext } from '../context/WbTableContext';
import { Selector } from '../Selector';

const useTreeCellStyles = makeStyles(theme => ({
  tableTreeCellRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const useRowStyles = makeStyles<Theme, { disableRowHighlightOnHover: boolean }>(
  theme => ({
    tableRow: props => ({
      position: 'relative',
      borderBottom: '1px solid white',
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        backgroundColor: props.disableRowHighlightOnHover
          ? undefined
          : theme.palette.bkg.primary,
      },
    }),
  }),
);

interface TableTreeCellProps extends WbExpandToggleProps {
  children: React.ReactNode;
  depth: number;
  hasChildren: boolean;
}

const TableTreeCell = ({
  children,
  expanded,
  onClick,
  depth,
  hasChildren,
}: TableTreeCellProps) => {
  const classes = useTreeCellStyles();
  const theme = useTheme();
  return (
    <Box
      className={classes.tableTreeCellRoot}
      style={{ paddingLeft: theme.spacing(2) * depth }}
    >
      <WbExpandToggle
        expanded={expanded}
        onClick={onClick}
        style={{ visibility: hasChildren ? undefined : 'hidden' }}
      />
      {children}
    </Box>
  );
};

interface TableCellComponentProps<T> {
  row: T;
  cell: TableCellProps<T>;
  // used for tree view, otherwise will always be 0
  rowDepth: number;
}

const TableCellComponent = <T,>({
  row,
  cell,
  rowDepth,
}: TableCellComponentProps<T>) => {
  let component = cell.formatter ? (
    <>{cell.formatter(row[cell.field])}</>
  ) : (
    <>{row[cell.field]}</>
  );

  if (cell.fieldRender && cell.field) {
    return <>{cell.fieldRender(row, { rowDepth })}</>;
  }
  if (cell.link && cell.field) {
    component = (
      <Link color="inherit" to={cell.link}>
        {row[cell.field] as any}
      </Link>
    );
  }
  if (cell.typographyProps) {
    component = (
      <Typography
        color={cell.typographyProps.color}
        component={cell.typographyProps.component || 'span'}
        variant={cell.typographyProps.variant}
        style={cell.typographyProps.style}
        className={cell.typographyProps.className}
      >
        {component}
      </Typography>
    );
  }
  if (!!cell.tooltip && typeof row[cell.field] === 'string') {
    return (
      <Tooltip
        title={row[cell.field] as unknown as string}
        children={component}
      />
    );
  }

  return component;
};

export function TableContent<T>({
  rowStyle,
  rowClassName,
}: TableContentProps<T>) {
  const theme = useTheme();

  const {
    columns,
    rows,
    selection,
    selectedItems,
    setSelectedItems,
    tooltipIfDisabled,
    onRowClick,
    rowDynamicClassName,
    treeProps,
    getRowId,
    disableRowHighlightOnHover,
    checkIfDisabled,
  } = useWbTableContext<T>();
  const classes = useRowStyles({ disableRowHighlightOnHover });

  // add the necessary data for tree rows if needed
  const {
    rows: displayedRows,
    isAnyRowNested,
    depthMap,
  } = useMemo(() => {
    if (!treeProps?.getRowChildren || !getRowId)
      return {
        rows,
        isAnyRowNested: false,
        depthMap: new Map<string, number>(),
      };
    return explodeTreeRows(
      rows,
      getRowId,
      treeProps.getRowChildren,
      treeProps.expandedRowsIds ?? [],
    );
  }, [rows, getRowId, treeProps?.getRowChildren, treeProps?.expandedRowsIds]);

  return (
    <TableBody>
      {displayedRows.map((row, index) => (
        <TableRow
          className={clsx(
            rowDynamicClassName?.(row),
            classes.tableRow,
            rowClassName,
          )}
          key={getRowId ? getRowId(row) : `table-row-${index}`}
          style={{
            ...(onRowClick ? { cursor: 'pointer' } : {}),
            ...rowStyle,
            ...(selection === 'single' &&
            getRowId?.(selectedItems![0]) === getRowId?.(row)
              ? { backgroundColor: theme.palette.bkg.primary }
              : {}),
            ...(selection === 'multiple' &&
            selectedItems?.find(r => getRowId?.(r) === getRowId?.(row))
              ? { backgroundColor: theme.palette.bkg.primary }
              : {}),
          }}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            if (onRowClick) onRowClick(row, event);
            if (selection === 'single') setSelectedItems?.([row]);
          }}
        >
          {selection === 'multiple' && (
            <TableCell key={`${index}-selector`} width="5%">
              <Selector
                row={row}
                getRowId={getRowId!}
                selectedItems={selectedItems!}
                setSelectedItems={setSelectedItems!}
                disabled={checkIfDisabled?.(row)}
                tooltipIfDisabled={tooltipIfDisabled}
              />
            </TableCell>
          )}
          {columns.map((cell, cellIndex) => {
            const id = getRowId?.(row);

            const rowDepth = id ? depthMap.get(id) ?? 0 : 0;

            let content = (
              <TableCellComponent<T>
                row={row}
                cell={cell}
                rowDepth={rowDepth}
              />
            );

            // for tree rows, add the expand button in the first column
            if (
              id &&
              treeProps &&
              cellIndex === 0 &&
              getRowId &&
              isAnyRowNested
            ) {
              const expanded = treeProps.expandedRowsIds.includes(id);
              content = (
                <TableTreeCell
                  hasChildren={treeProps.getRowChildren(row).length > 0}
                  expanded={expanded}
                  onClick={() => {
                    if (expanded)
                      treeProps.onExpandChange(
                        treeProps.expandedRowsIds.filter(i => i !== id),
                      );
                    else
                      treeProps.onExpandChange([
                        ...treeProps.expandedRowsIds,
                        id,
                      ]);
                  }}
                  depth={rowDepth}
                >
                  {content}
                </TableTreeCell>
              );
            }

            return (
              <TableCell key={`cell-index-${cellIndex}`} {...cell.cellProps}>
                {content}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableBody>
  );
}
