import {
  CustomViewComponent,
  CustomViewPageCategory,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import preview from './preview/practice_shaper_content/practice_shaper_content.png';
import thumbnail from './preview/practice_shaper_content/practice_shaper_content_thumbnail.png';

CustomViewPages.register({
  id: 'practice_shaper_content',
  meta: {
    label: 'Practice Shaper Detail Drawer',
    category: CustomViewPageCategory.PRACTICE_SHAPER,
    preview: {
      thumbnail: { src: thumbnail },
      items: [{ src: preview }],
    },
  },
  function: function Entity() {
    return (
      <>
        <CustomViewComponent type="include" id="builder_system_general" />
      </>
    );
  },
});
