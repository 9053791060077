import { z } from 'zod';
import {
  CompletePermission,
  GetPermissionsResponse,
  GetRolePermissionsResponse,
  GetRolesResponse,
  Role,
  RoleEntity,
  RolePermission,
  RoleSubject,
  RoleSubjectEntity,
  RoleVisibility,
  UpdateRolePermissionsResponse,
  UpdatedRole,
} from './model';

export const RolesSubjectsFiltersSchema = z
  .object({
    subject: z.string(),
    roleId: z.string(),
    entityRef: z.string().optional().nullable(),
    enabled: z.enum(['true', 'false']).default('false'),
  })
  .partial();

export type RolesSubjectsFilters = z.infer<typeof RolesSubjectsFiltersSchema>;

export type DeleteRolesSubjectsParams = {
  filters?: RolesSubjectsFilters;
  options?: { token?: string };
};

export type GetRolesSubjectsParams = {
  searchKeyword?: string;
  filters?: RolesSubjectsFilters;
  options?: { token?: string };
};

export type GetRolesParams = {
  searchKeyword?: string;
  options?: {
    visibility?: RoleVisibility;
    offset?: number;
    limit?: number;
    token?: string;
  };
};

export type GetRolePermissionsParams = {
  roleId: string;
  searchKeyword?: string;
  options?: {
    visibility?: RoleVisibility;
    offset?: number;
    limit?: number;
    token?: string;
  };
};

export type GetRoleParams = {
  roleId?: string;
  options?: {
    token?: string;
  };
};

export type GetUserPermissionsParams = {
  options?: {
    token: string;
    permissionId?: string;
  };
};

export type GetPlatformPermissionsParams = {
  searchKeyword?: string;
  options?: {
    token?: string;
    permissionId?: string;
    offset?: number;
    limit?: number;
  };
};

export type AddRolesSubjectsParams = {
  rolesSubjects: RoleSubject[];
  options?: { upsert?: boolean; token?: string };
};

export type AddRolesParams = {
  roles: Role[];
  options?: { upsert?: boolean; token?: string };
};

export type EditRoleParams = {
  role: UpdatedRole;
  options?: { token?: string };
};

export type DeleteRolesParams = {
  roles: string[];
  options?: { token?: string };
};

export type AddRolesPermissionsParams = {
  rolesPermissions: RolePermission[];
  options?: { upsert?: boolean; token?: string };
};

export type UpdateRolesPermissionsParams = {
  roleId: string;
  permissions: string[];
  options?: { token?: string };
};

export const RolesPermissionsFiltersSchema = z
  .object({
    roleId: z.string(),
    permissionId: z.string(),
  })
  .partial();

export type RolesPermissionsFilters = z.infer<
  typeof RolesPermissionsFiltersSchema
>;

export type DeleteRolePermissionsParams = {
  filters?: RolesPermissionsFilters;
  options?: { token?: string };
};

/**
 * RBAC Api definition for frontend clients
 */
export interface RbacApi {
  /**
   * Deletes roles subjects rows according to defined filters
   * @param options
   */
  deleteRolesSubjects(
    params: DeleteRolesSubjectsParams,
  ): Promise<Array<RoleSubjectEntity>>;

  getRolesSubjects(
    params: GetRolesSubjectsParams,
  ): Promise<Array<RoleSubjectEntity>>;

  getRoles(params: GetRolesParams): Promise<GetRolesResponse>;

  getRole(params: GetRoleParams): Promise<RoleEntity>;

  getRolePermissions(
    params: GetRolePermissionsParams,
  ): Promise<GetRolePermissionsResponse>;

  getUserPermissions(
    params: GetUserPermissionsParams,
  ): Promise<Array<CompletePermission>>;

  getPlatformPermissions(
    params: GetPlatformPermissionsParams,
  ): Promise<GetPermissionsResponse>;

  addRolesSubjects(
    params: AddRolesSubjectsParams,
  ): Promise<Array<RoleSubjectEntity>>;

  addRoles(params: AddRolesParams): Promise<Array<RoleEntity>>;

  editRole(params: EditRoleParams): Promise<RoleEntity>;

  deleteUserRoles(params: DeleteRolesParams): Promise<string[]>;

  addRolesPermissions(
    params: AddRolesPermissionsParams,
  ): Promise<Array<RolePermission>>;

  deleteRolePermissions(
    params: DeleteRolePermissionsParams,
  ): Promise<Array<RolePermission>>;

  updateRolePermissions(
    params: UpdateRolesPermissionsParams,
  ): Promise<UpdateRolePermissionsResponse>;
}
