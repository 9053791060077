import {
  WbBreadcrumb,
  WbBreadcrumbs,
  WbHeader,
} from '@agilelab/plugin-wb-platform';
import { Page, RoutedTabs } from '@backstage/core-components';
import React, { useMemo } from 'react';
import { RolesTab } from './Roles/RolesTab';
import { PermissionsTab } from './Permissions/PermissionTab';

export const RolesPermissionsPage: React.FC = () => {
  const routes = useMemo(() => {
    const rolesSection = {
      path: '/roles',
      title: 'Roles',
      children: <RolesTab />,
    };
    const permissionSection = {
      path: '/permissions',
      title: 'Permissions',
      children: <PermissionsTab />,
    };

    return [rolesSection, permissionSection];
  }, []);

  return (
    <Page themeId="home">
      <WbHeader
        title="Roles & Permissions "
        titleContainerStyle={{ flex: 'none' }}
        breadcrumbs={
          <WbBreadcrumbs aria-label="breadcrumb">
            <WbBreadcrumb>Administration</WbBreadcrumb>
            <WbBreadcrumb>Roles & Permissions </WbBreadcrumb>
          </WbBreadcrumbs>
        }
      />

      <RoutedTabs routes={routes} />
    </Page>
  );
};
