import React from 'react';
import { RolesCardHeader, RolesTable } from './RolesTable';
import { Paper, makeStyles } from '@material-ui/core';
import { RolesTableContextProvider } from './RolesTableContext';
import { WbCardContent } from '@agilelab/plugin-wb-platform';

const useStyles = makeStyles(
  theme => ({
    paper: {
      height: '100%',
    },
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexBox: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  }),
  { name: 'RolesTab' },
);

export const RolesTab: React.FC = () => {
  const classes = useStyles();
  return (
    <RolesTableContextProvider>
      <Paper className={classes.paper}>
        <WbCardContent className={classes.container}>
          <RolesCardHeader />
          <RolesTable />
        </WbCardContent>
      </Paper>
    </RolesTableContextProvider>
  );
};
