import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/TaxonomyType.v2alpha1.schema.json';

declare interface TaxonomyTypeEntity extends Entity {
  apiVersion: 'witboost.com/v2';
  kind: 'TaxonomyType';
  metadata: EntityMeta & { displayName?: string };
  spec: {
    abstract: false;
    implements?: string[];
    schema?: object;
  };
}

export type { TaxonomyTypeEntity as TaxonomyType };

export const taxonomyTypeEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);

export function isTaxonomyType(entity: Entity): entity is TaxonomyTypeEntity {
  return (
    entity.apiVersion === 'witboost.com/v2' && entity.kind === 'TaxonomyType'
  );
}
