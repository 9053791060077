import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  TextFieldProps,
  useTheme,
} from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { WbDivider } from '../WbDivider';

const useStyles = makeStyles(
  theme => ({
    root: {
      minWidth: '220px',
      backgroundColor: theme.palette.white,
      height: 'auto',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '20px !important',
      },
    },
  }),
  { name: 'WbSearch' },
);

export type WbSearchProps = Omit<TextFieldProps, 'onChange'> & {
  debounced?: boolean;
  onChange?: (value: string) => void;
  submit?: () => void;
  showEnter?: boolean;
};

export const WbSearch = ({
  onChange,
  value,
  debounced,
  placeholder,
  submit,
  showEnter,
  ...props
}: WbSearchProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const [search, setSearch] = useState(value ?? '');

  const inputPropsWithStyle = !showEnter
    ? props.InputProps
    : { ...props.InputProps, style: { borderRadius: '0px 4px 4px 0px' } };

  // Workaround to clear input when user clear all filters
  useEffect(() => {
    if (value === '' || value === undefined) setSearch('');
  }, [value]);

  const handleDebounceFn = React.useCallback(
    (v: string) => {
      onChange?.(v);
    },
    [onChange],
  );

  const debounceFn = React.useMemo(
    () => debounce(handleDebounceFn, 500),
    [handleDebounceFn],
  );

  const handleChangeSearch = (e: any) => {
    const newValue = e.currentTarget.value;
    setSearch(newValue);
    if (debounced) debounceFn(newValue);
    else handleDebounceFn(newValue);
  };

  const handleClearSearch = () => {
    onChange?.('');
    setSearch('');
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      label=""
      classes={{ root: classes.root }}
      value={search ?? ''}
      onChange={handleChangeSearch}
      placeholder={placeholder ?? 'Search'}
      {...props}
      InputProps={{
        ...inputPropsWithStyle,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: search ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear"
              onClick={handleClearSearch}
              disabled={!search}
              size="small"
            >
              <ClearIcon fontSize="small" />
            </IconButton>
            {showEnter && (
              <>
                <WbDivider orientation="vertical" style={{ margin: '8px' }} />
                <IconButton
                  aria-label="submit"
                  onClick={submit}
                  size="small"
                  style={{ color: theme.palette.primary.main }}
                >
                  <ArrowForward fontSize="small" />
                </IconButton>
              </>
            )}
          </InputAdornment>
        ) : (
          showEnter && (
            <InputAdornment position="end">
              <IconButton
                aria-label="submit"
                onClick={submit}
                size="small"
                style={{ color: theme.palette.primary.main }}
              >
                <ArrowForward fontSize="small" />
              </IconButton>
            </InputAdornment>
          )
        ),
      }}
    />
  );
};
