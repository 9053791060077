import { ApolloClient } from '@apollo/client';
import { Domain, Domains } from '../components/VisualDiscoveryPage/utils';
import { GET_DOMAINS, GET_DOMAINS_BY_TYPES } from '../graphql';
import _ from 'lodash';
import { DomainType } from '@agilelab/plugin-wb-builder-common';
import { DomainTree } from '@agilelab/plugin-wb-search';

/**
 * Load all the domains from the marketplace.
 * @param apolloClient the Apollo client to use to query the marketplace
 * @returns a map of all the domains, where the key is the domain id and the value is the domain entity
 */
export async function loadDomainsMap(
  apolloClient: ApolloClient<object>,
): Promise<Record<string, Domain>> {
  const marketplaceDomains = (
    await apolloClient.query<Domains>({
      query: GET_DOMAINS,
    })
  ).data.Domains;

  return marketplaceDomains.reduce<Record<string, Domain>>((acc, domain) => {
    acc[domain.id] = domain;
    return acc;
  }, {});
}

type EnrichedDomain = Domain & { typeDisplayName?: string | undefined };

/**
 * Convert the domains in objects with a Tree structure that resembles their relationships
 * @param domains the flat domains
 * @param filterFN filter function to filter out domains that do not return true (and have no descendants that return true)
 * @returns {DomainTree[]} the domains in a Tree structure
 */
export function buildDomainTrees(
  domains: EnrichedDomain[],
  filterFN?: (domain: DomainTree) => boolean,
): DomainTree[] {
  // recursive function to filter subtrees with no elements that satisfy the filterFN
  function recFilter(nodes: DomainTree[]): DomainTree[] {
    if (!filterFN) return nodes;

    const filteredNodes = nodes
      .map(n => {
        return { ...n, children: recFilter(n.children) };
      })
      .filter(n => filterFN(n) || n.children.length > 0);
    return filteredNodes;
  }

  const domainsMap = domains.reduce<Record<string, Domain>>((acc, domain) => {
    acc[domain.id] = domain;
    return acc;
  }, {});

  const uniqueTypes = new Set(domains.map(d => d.type));

  // if there is only one domain type among all domains, we won't add the domain types as root elements
  const shouldAddDomainTypes = uniqueTypes.size > 1;
  // map the id of each domain node built to its node
  const builtNodes = new Map<string, DomainTree>();

  let roots: DomainTree[] = [];

  domains.forEach(d => {
    // if the domain node for this id has not been built yet, build it
    if (!builtNodes.has(d.id)) {
      builtNodes.set(d.id, {
        id: d.id,
        label: d.name,
        children: [],
      });
    }

    const node = builtNodes.get(d.id)!;

    const parentId = d.sub_domain_of?.[0]?.data.id;

    // if the parent id does not exist (or refers a domain id which can't be found), this means that the domain is a ROOT
    if (!parentId || !domainsMap[parentId]) {
      // since we are not adding domain types, the node itself will be the root
      if (!shouldAddDomainTypes) {
        roots.push(node);
        return;
      }

      // create a new node for the DomainType of the current domain 'd' (if it does not exist)
      if (!builtNodes.has(d.type)) {
        builtNodes.set(d.type, {
          id: d.type,
          label: d.typeDisplayName ?? d.type,
          children: [node],
        });
        roots.push(builtNodes.get(d.type)!);
        return;
      }
      const typeNode = builtNodes.get(d.type);
      // make the current domain as a child of the previous DomainType node (if it is not yet a child node)
      if (!_.includes(typeNode?.children, node)) {
        typeNode?.children.push(node);
      }
      return;
    }

    const parent = domainsMap[parentId];

    // create a parent domain node (if it does not exist)
    if (!builtNodes.has(parentId)) {
      builtNodes.set(parentId, {
        id: parent.id,
        label: parent.name,
        children: [],
      });
    }

    const parentNode = builtNodes.get(parentId);

    // add the current domain node to the children array of the parent if it does not exists
    if (!_.includes(parentNode?.children, node)) {
      parentNode?.children.push(node);
    }
  });

  // filters trees according to filterFN
  if (filterFN) roots = recFilter(roots);

  // add "parentSelector" node (shown as "other elements")  to every parent with children (except for Domain Types)
  function addParentSelectors(nodes: DomainTree[]) {
    nodes.forEach(n => {
      // skip the leaves
      if (!n.children.length) return;

      // add only for nodes in domainsMap (will exclude domain types)
      if (n.id in domainsMap) {
        // add the parent selector node with the actual id of the parent (this will select the parent domain in practice)
        n.children.push({
          id: n.id,
          label: n.label,
          data: { isParentSelectorNode: true },
          children: [],
        });

        // add the parent prefix to the id (we can't have two nodes with the same id)
        n.id = `parent-${n.id}`;
      }
      addParentSelectors(n.children);
    });
  }

  addParentSelectors(roots);

  return roots;
}

/**
 * Load all the domains from the marketplace in a Tree structure
 * @param compatibleDomainTypes the compatible domain types
 * @param apolloClient the Apollo client to use to query the marketplace
 * @returns {DomainTree[]} the domains in a Tree structure
 */
export async function getDomainTreesByTypes(
  compatibleDomainTypes: DomainType[],
  apolloClient: ApolloClient<object>,
) {
  const marketplaceDomains = (
    await apolloClient.query<Domains>({
      query: GET_DOMAINS_BY_TYPES,
      variables: {
        compatibleTypes: compatibleDomainTypes.map(
          domainType => domainType?.metadata.name,
        ),
      },
    })
  ).data.Domains;
  const enrichedMarketplaceDomains = marketplaceDomains.map(domain => ({
    ...domain,
    typeDisplayName: compatibleDomainTypes.find(
      dt => dt.metadata.name === domain.type,
    )?.metadata.displayName as string | undefined,
  }));
  return enrichedMarketplaceDomains;
}

/**
 * Given a domain, it resolves all the domain relations up to the root domain.
 * For every domain, it extracts the domain details using the extractor function (default is the domain name).
 * @param domainExternalId: the Domain external identifier to resolve
 * @param domainsMap: a map of all the domains (can be generated by invoking the method loadDomainsMap)
 * @param extractor: a function that extracts the domain details from the domain entity
 * @returns a list of strings representing the domain details for all the hierarchy of the domain. The list is ordered from the root domain to the input domain.
 */
export function resolveDomainRelations(
  domainExternalId: string,
  domainsMap: Record<string, Domain>,
  extractor: (domain: Domain) => string = domain => domain.name,
): string[] {
  const domain = domainsMap[domainExternalId];

  if (domain) {
    if (domain.sub_domain_of && domain.sub_domain_of.length === 1) {
      return [
        ...resolveDomainRelations(
          domain.sub_domain_of[0].data.id,
          domainsMap,
          extractor,
        ),
        extractor(domain),
      ];
    }
    return [extractor(domain)];
  }

  return [];
}
