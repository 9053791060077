import React from 'react';
import { MarkdownContent } from '@backstage/core-components';
import {
  Box,
  Theme,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { WittyIcon } from '../icons/WittyIcon';
import { Feedbacks } from './Feedbacks';
import { WbUserAvatar } from '../../WbUserAvatar';

export interface WittyMessage {
  sender: 'witty' | 'you';
  contentMessage: string;
  isFeedbackActive?: boolean;
}

type StyleProps = {
  sender: 'witty' | 'you';
};

const useStyles = makeStyles<Theme, StyleProps>(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(1),
      alignItems: props => (props.sender === 'you' ? 'flex-end' : 'auto'),
    },
    markdown: {
      flex: 1,
      overflow: 'auto',
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    userMessage: {
      backgroundColor: theme.palette.bkg.primary,
      width: '100%',
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    avatar: {
      width: '24px',
      height: '24px',
    },
  }),
  { name: 'message' },
);

export const Message: React.FC<WittyMessage> = ({
  sender,
  contentMessage,
  isFeedbackActive = true,
}) => {
  const classes = useStyles({ sender });
  const theme = useTheme();

  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center" style={{ gap: theme.spacing(1) }}>
        {sender === 'witty' ? (
          <WittyIcon style={{ color: theme.palette.witboostLogo }} />
        ) : (
          <WbUserAvatar className={classes.avatar} />
        )}
        <Typography style={{ fontWeight: 600 }} variant="body2">
          {sender.toLocaleUpperCase()}:
        </Typography>
      </Box>
      <Box className={sender !== 'witty' ? classes.userMessage : ''}>
        <MarkdownContent
          className={classes.markdown}
          content={contentMessage}
        />
        {sender === 'witty' && isFeedbackActive && <Feedbacks />}
      </Box>
    </Box>
  );
};
