import { Tooltip } from '@material-ui/core';
import React, { useMemo } from 'react';
import { composeNoContextMessage } from './messages';
import { WittyTableColumn } from '../../contexts';

export type WittyNoContextTooltipProps = {
  triggeringColumns: WittyTableColumn[];
  children: React.ReactElement;
  hide?: boolean;
};

export const WittyNoContextTooltip = ({
  triggeringColumns,
  children,
  hide = true,
}: WittyNoContextTooltipProps) => {
  const message = useMemo(
    () => composeNoContextMessage(triggeringColumns),
    [triggeringColumns],
  );
  return (
    <Tooltip title={message} disableHoverListener={hide}>
      {children}
    </Tooltip>
  );
};
