import React, { ReactElement, ReactNode, useMemo } from 'react';
import { ActionsContextProvider } from '../../context';
import { extractIncludeFromCode, useCustomActions } from './utils';
import { CustomViewPages } from './pages';
import { dumpJsxAsYaml } from '../../utils';

type CustomViewPageMetadataType = {
  included: any[];
  getCodeAsYaml: () => string;
  pageId: string;
};

export const CustomViewPageMetadata: React.FC<{
  pageId: string;
  children: (metadata: CustomViewPageMetadataType) => ReactNode;
}> = ({ pageId, children }) => {
  const getCustomViewChildren = CustomViewPages?.get(pageId);
  const child = getCustomViewChildren!();
  const included = extractIncludeFromCode(child as ReactElement, '', '');
  const realActions = useCustomActions(pageId, included);

  const metadata = useMemo(
    () => ({
      included,
      getCodeAsYaml: () => dumpJsxAsYaml(child),
      pageId: pageId,
    }),
    [child, included, pageId],
  );

  return (
    <ActionsContextProvider actions={realActions}>
      {children(metadata)}
    </ActionsContextProvider>
  );
};
