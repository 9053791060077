import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { WbCardContent } from '@agilelab/plugin-wb-platform';
import { PermissionsTable } from './PermissionsTable';

const useStyles = makeStyles(
  {
    paper: {
      height: '100%',
    },
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  { name: 'PermissionsTab' },
);

export const PermissionsTab: React.FC = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <WbCardContent className={classes.container}>
        <PermissionsTable />
      </WbCardContent>
    </Paper>
  );
};
