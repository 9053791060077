import {
  CustomViewComponent,
  CustomViewPageCategory,
  CustomViewPages,
  useCustomViewInfoFromConfig,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import preview from './preview/marketplace_component_general/marketplace_component_general.png';
import thumbnail from './preview/marketplace_component_general/marketplace_component_general_thumbnail.png';

CustomViewPages.register({
  id: 'marketplace_component_general',
  meta: {
    label: 'Component General Information',
    category: CustomViewPageCategory.MARKETPLACE,
    preview: {
      thumbnail: { src: thumbnail },
      items: [{ src: preview }],
    },
  },
  function: function ComponentGeneral() {
    const additionalGeneralInfo = useCustomViewInfoFromConfig(
      'mesh.marketplace.ui.outputPortPage.additionalGeneralInfo',
      { showOnlyWithValues: true },
    );

    return (
      <CustomViewComponent
        type="marketplace_info_card"
        title="General Information"
      >
        <CustomViewComponent type="hbox">
          <CustomViewComponent type="container" size={3}>
            <CustomViewComponent
              type="automatic_fields_list"
              path=""
              defaults={{
                description: { colSpan: 4 },
                processDescription: { colSpan: 2 },
              }}
              exclude={[
                'useCaseTemplateId',
                'infrastructureTemplateId',
                'id',
                'creationDate',
                'version',
                'email',
                'outputPortType',
                'preview',
                'shoppable',
                'consumable',
              ]}
              order={['name', 'description', 'kind', 'platform', 'technology']}
            />
            <CustomViewComponent
              label="Preview"
              type="string"
              href="{{ preview }}"
              value="Link to the preview file"
              showWhenExists="preview"
            />
            {additionalGeneralInfo}
          </CustomViewComponent>
          <CustomViewComponent type="container" borderLeft>
            <CustomViewComponent
              label="Environment"
              type="string"
              value="{{ _system.environment.name }}"
              colSpan="4"
              showWhenExists="_system.environment.name"
            />
            <CustomViewComponent
              label="Version"
              type="string"
              value="{{version}}"
              colSpan="4"
              showWhenExists="version"
            />
            <CustomViewComponent
              label="Published Date"
              type="date"
              value="{{ _system.published_at }}"
              colSpan="4"
              showWhenExists="_system.published_at"
            />
            <CustomViewComponent
              label="Domain"
              type="string"
              value="{{ _system.descriptor.domain }}"
              colSpan="4"
              showWhenExists="_system.descriptor.domain"
            />
            <CustomViewComponent
              label="System"
              type="string"
              value="{{ _system.display_name }}"
              colSpan="4"
              showWhenExists="_system.display_name"
            />
            <CustomViewComponent
              label="System"
              type="string"
              value="{{ _system.name }}"
              colSpan="4"
              showWhenExists="_system.name"
              hideWhenExists="_system.display_name"
            />

            <CustomViewComponent
              label="Parent"
              type="string"
              value="{{ _computedInfo.parentComponent[0].data.display_name }}"
              colSpan="4"
              showWhenExists="_computedInfo.parentComponent[0]"
            />
          </CustomViewComponent>
        </CustomViewComponent>
        <CustomViewComponent
          type="hline"
          showWhen={{
            value: '{{ tags.length }}',
            notEquals: 0,
          }}
        />
        <CustomViewComponent
          showWhen={{ value: '{{ tags.length }}', notEquals: 0 }}
          label="Tags"
          type="tags"
          path="tags"
        />
        <CustomViewComponent type="marketplace_flag_and_score" />
      </CustomViewComponent>
    );
  },
});
