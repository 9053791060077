import React, { Fragment } from 'react';
import { WbShowMoreElement } from '../WbShowMoreElement';
import { WbLink } from '../WbLink';

type Props = {
  value: string;
  href?: string;
  hrefTarget?: string;
  maxLines?: number;
  showMoreButton?: boolean;
};

export const ExpandableItem = ({
  value,
  href,
  maxLines,
  showMoreButton,
  hrefTarget,
}: Props) => {
  const renderedValue = (
    <WbShowMoreElement maxLines={maxLines} showMoreButton={showMoreButton}>
      {value}
    </WbShowMoreElement>
  );
  return (
    <Fragment>
      {href ? (
        <WbLink
          route={href}
          hrefTarget={href.startsWith('http') ? hrefTarget : undefined}
          externalLink={
            href.startsWith('http') || href.startsWith('mailto')
              ? href
              : undefined
          }
          underlineLink="hover"
          text={value}
          showTooltip
        />
      ) : (
        renderedValue
      )}
    </Fragment>
  );
};
