import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '8px 12px',
    },
  }),
  { name: 'WbCardContent' },
);

export const WbCardContent: React.FC<Props> = ({
  children,
  style,
  className,
}) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className)} style={style}>
      {children}
    </Box>
  );
};
