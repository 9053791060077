import { TableCellProps } from '@agilelab/plugin-wb-platform';
import {
  GetPermissionsResponse,
  PermissionEntity,
} from '@agilelab/plugin-wb-rbac-common';
import {
  alertApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import React, { useContext, useState } from 'react';
import useAsyncFn, { AsyncState } from 'react-use/lib/useAsyncFn';
import { rbacApiRef } from '../../..';
import { useOverviewContext } from './OverviewContext';

export interface Filters {
  text?: string;
}

export interface RolePermissionsTableContextProviderProps {
  children?: React.ReactNode;
}

export interface RolePermissionsTableContextType
  extends RolePermissionsTableContextProviderProps {
  openManageDrawer: boolean;
  setOpenManageDrawer: React.Dispatch<React.SetStateAction<boolean>>;

  error: Error | undefined;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;

  openConfirmDialog: boolean;
  setOpenConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>;

  removePermissions: (permissions: string[]) => Promise<string[]>;
  removePermissionsState: AsyncState<string[]>;

  loadPermissions: () => Promise<GetPermissionsResponse>;
  permissionsLoaded: AsyncState<GetPermissionsResponse | undefined>;

  updateRole: (permissions: PermissionEntity[]) => Promise<void>;

  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;

  defaultColumns: TableCellProps<PermissionEntity>[];

  selectedDeleteItems: PermissionEntity[];
  setSelectedDeleteItems: React.Dispatch<
    React.SetStateAction<PermissionEntity[]>
  >;

  selectedPermissions: PermissionEntity[];
  setSelectedPermissions: React.Dispatch<
    React.SetStateAction<PermissionEntity[]>
  >;
}

export const RolePermissionsTableContext =
  React.createContext<RolePermissionsTableContextType>(
    {} as RolePermissionsTableContextType,
  );

export const RolePermissionsTableContextProvider = ({
  children,
}: RolePermissionsTableContextProviderProps) => {
  const { roleId } = useOverviewContext();
  const rbacApi = useApi(rbacApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(alertApiRef);

  const [error, setError] = useState<Error>();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openManageDrawer, setOpenManageDrawer] = useState(false);

  const [filters, setFilters] = useState<Filters>({});

  const [selectedDeleteItems, setSelectedDeleteItems] = useState<
    PermissionEntity[]
  >([]);

  const [selectedPermissions, setSelectedPermissions] = useState<
    PermissionEntity[]
  >([]);

  const defaultColumns: TableCellProps<PermissionEntity>[] = [
    {
      field: 'id',
      cellProps: {
        size: 'small',
        align: 'left',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'displayName',
      cellProps: {
        size: 'small',
        align: 'left',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'Name',
      sortable: true,
    },
    {
      field: 'description',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'Description',
    },
  ];

  const [permissionsLoaded, loadPermissions] = useAsyncFn(async () => {
    const fetchPermissions = async (): Promise<GetPermissionsResponse> => {
      const res = await rbacApi.getRolePermissions({
        searchKeyword: filters.text,
        roleId,
        options: {
          token: (await identityApi.getCredentials()).token,
        },
      });
      return res;
    };

    return fetchPermissions();
  }, [filters]);

  const updateRole = async (permissions: PermissionEntity[]) => {
    try {
      const token = (await identityApi.getCredentials()).token;
      const permissionIds = permissions.map(p => p.id);

      const res = await rbacApi.updateRolePermissions({
        roleId,
        permissions: permissionIds,
        options: { token },
      });

      setOpenManageDrawer(false);

      let message = 'Permissions updated successfully.';
      if (
        res.addedPermissions.length > 0 ||
        res.removedPermissions.length > 0
      ) {
        message += 'Changes:';
        if (res.addedPermissions.length > 0) {
          message += `Added: ${res.addedPermissions.length}.`;
        }
        if (res.removedPermissions.length > 0) {
          message += `Removed: ${res.removedPermissions.length}.`;
        }
      }

      alertApi.post({ message, severity: 'success' });
      loadPermissions();
    } catch (e) {
      alertApi.post({ message: `Error: ${e.message || e}`, severity: 'error' });
    }
  };

  const [removePermissionsState, removePermissions] = useAsyncFn(
    async (permissions: string[]) => {
      try {
        const token = (await identityApi.getCredentials()).token;

        const results = await Promise.allSettled(
          permissions.map(permissionId =>
            rbacApi.deleteRolePermissions({
              filters: { roleId, permissionId },
              options: { token },
            }),
          ),
        );

        const failed = results.filter(result => result.status === 'rejected');

        if (failed.length === permissions.length) {
          alertApi.post({
            message: `${failed.length} permission(s) failed to remove.`,
            severity: 'error',
          });
        } else {
          alertApi.post({
            message: `Permissions successfully removed`,
            severity: 'success',
          });
        }

        loadPermissions();
      } catch (e) {
        alertApi.post({
          message: e.message || 'An error occurred',
          severity: 'error',
        });
      }
      return [];
    },
    [],
  );

  return (
    <RolePermissionsTableContext.Provider
      value={{
        openManageDrawer,
        setOpenManageDrawer,

        defaultColumns,

        loadPermissions,
        permissionsLoaded,

        error,
        setError,

        openConfirmDialog,
        setOpenConfirmDialog,

        removePermissions,
        removePermissionsState,

        filters,
        setFilters,

        selectedDeleteItems,
        setSelectedDeleteItems,

        selectedPermissions,
        setSelectedPermissions,

        updateRole,
      }}
    >
      {children}
    </RolePermissionsTableContext.Provider>
  );
};

export const useRolePermissionsTableContext = () => {
  const context = useContext(RolePermissionsTableContext);
  if (!context) {
    throw new Error(
      'useRolePermissionsTableContext must be used within a RolePermissionsTableContextProvider',
    );
  }
  return context as RolePermissionsTableContextType;
};
