import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/SystemType.v2alpha1.schema.json';

type SystemTypeEntity = Entity &
  Omit<ProjectType, 'spec'> & {
    apiVersion: 'witboost.com/v2';
    kind: 'SystemType';
    metadata: {
      displayName?: string;
      pluralizedDisplayName?: string;
      displayPriority?: number;
    } & EntityMeta;
    spec: Omit<
      ProjectType['spec'],
      'resourceTypeId' | 'belongsTo' | 'partOfDomain'
    > & {
      consumable?: boolean;
      shoppable?: boolean;
      abstract: boolean;
      belongsTo?: string;
      partOfDomain?: string;
      partiallyDeployableIn?: string[];
      resourceTypeId?: string;
      extends?: string[];
      ownedBy?: string;
      implements?: string[];
      schema?: object;
    };
  };

export type { SystemTypeEntity as SystemType };

export const systemTypeEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);

export function isSystemType(entity: Entity): entity is SystemTypeEntity {
  return (
    entity.apiVersion === 'witboost.com/v2' && entity.kind === 'SystemType'
  );
}
