import {
  EllipsisText,
  WbAutocomplete,
  WbChip,
  customAlertApiRef,
  renderMessageWithSeverity,
  useTruncatedStyles,
} from '@agilelab/plugin-wb-platform';
import { CustomError } from '@agilelab/plugin-wb-platform-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Checkbox,
  FormControl,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AutocompleteRenderOptionState } from '@material-ui/lab';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  WmCompleteUiSchema,
  WmFieldExtensionComponentProps,
} from '../../../extensions/types';
import { usePrevious } from '../../hooks/useEventStream';
import { extractCustomProperties, isHidden } from '../../utils';
import {
  CustomUrlOperations,
  MicroserviceConfig,
  fetchAllMicroserviceValues,
  fetchMicroserviceValues,
  getApiConfigId,
  parseConfiguration,
  parseDynamicFields,
  validateUiSchema,
} from './CustomUrlUtilities';
import {
  useWittyAutoselect,
  useWittyTable,
  useWittyTableRow,
  WittyInputAdornment,
  WittyNoContextTooltip,
} from '@agilelab/plugin-wb-witty-react';
import {
  AutocompleteOption,
  PickerMessage,
  retrievalErrorPickerMessage,
} from './types';

interface CustomUrlPickerUiOptions {
  maxNumberToSelect?: number;
  allowArbitraryValues?: boolean;
  selectedField: string;
}

export const maxNumberToSelect = (
  uiSchema: WmCompleteUiSchema<CustomUrlPickerUiOptions>,
): number | undefined => uiSchema['ui:options']?.maxNumberToSelect;

export const allowArbitraryValues = (
  uiSchema: WmCompleteUiSchema<CustomUrlPickerUiOptions>,
): boolean =>
  (uiSchema['ui:options']?.allowArbitraryValues as boolean) ?? false;

const useStyles = makeStyles(theme => ({
  ul: {
    maxHeight: '300px',
  },
  headerText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
}));

const generateColumns = (fields: string[], options: Record<string, any>[]) => {
  const columns = fields.map(field => {
    const optionLengths = options.map(option => `${option[field]}`.length);
    const maxOptionLength = Math.max(...optionLengths);
    return fields.length === 1
      ? `minmax(${maxOptionLength * 10}px, 1fr)`
      : `${maxOptionLength * 10}px`;
  });
  const stringColumns = columns.join(' ');
  const gridColumns = `40px ${stringColumns}`;
  return gridColumns;
};

const TableHeader: React.FC<{
  children?: React.ReactNode;
  fields?: string[];
  options?: Record<string, any>[];
}> = ({ children, fields = [], options = [] }) => {
  const classes = useStyles();

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridGap: '20px',
          gridTemplateColumns: generateColumns(fields, options),
          padding: '6px 12px',
          width: '100%',
        }}
      >
        <div />

        {fields.map(field => (
          <Typography
            key={field}
            style={{ padding: 0 }}
            className={classes.headerText}
          >
            {field}
          </Typography>
        ))}
      </div>

      {children}
    </>
  );
};

const TableRowOption: React.FC<{
  option: any;
  state: AutocompleteRenderOptionState;
  uiSchema: WmCompleteUiSchema<any>;
  fields?: string[];
  options?: Record<string, any>[];
}> = ({ option, state, fields = [], options = [] }) => {
  const truncatedClass = useTruncatedStyles();

  const { selected } = state;

  return (
    <div
      style={{
        height: '36px',
        display: 'grid',
        gridGap: '20px',
        gridTemplateColumns: generateColumns(fields, options),
        width: '100%',
      }}
    >
      <Checkbox style={{ padding: 0 }} checked={selected} />
      {fields
        .filter(key => Object.keys(option).includes(key))
        .map(key => (
          <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
            <div className={truncatedClass.truncated}>{option[key]}</div>
          </div>
        ))}
    </div>
  );
};

/**
 * This Picker enables users to select multiple values fetched from a microservice.
 * @param props
 * @returns
 */
export const CustomUrlPicker = (
  props: WmFieldExtensionComponentProps<string, any>,
) => {
  const {
    onChange,
    schema: {
      title = 'Custom URL',
      description = 'A list of values fetched from a Microservice',
    },
    required,
    uiSchema,
    rawErrors,
    idSchema,
    formData,
    formContext,
    name,
  } = props;
  const alertApi = useApi(customAlertApiRef);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<AutocompleteOption[]>(
    [],
  );
  const [autocompleteValues, setAutocompleteValues] = useState<any>(
    formData || [],
  );
  const theme = useTheme();
  const prevFormContext = usePrevious(formContext);
  const [validatedUiSchema, setUiSchema] = useState<
    WmCompleteUiSchema<CustomUrlPickerUiOptions> | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [enableSearch, setEnableSearch] = useState<boolean>(true);
  const [messages, setMessages] = useState<Map<string, PickerMessage>>(
    new Map([]),
  );
  const [templateParams, setTemplateParams] = useState<any>();
  const [microserviceConfig, setMicroserviceConfig] =
    useState<MicroserviceConfig>();
  const config = useApi(configApiRef);
  const [limitReached, setLimitReached] = useState<boolean>(
    !!(
      maxNumberToSelect(uiSchema) &&
      autocompleteValues.length >= maxNumberToSelect(uiSchema)!
    ),
  );
  const [offset, setOffset] = useState<number>(0);
  const classes = useStyles();
  const [position, setPosition] = useState(0);
  const listElem = useRef<HTMLDivElement>();
  const mounted = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!mounted.current) return;
    else if (position && listElem.current) {
      listElem.current.scrollTop = position - listElem.current.offsetHeight;
    }
  }, [position]);

  useEffect(() => {
    try {
      const validatedSchema = validateUiSchema(uiSchema);
      setUiSchema(validatedSchema);
      setMicroserviceConfig(
        parseConfiguration(
          config,
          validatedSchema,
          CustomUrlOperations.RETRIEVAL,
        ),
      );
    } catch (err) {
      alertApi.post({
        error: new CustomError(
          'Error: CustomUrlPicker Validation',
          err.message,
        ),
        severity: 'error',
      });
    }
  }, [uiSchema, alertApi, config]);

  /**
   * This useEffect method is used to parse dynamic parameters and to set the errors list in case of missing resolution.
   * This is used separately from the others to make the CustomUrlPicker changes on the basis of the value of dependent fields.
   */
  useEffect(() => {
    if (validatedUiSchema)
      setTemplateParams(
        parseDynamicFields(
          formContext,
          prevFormContext,
          val => {
            setAutocompleteValues(val);
            onChange(undefined);
            setLimitReached(false);
            setMessages(prevErrors => {
              prevErrors.delete('limit_error');
              return prevErrors;
            });
          },
          setEnableSearch,
          setOffset,
          setMessages,
          validatedUiSchema['ui:apiSpec']!.retrieval.params,
        ),
      );
  }, [
    onChange,
    formContext,
    prevFormContext,
    setAutocompleteValues,
    setMessages,
    validatedUiSchema,
  ]);

  /**
   * This useEffect hook implements the search mechanism by using debounce. When a user types some string
   * in the picker, useEffect is triggered and after a delay it performs a request to the microservice.
   */
  useEffect(() => {
    if (validatedUiSchema && enableSearch && microserviceConfig) {
      setLoading(true);
      const microserviceValues = setTimeout(
        () =>
          fetchMicroserviceValues(
            microserviceConfig,
            offset,
            templateParams,
            searchInput,
          )
            .then(response => {
              setLoading(false);
              setEnableSearch(false);
              if (response.ok) {
                setFilteredOptions(response.options);
              } else {
                setFilteredOptions([]);
                setMessages(prevErrors =>
                  prevErrors.set(
                    retrievalErrorPickerMessage.key,
                    retrievalErrorPickerMessage.value,
                  ),
                );
              }
            })
            .catch(err => {
              setLoading(false);
              setEnableSearch(false);
              alertApi.post({
                error: new CustomError(
                  'Error: CustomUrlPicker HTTP request',
                  err.message,
                ),
                severity: 'error',
              });
            }),
        1500,
      );
      return () => clearTimeout(microserviceValues);
    }
    return undefined;
  }, [
    enableSearch,
    validatedUiSchema,
    templateParams,
    searchInput,
    alertApi,
    microserviceConfig,
    offset,
  ]);

  const checkLimit = useCallback(
    (option: any) =>
      limitReached &&
      !autocompleteValues.map((val: any) => val.id).includes(option.id),
    [limitReached, autocompleteValues],
  );

  const selectFieldsToSave = useCallback(
    (val: Record<string, any> | string) => {
      return typeof val === 'object'
        ? Object.keys(val)
            .filter(key =>
              (
                validatedUiSchema?.['ui:fieldsToSave'] ??
                validatedUiSchema?.['ui:displayFields']
              )?.includes(key),
            )
            .reduce((acc, curr) => ({ ...acc, [curr]: val[curr] }), {})
        : val;
    },
    [validatedUiSchema],
  );

  const fieldId = useMemo(() => `${idSchema.$id}`, [idSchema.$id]);

  const wittyTable = useWittyTable();
  const wittyTableRow = useWittyTableRow();
  const canAutocomplete = wittyTableRow?.canAutocomplete;
  const triggeringColumns = wittyTable?.triggeringColumns ?? [];

  const onSelect = useCallback(
    (_: any, val: any) => {
      if (
        required &&
        Array.isArray(val) &&
        !val.length &&
        prevFormContext &&
        prevFormContext[name] !== val
      ) {
        setAutocompleteValues([]);
        onChange(undefined);
        setLimitReached(false);
        setMessages(prevErrors => {
          prevErrors.delete('limit_error');
          return prevErrors;
        });
      } else {
        setAutocompleteValues(val);
        onChange(val.map(selectFieldsToSave));
        const limit = maxNumberToSelect(uiSchema);
        if (limit && val.length >= limit) {
          /**
           * This is a workaround adopted for arbitrary values, we don't have so much control on those,
           * so we decided to remove the last value if the user reach the maximum number of values.
           */
          if (val.length > limit) {
            const shiftedVal = val.slice(0, val.length - 1);
            setAutocompleteValues(shiftedVal);
            onChange(shiftedVal.map(selectFieldsToSave));
          }
          setLimitReached(true);
          setMessages(prevErrors =>
            prevErrors.set('limit_error', {
              line: 'You have reached the maximum number of items to select. Just delete and reselect one or more values, if you want to replace them.',
              severity: 'info',
            }),
          );
        } else {
          setMessages(prevErrors => {
            prevErrors.delete('limit_error');
            return prevErrors;
          });
          setLimitReached(false);
        }
      }
    },
    [
      uiSchema,
      required,
      prevFormContext,
      name,
      onChange,
      setAutocompleteValues,
      selectFieldsToSave,
    ],
  );

  /**
   * Fetches the entire options from the microservice, page by page, until a page with 0 elements is met (the last one)
   */
  const fetchAllOptions = useCallback(async () => {
    if (!microserviceConfig) throw new Error('Error in microservice config');
    return fetchAllMicroserviceValues(microserviceConfig, templateParams);
  }, [templateParams, microserviceConfig]);

  // a string that uniquely identifies the combination of the params to send to the microservice (except limit and offset) and the microservice id
  const apiConfigIdentifier = useMemo(() => {
    if (!uiSchema['ui:apiSpec']) return null;
    return getApiConfigId(
      uiSchema['ui:apiSpec'].retrieval.microserviceId,
      templateParams,
    );
  }, [templateParams, uiSchema]);

  const witty = useWittyAutoselect({
    fieldId: idSchema.$id,
    schema: props.schema,
    onSelect: onSelect,
    optionsSetId: apiConfigIdentifier,
  });

  const updateWittyFields = wittyTable?.updateWittyFields;

  useEffect(() => {
    if (
      updateWittyFields &&
      apiConfigIdentifier &&
      witty.enabled &&
      canAutocomplete
    ) {
      updateWittyFields({
        type: 'ADD_FIELD',
        payload: {
          optionsSetId: apiConfigIdentifier,
          fieldId: fieldId,
          type: 'multiselect',
          fetchOptions: fetchAllOptions,
          notify: (_: any, val: any) => {
            onSelect(val, val);
          },
        },
      });
    }

    return () => {
      updateWittyFields?.({ type: 'REMOVE_FIELD', payload: fieldId });
    };
  }, [
    fieldId,
    fetchAllOptions,
    onSelect,
    witty.enabled,
    updateWittyFields,
    apiConfigIdentifier,
    props.formContext,
    canAutocomplete,
  ]);

  const customProps = {
    ...extractCustomProperties(uiSchema),
  };

  const optionsLimit = uiSchema.optionsLimit;

  return (
    <FormControl
      style={{
        display: isHidden(uiSchema) ? 'none' : undefined,
        paddingTop: '0px',
        paddingBottom: '0px',
      }}
      required={required}
      error={rawErrors?.length > 0}
    >
      <WbAutocomplete
        size="small"
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
        id={idSchema?.$id}
        options={filteredOptions || []}
        multiple
        getOptionDisabled={checkLimit}
        disabled={witty.loading || props.disabled}
        loading={loading}
        placeholder="Type to search"
        value={autocompleteValues}
        onChange={onSelect}
        ref={mounted}
        classes={{
          listbox: classes.ul,
        }}
        startAdornment={
          witty.enabled && (
            <WittyNoContextTooltip
              triggeringColumns={triggeringColumns}
              hide={canAutocomplete}
            >
              <span>
                <WittyInputAdornment
                  disabled={!canAutocomplete}
                  loading={witty.loading}
                  onClick={witty.suggestOptions}
                />
              </span>
            </WittyNoContextTooltip>
          )
        }
        popupIcon={<ExpandMoreIcon />}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget;
            const scrollPosition =
              listboxNode.scrollTop + listboxNode.clientHeight;

            const currentScrollPosition = Math.round(
              listboxNode.scrollTop + listboxNode.clientHeight,
            );
            const endPosition = Math.round(listboxNode.scrollHeight);
            /**
             * This part here takes in consideration that the infinite scrolling mechanism can work differently
             * depending on the screen resolution. This means that the scrollbar position can be considered at "the end"
             * of the list box also if there are 2 pixels away from the end.
             */
            if (
              currentScrollPosition - endPosition <= 2 &&
              currentScrollPosition - endPosition >= 0 &&
              validatedUiSchema &&
              microserviceConfig &&
              !fetching
            ) {
              setFetching(true);
              fetchMicroserviceValues(
                microserviceConfig,
                offset + 1,
                templateParams,
                searchInput,
              )
                .then(results => {
                  if (results.ok) {
                    setFilteredOptions([
                      ...filteredOptions,
                      ...results.options,
                    ]);
                    setOffset(prevOffset => prevOffset + 1);
                    setPosition(scrollPosition);
                  } else {
                    setMessages(prevErrors =>
                      prevErrors.set(
                        retrievalErrorPickerMessage.key,
                        retrievalErrorPickerMessage.value,
                      ),
                    );
                  }
                })
                .catch(err =>
                  alertApi.post({
                    error: new CustomError(
                      'Error: CustomUrlPicker HTTP request',
                      err.message,
                    ),
                    severity: 'error',
                  }),
                )
                .finally(() => setFetching(false));
            }
          },
          ref: listElem,
          style: {
            overflowX: 'auto',
          },
        }}
        inputValue={searchInput}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === 'input') {
            setFilteredOptions([]);
            setOffset(0);
            setEnableSearch(true);
            setSearchInput(newInputValue);
          }
        }}
        disableCloseOnSelect
        freeSolo={allowArbitraryValues(uiSchema)}
        getOptionLabel={(option: any) =>
          Object.keys(option)
            .map(key => `${key}: ${option[key]}`)
            .join(' ')
        }
        getOptionSelected={(option: any, val: any) => {
          if (typeof option === 'object' && typeof val === 'object') {
            return option.id === val.id;
          }

          return option === val;
        }}
        groupBy={() => ''}
        renderGroup={params => (
          <TableHeader
            fields={validatedUiSchema?.['ui:displayFields']}
            options={filteredOptions}
          >
            {params.children}
          </TableHeader>
        )}
        renderOption={(option: any, state) => (
          <TableRowOption
            key={option.id}
            fields={validatedUiSchema?.['ui:displayFields']}
            state={state}
            uiSchema={uiSchema}
            option={option}
            options={filteredOptions}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.length > 2 && optionsLimit ? (
            <>
              {value.slice(0, 2).map((option: any, index) => (
                <WbChip
                  key={index}
                  label={
                    <EllipsisText maxWidth={80}>
                      {typeof option === 'object'
                        ? `${
                            option[uiSchema['ui:options'].selectedField] ??
                            option
                          }`
                        : option}
                    </EllipsisText>
                  }
                  {...getTagProps({ index })}
                />
              ))}
              <WbChip label={`+${value.length - 2}`} />
            </>
          ) : (
            value.map((option: any, index) => (
              <WbChip
                label={
                  <EllipsisText maxWidth={250}>
                    {typeof option === 'object'
                      ? `${
                          option[uiSchema['ui:options'].selectedField] ?? option
                        }`
                      : option}
                  </EllipsisText>
                }
                {...getTagProps({ index })}
              />
            ))
          )
        }
        label={title}
        helperText={
          messages.size
            ? renderMessageWithSeverity(Array.from(messages.values()), theme)
            : description
        }
        required={required}
        {...customProps}
      />
    </FormControl>
  );
};
