import { Button } from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Sort from '@material-ui/icons/Sort';
import React, { Dispatch, SetStateAction } from 'react';

export enum OrderOptions {
  Asc = 'Asc',
  Desc = 'Desc',
}

type Props = {
  value: OrderOptions;
  setValue: Dispatch<SetStateAction<OrderOptions>>;
  style?: React.CSSProperties;
};

export const WbOrderButton = ({ value, setValue, style }: Props) => {
  const Arrow = value === OrderOptions.Asc ? ArrowUpward : ArrowDownward;
  return (
    <div style={{ display: 'inline', ...style }}>
      <Button
        size="small"
        onClick={() => {
          if (value === OrderOptions.Asc) {
            setValue(OrderOptions.Desc);
          } else {
            setValue(OrderOptions.Asc);
          }
        }}
      >
        <Arrow style={{ fontSize: '1.2rem' }} />
        <Sort style={{ fontSize: '1.2rem' }} />
      </Button>
    </div>
  );
};
