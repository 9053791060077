import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

type DotProps = {
  active?: boolean;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      borderRadius: '50%',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      backgroundColor: theme.palette.blueGrey[500],
    },
    active: {
      border: `1px solid ${theme.palette.white}`,
      backgroundColor: theme.palette.primary.main,
    },
  }),
  { name: 'Dot' },
);

export const Dot: React.FC<DotProps> = ({ active }) => {
  const classes = useStyles();
  return <Box className={clsx(classes.root, active && classes.active)} />;
};
