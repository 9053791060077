export type AutocompleteOption = {
  id: string;
  value: string;
  description: string;
};

export type PickerMessage = {
  line: string;
  severity: 'info' | 'warning' | 'error';
};

export type FetchOptionsResponse = {
  options: AutocompleteOption[];
  ok: boolean;
};

export const retrievalErrorPickerMessage: {
  key: string;
  value: PickerMessage;
} = {
  key: 'retrieval_error',
  value: {
    line: 'Error while fetching values. Retry later or contact platform team.',
    severity: 'error',
  },
};
