import {
  Box,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { useMemo } from 'react';

import { TableCellProps, WbTableSort, WbTableSortDirection } from '../types';
import { useWbTableContext } from '../context/WbTableContext';

export interface TableHeaderProps<T> {
  style?: React.CSSProperties;
  onSort?: WbTableSort<T>;
  multiSelection?: boolean;
  selectedItems?: T[];
  setSelectedItems?: React.Dispatch<React.SetStateAction<T[]>>;
}

const getNewDirection = (d: WbTableSortDirection | undefined) => {
  switch (d) {
    case 'asc':
      return 'desc';
    case 'desc':
      return 'asc';
    default:
      return 'asc';
  }
};

export function TableHeader<T>({ style }: TableHeaderProps<T>) {
  const {
    columns,
    rows,
    onSort,
    selection,
    selectedItems,
    setSelectedItems,
    checkIfDisabled,
    tooltipIfDisabled,
  } = useWbTableContext<T>();
  const theme = useTheme();
  const [active, setActive] = React.useState<string>();
  const [direction, setDirection] = React.useState<WbTableSortDirection>();

  const getTableCellComponent = (cell: TableCellProps<T>) => {
    const title = (
      <Typography color="primary" component="div" variant="button">
        <Box fontSize={theme.typography.body2.fontSize}>{cell.headerName}</Box>
      </Typography>
    );

    const component =
      cell.sortable && onSort ? (
        <TableSortLabel
          active={active === cell.headerName}
          direction={direction}
          onClick={e => {
            setActive(cell.headerName);
            setDirection(d => {
              const newDirection = getNewDirection(d);
              onSort(e, cell, newDirection);
              return newDirection;
            });
          }}
        >
          {title}
        </TableSortLabel>
      ) : (
        title
      );

    if (!!cell.headerDescription) {
      return <Tooltip title={cell.headerDescription} children={component} />;
    }

    return component;
  };

  const selectableRows = useMemo(() => {
    return rows.filter(r => !checkIfDisabled?.(r));
  }, [rows, checkIfDisabled]);

  const Selector = () => (
    <Checkbox
      disabled={selectableRows.length === 0}
      indeterminate={
        (selectedItems!.length > 0 &&
          selectedItems!.length < selectableRows.length) ||
        selectableRows.length === 0
      }
      onClick={() => {
        if (selectedItems!.length === selectableRows.length) {
          setSelectedItems!([]);
        } else {
          setSelectedItems!(selectableRows);
        }
      }}
      checked={selectedItems?.length === selectableRows.length}
      color="primary"
    />
  );

  return (
    <TableHead
      style={{ background: theme.palette.background.default, ...style }}
    >
      <TableRow>
        {selection === 'multiple' && (
          <TableCell key="all-cell-selector" width="5%">
            {tooltipIfDisabled && selectableRows.length === 0 ? (
              <Tooltip title={tooltipIfDisabled}>
                <span>
                  <Selector />
                </span>
              </Tooltip>
            ) : (
              <Selector />
            )}
          </TableCell>
        )}
        {columns.map((cell, cellIndex) => (
          <TableCell key={`cell-index-${cellIndex}`} {...cell.cellProps}>
            {getTableCellComponent(cell)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
