export const downloadFile = (
  content: string,
  fileName: string,
  fileType: string | undefined = 'text/yaml',
) => {
  const textToWriteBlob = new Blob([content], {
    type: fileType,
  });
  const textToWriteUrl = URL.createObjectURL(textToWriteBlob);
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  downloadLink.href = textToWriteUrl;
  downloadLink.click();
};

export const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
};

export const saveYaml = (filename: string, yamldata: string) => {
  downloadFile(yamldata, `${filename}.yaml`, 'application/yaml');
};
