import { WittyOption } from '@agilelab/plugin-wb-witty-common';
import { ErrorSchema, IChangeEvent } from '@rjsf/core';

export type WittyFieldSubscription =
  | WittyTextFieldSubscription
  | WittyMultiSelectFieldSubscription;

export type WittyTextFieldSubscription = {
  type: 'text';
  fieldId: string;
  notify: (e: IChangeEvent<string> | any, es?: ErrorSchema) => any;
};

export type OptionsSetsCache = Map<
  string,
  { options?: WittyOption[]; fetchFn: () => Promise<WittyOption[]> }
>;

export type WittyMultiSelectFieldSubscription = {
  type: 'multiselect';
  fieldId: string;
  optionsSetId: string;
  fetchOptions: () => Promise<WittyOption[]>;
  notify: (_: any, val: any) => void;
};

export const isWittyMultiSelectFieldSubscription = (
  w: WittyFieldSubscription,
): w is WittyMultiSelectFieldSubscription => w.type === 'multiselect';

export type WittyTableColumn = {
  columnName: string;
  columnKey: string;
};

export type WittyTable = {
  tablePath: string;
  additionalContext: string[];
  updateWittyFields: React.Dispatch<Action>;
  wittyFields: WittyFieldSubscription[];
  rowsNumber: number;
  triggeringColumns: WittyTableColumn[];
  maxRowsPerChunk: number;
  isProvided?: boolean;
  getOptionsSet: (optionsSetId: string) => Promise<WittyOption[]>;
  getOptionsSets: (
    optionsSetIds: string[],
  ) => Promise<Record<string, WittyOption[]>>;
};

export type WittyTableContextProviderProps = {
  additionalContext: string[];
  tablePath: string;
  rowsNumber: number;
  triggeringColumns: WittyTableColumn[];
  maxRowsPerChunk: number;
};

export type Action =
  | { type: 'ADD_FIELD'; payload: WittyFieldSubscription }
  | { type: 'REMOVE_FIELD'; payload: string }
  | { type: 'RESET_FIELDS' };
