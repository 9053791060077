import {
  CustomViewComponent,
  CustomViewPageCategory,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import React from 'react';
import preview from './preview/marketplace_system/marketplace_system.png';
import thumbnail from './preview/marketplace_system/marketplace_system_thumbnail.png';

CustomViewPages.register({
  id: 'marketplace_system',
  meta: {
    label: 'System Detail Page',
    category: CustomViewPageCategory.MARKETPLACE,
    preview: {
      thumbnail: { src: thumbnail },
      items: [{ src: preview }],
    },
  },
  function: function DataProduct() {
    const configApi = useApi(configApiRef);
    const isMultipleAccessRequestEnabled = configApi.getOptionalBoolean(
      'mesh.marketplace.multipleAccessRequestSelection',
    );

    return (
      <CustomViewComponent type="grid_list">
        <CustomViewComponent type="include" id="marketplace_system_general" />
        <CustomViewComponent
          type="marketplace_tech_card"
          configs={configApi.getOptionalStringArray(
            'mesh.marketplace.ui.dataProductPage.technicalInformation',
          )}
        />
        <CustomViewComponent type="hbox" responsive>
          <CustomViewComponent type="grid_list" size={2}>
            <CustomViewComponent type="grid_sequence" path="_componentsByKind">
              <CustomViewComponent
                type="marketplace_component_card"
                title="{{ label }}"
              >
                <CustomViewComponent
                  type="table"
                  path="components"
                  click="showComponent"
                  filters={[
                    {
                      type: 'search',
                      column: 'Name',
                    },
                    {
                      type: 'multiselect',
                      column: 'Type',
                    },
                    {
                      type: 'multiselect',
                      column: 'Technology',
                    },
                    {
                      column: 'Access',
                      type: 'boolean',
                    },
                  ]}
                >
                  <CustomViewComponent
                    type={isMultipleAccessRequestEnabled ? 'checkbox' : 'radio'}
                    path="selected"
                    label=""
                    width="10%"
                    click="setSelection"
                    disabled="{% if shoppable is null or _parent._parent._computedInfo.shoppable === 'SHOPPABLE' %} true {% else %} false {% endif %}"
                    disabledTooltip="The project owner has not enabled individual access requests for this consumable "
                  />
                  <CustomViewComponent
                    type="string"
                    path="name"
                    label="Name"
                    width="30%"
                  />
                  <CustomViewComponent
                    type="string"
                    path="type"
                    label="Type"
                    width="20%"
                  />
                  <CustomViewComponent
                    type="string"
                    path="version"
                    label="Version"
                    width="20%"
                  />
                  <CustomViewComponent
                    type="string"
                    path="descriptor.technology"
                    label="Technology"
                    width="20%"
                  />
                  <CustomViewComponent
                    type="enabled"
                    path="access"
                    label="Access"
                    width="10%"
                  />
                </CustomViewComponent>
              </CustomViewComponent>
            </CustomViewComponent>
          </CustomViewComponent>
          <CustomViewComponent type="grid_list" size={1}>
            <CustomViewComponent type="marketplace_review" />
            <CustomViewComponent type="marketplace_questions" />
          </CustomViewComponent>
        </CustomViewComponent>
      </CustomViewComponent>
    );
  },
});
