import React, { useMemo } from 'react';
import { LayoutTemplate, createScaffolderLayout } from '../../../layouts';
import { scaffolderPlugin } from '../../../plugin';
import { TableRowTemplate } from '../ObjectFieldTemplate/TableRowTemplate';
import {
  useWittyTable,
  WittyTableRowContextProvider,
} from '@agilelab/plugin-wb-witty-react';
import { JsonFormData } from '@agilelab/plugin-wb-witty-common';
import { useDebouncedValue } from '@agilelab/plugin-wb-platform';

export const WittyTableRowTemplate: LayoutTemplate = props => {
  const wittyTable = useWittyTable();
  const triggeringColumns = wittyTable?.triggeringColumns;
  const formContext = useDebouncedValue(props.formContext, 300);
  const canAutocomplete = useMemo(() => {
    if (triggeringColumns) {
      const rowId = props.idSchema.$id;
      return triggeringColumns.some(col => {
        const cellValue = new JsonFormData({ root: formContext }).get(
          `${rowId}_${col.columnKey}`,
        );
        if (Array.isArray(cellValue)) {
          return cellValue.length > 0;
        }

        return cellValue && cellValue !== '' && cellValue !== null;
      });
    }
    return false;
  }, [formContext, props.idSchema.$id, triggeringColumns]);

  if (!TableRowTemplate) {
    return undefined;
  }

  return (
    <WittyTableRowContextProvider
      canAutocomplete={canAutocomplete}
      tableRowPath={props.idSchema.$id}
    >
      <TableRowTemplate {...props} />
    </WittyTableRowContextProvider>
  );
};

export const WittyTableRowTemplateLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'WittyTableRowTemplate',
    component: WittyTableRowTemplate,
  }),
);
