import {
  TableCellProps,
  WbTable,
  WbTableFilters,
} from '@agilelab/plugin-wb-platform';
import {
  GetPermissionsResponse,
  PermissionEntity,
  RoleEntity,
} from '@agilelab/plugin-wb-rbac-common';
import { ErrorPanel, Progress } from '@backstage/core-components';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import React, { useState } from 'react';
import { useAsync } from 'react-use';
import { rbacApiRef } from '../../..';

export interface Filters {
  text?: string;
}

interface PermissionsTableProps {}

export const PermissionsTable: React.FC<PermissionsTableProps> = () => {
  const rbacApi = useApi(rbacApiRef);
  const identityApi = useApi(identityApiRef);
  const [filters, setFilters] = useState<Filters>({});
  const [pagination, setPagination] = useState({
    limit: 15,
    offset: 0,
  });

  const { loading, value, error } = useAsync(async () => {
    const fetchPermissions = async (): Promise<GetPermissionsResponse> => {
      return await rbacApi.getPlatformPermissions({
        searchKeyword: filters.text,
        options: {
          offset: pagination.offset,
          limit: pagination.limit,
          token: (await identityApi.getCredentials()).token,
        },
      });
    };
    return fetchPermissions();
  }, [pagination, filters]);

  const columns: TableCellProps<PermissionEntity>[] = [
    {
      field: 'id',
      cellProps: {
        size: 'small',
        align: 'left',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'displayName',
      cellProps: {
        size: 'small',
        align: 'left',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'Name',
      sortable: true,
    },
    {
      field: 'description',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'Description',
    },
  ];

  if (error)
    return (
      <ErrorPanel
        error={
          error instanceof Error ? error : new Error('Something went wrong')
        }
      />
    );

  return (
    <>
      <WbTableFilters
        searchValue={filters.text}
        onSearch={search => {
          setFilters({ text: search });
        }}
        onClear={() => setFilters({})}
      />
      <WbTable<RoleEntity>
        styles={{
          container: { height: '100%', flex: '1 1 0' },
        }}
        components={{
          tableLoader: {
            loading: loading,
            loader: <Progress />,
          },
          tableContent: {
            columns,
            rows: value?.permissions,
          },
        }}
        pagination={{
          rowsPerPageOptions: [15, 25, 50],
          count: value?.total || 0,
          limit: pagination.limit,
          offset: pagination.offset,
          onPageChange: (page: number) => {
            setPagination({
              ...pagination,
              offset: page * pagination.limit,
            });
          },
          onRowsPerPageChange: (rowsPerPage: number) => {
            setPagination({
              offset: 0,
              limit: rowsPerPage,
            });
          },
        }}
      />
    </>
  );
};
