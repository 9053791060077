import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { IChangeEvent } from '@rjsf/core';

export type WittyForm = {
  onChange: (e: IChangeEvent) => void;
  formData: Record<string, any>;
  formDefinition: Record<string, any>;
  isProvided?: boolean;
};

const defaultWittyFormContext: WittyForm = {
  onChange: () => {},
  formData: {},
  formDefinition: {},
  isProvided: false, // Explicitly mark default as not provided
};

export const WittyFormContext = createContext<WittyForm>(
  defaultWittyFormContext,
);

export const useWittyFormContext = (): WittyForm | undefined => {
  const context = useContext(WittyFormContext);

  if (!context.isProvided) {
    return undefined;
  }

  return context;
};

export const WittyFormContextProvider = ({
  children,
  ...props
}: {
  children: ReactNode;
} & WittyForm) => {
  const value = useMemo(() => ({ ...props, isProvided: true }), [props]);

  return (
    <WittyFormContext.Provider value={value}>
      {children}
    </WittyFormContext.Provider>
  );
};
