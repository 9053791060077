import React from 'react';
import { WittyChat, WittyIcon } from '@agilelab/plugin-wb-platform';
import { makeStyles } from '@material-ui/core';
import { useWittyAssistant } from '../../contexts/useWittyAssistant';

const useStyles = makeStyles(theme => ({
  expandingChat: {
    position: 'absolute',
    top: 0,
    right: 0,
    justifySelf: 'flex-end',
    height: '100vh',
    width: '40vw',
  },
  expandedChat: {
    position: 'absolute',
    top: 0,
    right: 0,
    justifySelf: 'flex-end',
    height: '100vh',
    width: '70vw',
  },
  iconWrapper: {
    position: 'absolute',
    top: 0,
    right: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.white,
    paddingInline: theme.spacing(1.5),
    height: '32px', // same as header
    cursor: 'pointer',
  },
}));

export const WittyAssistant = () => {
  const classes = useStyles();

  const {
    loading,
    messages,
    open,
    onNewMessage,
    setOpen,
    expanded,
    setExpanded,
    onNewSession,
  } = useWittyAssistant();

  return (
    <>
      {open ? (
        <div
          className={expanded ? classes.expandedChat : classes.expandingChat}
        >
          <WittyChat
            incomingMessage={loading}
            messages={messages}
            onNewMessage={onNewMessage}
            onNewSession={onNewSession}
            setOpen={setOpen}
            setChatExpanded={() =>
              setExpanded((prevExpanded: boolean) => !prevExpanded)
            }
            isMicrophoneActive={false}
            isAttachContentActive={false}
            isFeedbackActive={false}
          />
        </div>
      ) : (
        <div className={classes.iconWrapper}>
          <WittyIcon
            onClick={() => {
              setExpanded(false);
              setOpen(true);
            }}
          />
        </div>
      )}
    </>
  );
};
