import {
  LdapAuth,
  ldapAuthApiRef,
  oidcAuthApiRef,
  userConfigApiRef,
} from '@agilelab/plugin-wb-auth';
import { eventsApiRef } from '@agilelab/plugin-wb-events';
import { EventsClient } from '@agilelab/plugin-wb-events-common';
import {
  auditApiRef,
  CustomAlertApiForwarder,
  customAlertApiRef,
  customFrontendApiRef,
  CustomFrontendClient,
  CustomPresentationRetriever,
  CustomPresentationRetrieverApiRef,
  MeshAuditClient,
} from '@agilelab/plugin-wb-platform';
import { practiceShaperApiRef } from '@agilelab/plugin-wb-practice-shaper';
import { PracticeShaperClient } from '@agilelab/plugin-wb-practice-shaper-common';
import {
  witboostSearchApiRef,
  WitboostSearchClient,
} from '@agilelab/plugin-wb-search';
import { UserConfigClient } from '@agilelab/plugin-wb-user-config-client';
import { userHeadersApiRef } from '@agilelab/plugin-wb-user-headers';
import { UserHeadersClient } from '@agilelab/plugin-wb-user-headers-common';
import { OAuth2 } from '@backstage/core-app-api';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
  oauthRequestApiRef,
} from '@backstage/core-plugin-api';
import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  prototypeApiRef,
  PrototypeClient,
} from '@agilelab/plugin-wb-prototype';
import {
  DefaultEnvironmentApi,
  environmentApiRef,
} from '@agilelab/plugin-wb-environment';
import {
  wittyAutocompleteApiRef,
  WittyAutocompleteClient,
  WittyAssistantClient,
  wittyAssistantApiRef,
} from '@agilelab/plugin-wb-witty-react';
import { GuestUsersAuth, guestUsersAuthApiRef } from '@agilelab/plugin-wb-auth';
import { guestUsersApiRef } from '@agilelab/plugin-wb-auth';
import { GuestUsersAuthClient } from '@agilelab/plugin-wb-guest-users-auth-common';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: customAlertApiRef,
    deps: {},
    factory: () => new CustomAlertApiForwarder(),
  }),
  createApiFactory({
    api: ldapAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, configApi }) =>
      LdapAuth.create({
        discoveryApi,
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  createApiFactory({
    api: guestUsersAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, configApi }) =>
      GuestUsersAuth.create({
        discoveryApi,
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  createApiFactory({
    api: oidcAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      OAuth2.create({
        configApi,
        discoveryApi,
        oauthRequestApi,
        provider: {
          id: 'oidc',
          title: 'Open ID Connect',
          icon: () => null,
        },
        defaultScopes: ['openid', 'profile', 'email'],
      }),
  }),
  createApiFactory({
    api: userConfigApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({ discoveryApi, fetchApi }) =>
      new UserConfigClient({ discoveryApi, fetchApi }),
  }),
  createApiFactory({
    api: auditApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new MeshAuditClient(discoveryApi, identityApi),
  }),
  createApiFactory({
    api: userHeadersApiRef,
    deps: {
      userConfigApi: userConfigApiRef,
      configApi: configApiRef,
    },
    factory: ({ userConfigApi, configApi }) =>
      new UserHeadersClient(userConfigApi, {
        enabled: configApi.getBoolean('mesh.userHeaders.enabled'),
        prefix: configApi.getString('mesh.userHeaders.prefix'),
      }),
  }),
  createApiFactory({
    api: practiceShaperApiRef,
    deps: {
      configApi: configApiRef,
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({ configApi, discoveryApi, fetchApi }) =>
      new PracticeShaperClient({
        config: configApi,
        discoveryApi,
        fetchApi,
      }),
  }),
  createApiFactory({
    api: witboostSearchApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) =>
      new WitboostSearchClient({ discoveryApi, identityApi }),
  }),
  createApiFactory({
    api: eventsApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({ discoveryApi, fetchApi }) =>
      new EventsClient({
        discoveryApi,
        fetchApi,
      }),
  }),
  createApiFactory({
    api: customFrontendApiRef,
    deps: {
      configApi: configApiRef,
      customAlertApi: customAlertApiRef,
    },
    factory: ({ configApi, customAlertApi }) =>
      new CustomFrontendClient(configApi, customAlertApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: CustomPresentationRetrieverApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({ discoveryApi, fetchApi }) =>
      new CustomPresentationRetriever({ discoveryApi, fetchApi }),
  }),
  createApiFactory({
    api: prototypeApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({ discoveryApi, fetchApi }) =>
      new PrototypeClient({ discoveryApi, fetchApi }),
  }),
  createApiFactory({
    api: environmentApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({ discoveryApi, fetchApi }) =>
      new DefaultEnvironmentApi({ discoveryApi, fetchApi }),
  }),
  createApiFactory({
    api: wittyAutocompleteApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({ fetchApi, discoveryApi }) =>
      new WittyAutocompleteClient(fetchApi, discoveryApi),
  }),
  createApiFactory({
    api: wittyAssistantApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({ fetchApi, discoveryApi }) =>
      new WittyAssistantClient(fetchApi, discoveryApi),
  }),
  createApiFactory({
    api: guestUsersApiRef,
    deps: { discoveryApi: discoveryApiRef },
    factory: ({ discoveryApi }) => new GuestUsersAuthClient(discoveryApi),
  }),
];
