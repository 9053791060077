import { CustomSnackbarError, WbDrawer } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { Typography } from '@material-ui/core';
import { RoleForm, RoleFormProps, RoleFormType } from './RoleForm';

interface NewEditRoleDrawerProps extends RoleFormProps {
  defaultValues?: RoleFormType;
  id?: string;
  error?: Error;
  openDrawer: boolean;
}

export const NewEditRoleDrawer: React.FC<NewEditRoleDrawerProps> = ({
  onSubmit,
  dataState,
  openDrawer,
  setOpenDrawer,
  error,
  defaultValues,
}) => {
  return (
    <WbDrawer
      open={openDrawer}
      setOpen={setOpenDrawer}
      contentContainerStyle={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: 0,
      }}
      PaperProps={{
        style: {
          width: '40%',
        },
      }}
      title={defaultValues ? 'Edit role' : 'New Role'}
    >
      {error && (
        <CustomSnackbarError
          message={{
            title: 'Error',
            type: 'error',
            content: <Typography>{error.message}</Typography>,
          }}
        />
      )}
      <RoleForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        dataState={dataState}
        setOpenDrawer={setOpenDrawer}
      />
    </WbDrawer>
  );
};
