import { DataProductPage } from './DataProductPage';
import { OutputPortPage } from './OutputPortPage';
import { DataContractDrawer } from './data-contract/DataContractDrawer';

export * from './components';
export * from './data-contract/DataContractDrawerCustomView';
export * from './data-contract/DataContractDrawerCtrl';
export * from './data-contract/DataContractPopoverOverview';
export * from './VisualDiscovery/SystemPopoverOverview';
export * from './DataProductCustomView';
export * from './DataProductSubpages';
export * from './DataProductView';
export * from './OutportPortSubpages';
export * from './OutputPortCustomView';
export * from './OutputPortView';
export * from './schemaComponents';
export * from './OutputCardZoomInCustomView';

export const CustomViews = {
  DataProductPage,
  OutputPortPage,
  DataContractDrawer,
};
