import { parseUrn } from '@agilelab/plugin-wb-builder-common';
import {
  JsonArray,
  JsonObject,
  JsonPrimitive,
  JsonValue,
} from '@backstage/types';
import {
  MarketplaceComponentsQueryType,
  MarketplaceSystemsQueryType,
} from '../../graphql';
import {
  WitboostIndexableDocument,
  WitboostMarketplaceComputedInfo,
} from '../types';

export interface MarketplaceDocument extends WitboostIndexableDocument {
  _computedInfo: WitboostMarketplaceComputedInfo;
}

export type MarketplaceCollatorEntityTransformer = (
  descriptor: JsonObject,
) => Omit<object, 'location' | 'title' | 'text'>;

export type MarketplaceCollatorSystemComputedInfoBuilder = (
  document: MarketplaceSystemsQueryType,
) => WitboostMarketplaceComputedInfo;

export type MarketplaceCollatorComponentComputedInfoBuilder = (
  document: MarketplaceComponentsQueryType,
) => WitboostMarketplaceComputedInfo;

export const defaultMarketplaceCollatorSystemDescriptorTransformer: MarketplaceCollatorEntityTransformer =
  (descriptor: JsonObject) => {
    return {
      ...descriptor,
    };
  };

export const defaultMarketplaceCollatorComponentDescriptorTransformer: MarketplaceCollatorEntityTransformer =
  (descriptor: JsonObject) => {
    return {
      ...descriptor,
    };
  };

export const defaultMarketplaceSystemComputedInfoBuilder: MarketplaceCollatorSystemComputedInfoBuilder =
  (document: MarketplaceSystemsQueryType) => {
    return {
      urn: document.external_id,
      kind: document.kind,
      taxonomy: {
        name: document.taxonomy.name,
        external_id: document.taxonomy.external_id,
      },
      system_urn: document.external_id,
      publishedAt: document.published_at,
      consumable: document.consumable,
      environment: document.environment.name,
      domain: {
        name: document.domains?.at(0)?.domain.name ?? 'Unknown',
        external_id: document.domains?.at(0)?.domain.external_id ?? 'Unknown',
      },
      owner: {
        ref: document.owner,
        displayName: document.owner_display_name,
      },
    };
  };

export const defaultMarketplaceComponentComputedInfoBuilder: MarketplaceCollatorComponentComputedInfoBuilder =
  (document: MarketplaceComponentsQueryType) => {
    const parsedUrn = parseUrn(document.external_id);
    const isSubcomponent = !!parsedUrn.subcomponent;
    return {
      urn: document.external_id,
      kind: document.kind,
      taxonomy: {
        name: document.parent?.taxonomy?.name ?? 'Unknown',
        external_id: document.parent?.taxonomy?.external_id ?? 'Unknown',
      },
      consumable: document.consumable,
      environment: document.environment.name,
      publishedAt: document.published_at,
      system_urn: document.parent?.external_id ?? 'Unknown',
      domain: {
        name: document.parent?.domain.name ?? 'Unknown',
        external_id: document.parent?.domain.external_id ?? 'Unknown',
      },
      owner: {
        ref: document.parent?.owner ?? 'Unknown',
        displayName: document.parent?.owner_display_name ?? 'Unknown',
      },
      parent: document.parent?.display_name
        ? {
            displayName: document.parent?.display_name,
            parentComponentDisplayName: isSubcomponent
              ? parsedUrn.component
              : undefined,
          }
        : undefined,
    };
  };

function isArray(value: JsonValue): value is JsonArray {
  return Array.isArray(value);
}

function isPrimitive(value: JsonValue): value is JsonPrimitive {
  return Object(value) !== value;
}

function isObject(value: JsonValue): value is JsonObject {
  return typeof value === 'object' && value !== null;
}

export class Metadata {
  private metadata: JsonObject;

  constructor(descriptor: JsonObject) {
    this.metadata = this.flatEntityDescriptor(descriptor);
  }

  get value() {
    return { ...this.metadata };
  }

  add(key: string, value: string) {
    this.metadata = { ...this.metadata, [key]: value };
  }

  toString(): string {
    return Object.keys(this.metadata)
      .filter(key => Boolean(this.metadata[key]))
      .map(key => `${key} = ${this.metadata[key]}`)
      .join(' # ');
  }

  private flatEntityDescriptor = (data: JsonObject): JsonObject => {
    function recurse(cur: JsonValue, prop: string, result: JsonObject) {
      if (isPrimitive(cur)) {
        result[prop] = cur;
      } else if (isArray(cur)) {
        cur.forEach((el, index) => recurse(el, `${prop}[${index}]`, result));
      } else if (isObject(cur)) {
        Object.keys(cur).forEach(key =>
          recurse(cur[key] || '', prop ? `${prop}.${key}` : key, result),
        );
      }
      return result;
    }

    return recurse(data, '', {});
  };
}
