import { z } from 'zod';
import { WbCardActionButton, WbTextField } from '@agilelab/plugin-wb-platform';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Box, FormControl, makeStyles } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { RoleEntity } from '@agilelab/plugin-wb-rbac-common';

const useStyles = makeStyles(
  theme => ({
    form: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    actions: {
      display: 'flex',
      padding: theme.spacing(1),
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      gap: theme.spacing(2),
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
    fields: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      paddingInline: theme.spacing(2),
      paddingTop: theme.spacing(2),
      overflowY: 'auto',
    },
  }),
  { name: 'NewRoleDrawer' },
);

export const RoleFormSchema = z.object({
  displayName: z.string({
    required_error: 'Name is required',
  }),
  description: z.string().optional(),
});

export interface RoleFormType {
  displayName: string;
  description?: string;
}

export interface RoleFormProps {
  defaultValues?: RoleFormType;
  onSubmit: (newValues: RoleFormType) => void;
  dataState: AsyncState<RoleEntity[] | undefined>;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RoleForm: React.FC<RoleFormProps> = ({
  onSubmit,
  dataState,
  setOpenDrawer,
  defaultValues,
}) => {
  const classes = useStyles();
  const { handleSubmit, control, watch } = useForm<RoleFormType>({
    defaultValues,
    resolver: zodResolver(RoleFormSchema),
  });

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.fields}>
        <Controller
          name="displayName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl style={{ width: '100%' }}>
              <WbTextField
                label="Name *"
                value={field.value}
                onChange={field.onChange}
                error={Boolean(error)}
                helperText={
                  error?.message ||
                  'Entering a meaningful and recognizable name'
                }
                style={{ width: '100%' }}
              />
            </FormControl>
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl style={{ width: '100%' }}>
              <WbTextField
                label="Description"
                value={field.value}
                error={Boolean(error)}
                onChange={field.onChange}
                style={{ width: '100%' }}
                helperText={error?.message || 'Provide a detailed description'}
              />
            </FormControl>
          )}
        />
      </Box>

      <Box className={classes.actions}>
        <WbCardActionButton
          onClick={() => {
            setOpenDrawer(false);
          }}
          variant="outlined"
          color="primary"
          label="Cancel"
        />
        <WbCardActionButton
          label={defaultValues ? 'Update role' : 'Add Role'}
          type="submit"
          disabled={dataState.loading || !watch('displayName')}
          variant="contained"
          color="primary"
        />
      </Box>
    </form>
  );
};
