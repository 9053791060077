import {
  GenericField,
  WbCard,
  WbCardActionButton,
  WbCardContent,
} from '@agilelab/plugin-wb-platform';
import { Box, makeStyles, useTheme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback } from 'react';
import { NewEditRoleDrawer } from '../RoleDrawer';
import { RoleFormType } from '../RoleForm';
import { RolePermissionsDrawer } from './ManageRolePermissionsDrawer';
import { useOverviewContext } from './OverviewContext';
import {
  DeleteConfirmDialog,
  RolePermissionCardHeader,
  RolePermissionsTable,
} from './RolePermissionsTable';
import { RolePermissionsTableContextProvider } from './RolePermissionsTableContext';

const useStyles = makeStyles(
  theme => ({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexBox: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    scrollableTable: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      height: '100%',
      overflow: 'hidden',
    },
  }),
  { name: 'OverviewTab' },
);

const GeneralInfoCardActions: React.FC = () => {
  const {
    openDrawer,
    setOpenDrawer,
    error,
    roleState,
    editRole,
    editRoleState,
    canEditRolePermission,
  } = useOverviewContext();

  const onSubmit = useCallback(
    (newValues: RoleFormType) => {
      editRole(newValues);
    },
    [editRole],
  );
  return (
    canEditRolePermission &&
    roleState.value?.visibility !== 'internal' && (
      <>
        <WbCardActionButton
          label="Edit Role"
          startIcon={<EditIcon />}
          onClick={() => setOpenDrawer(true)}
          color="secondary"
          size="small"
          variant="outlined"
        />
        <NewEditRoleDrawer
          defaultValues={{
            displayName: roleState.value?.displayName!,
            description: roleState.value?.description,
          }}
          onSubmit={onSubmit}
          dataState={editRoleState}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          error={error}
        />
      </>
    )
  );
};

const GeneralInfoBox = () => {
  const classes = useStyles();

  const { roleState } = useOverviewContext();
  return (
    <WbCard title="General Information" actions={<GeneralInfoCardActions />}>
      <WbCardContent className={classes.container}>
        <GenericField
          label="Description"
          value={roleState.value?.description}
        />
      </WbCardContent>
    </WbCard>
  );
};

export const OverviewTab: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      style={{ gap: theme.spacing(2) }}
    >
      <GeneralInfoBox />
      <RolePermissionsTableContextProvider>
        <WbCard title="Permissions" cardStyle={{ flex: 1, overflow: 'hidden' }}>
          <WbCardContent className={classes.scrollableTable}>
            <RolePermissionCardHeader />
            <RolePermissionsTable />
          </WbCardContent>
        </WbCard>
        <DeleteConfirmDialog />
        <RolePermissionsDrawer />
      </RolePermissionsTableContextProvider>
    </Box>
  );
};
