import { WittyTableColumn } from '@agilelab/plugin-wb-witty-react';

export function composeNoContextMessage(
  triggeringColumns?: WittyTableColumn[],
): string {
  const columns = triggeringColumns?.map(col => col.columnName).join(', ');
  if (!columns) {
    return 'Witty has not enough context to autocomplete your table.';
  }

  return `Witty has not enough context to autocomplete your table. Fill out these columns first: ${columns}.`;
}
