import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { WbCardActionButton } from '../../WbCardActionButton';
import PublishIcon from '@material-ui/icons/Publish';

const tableStyle: React.CSSProperties = {
  marginLeft: '10px',
  marginRight: '10px',
};

const boldCellStyle: React.CSSProperties = {
  fontWeight: 'bold',
  paddingBottom: '5px',
  paddingRight: '32px',
};

const longTextCellStyle: React.CSSProperties = {
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  maxWidth: '400px',
};

interface Metadata {
  repository?: string;
  file?: string;
  branch?: string;
}

interface MetadataTableProps {
  metadata: Metadata;
}

type FileMetadata = {
  repoUrl: string;
  branch: string;
  /**
   * e.g. dir1/dir2/file-name.ext
   */
  filePath: string;
};

export interface SaveDialogProps {
  classes: any;
  isLoading: boolean;
  fileMetadata: FileMetadata | null | undefined;
  save: (commitMessage: string) => Promise<void>;
  targetBranchQuery?: string;
  defaultCommitMessage?: string;
  disableButton?: boolean;
}

const MetadataTable: React.FC<MetadataTableProps> = ({ metadata }) => {
  const { repository, file, branch } = metadata;

  return (
    <table style={tableStyle}>
      <tbody>
        <tr>
          <td style={boldCellStyle}>Repository</td>
          <td style={longTextCellStyle}>
            <Link
              title="Go to the repository"
              target="_blank"
              href={repository}
            >
              {repository}
            </Link>
          </td>
        </tr>
        <tr>
          <td style={boldCellStyle}>File</td>
          <td style={longTextCellStyle}>{file}</td>
        </tr>
        <tr>
          <td style={boldCellStyle}>Branch</td>
          <td style={longTextCellStyle}>{branch}</td>
        </tr>
      </tbody>
    </table>
  );
};

export const SaveDialog = ({
  classes,
  fileMetadata,
  isLoading,
  save,
  targetBranchQuery,
  defaultCommitMessage,
  disableButton,
}: SaveDialogProps) => {
  const [inputValue, setInputValue] = useState(
    defaultCommitMessage ?? `Save new changes to catalog-info file`,
  );
  const [isInputValid, setIsInputValid] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);

  const validateInput = useCallback((value: any) => {
    const trimmedValue = value.trim();
    setIsInputValid(trimmedValue !== '');
  }, []);

  return (
    <>
      <Tooltip title="Persist the updates in the entity's repository">
        <WbCardActionButton
          disabled={disableButton}
          onClick={() => setDialogOpen(true)}
          className={classes.saveButton}
          label="Save to repository"
          icon={<PublishIcon />}
        />
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          if (!isLoading) setDialogOpen(false);
        }}
        PaperProps={{
          style: {
            minWidth: '400px',
            minHeight: '200px',
          },
        }}
      >
        <DialogTitle>
          Save to repository
          <Typography color="textSecondary" style={{ fontSize: '14px' }}>
            You are going to update the entity's repository
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: '2px 3px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MetadataTable
            metadata={{
              repository: fileMetadata?.repoUrl,
              file: fileMetadata?.filePath,
              branch: targetBranchQuery ?? fileMetadata?.branch ?? 'default',
            }}
          />
          <Box style={{ height: '20px' }} />
          <TextField
            style={{ marginLeft: '10px', marginRight: '10px' }}
            margin="dense"
            id="inputField"
            label="Update message"
            type="text"
            value={inputValue}
            onChange={val => {
              setInputValue(val.target.value);
              validateInput(val.target.value);
            }}
            disabled={isLoading}
            error={!isInputValid}
            helperText={!isInputValid && 'The update message cannot be empty'}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
            color="secondary"
            disabled={isLoading}
          >
            Cancel
          </Button>
          {isLoading ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            <Tooltip
              title={
                isInputValid
                  ? `A new commit \`${inputValue}\` will be created and pushed to the target git repository on ${
                      targetBranchQuery ?? fileMetadata?.branch ?? 'default'
                    } branch`
                  : ''
              }
            >
              <span>
                <Button
                  onClick={async () => {
                    if (isInputValid) {
                      await save(inputValue);
                    }
                  }}
                  color="primary"
                  variant="contained"
                  disabled={!isInputValid}
                >
                  Save
                </Button>
              </span>
            </Tooltip>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
