import { useApi, configApiRef, alertApiRef } from '@backstage/core-plugin-api';
import { useEffect, useMemo } from 'react';
import { useWittyFormContext, useWittyTable } from '../contexts';

export const wittyTextPickerUniqueName = 'WittyTextPicker';

export function useWittyEnabled(pickerSchema: Record<string, any>): {
  enabled: boolean;
  wittyForm: ReturnType<typeof useWittyFormContext>;
} {
  const configApi = useApi(configApiRef);
  const alertApi = useApi(alertApiRef);
  const wittyForm = useWittyFormContext();
  const wittyTable = useWittyTable();

  const schemaEnabled = useMemo(() => {
    if (
      !(
        (typeof pickerSchema?.['ui:field'] === 'string' &&
          pickerSchema['ui:field'] === wittyTextPickerUniqueName) ||
        pickerSchema?.wittySuggestions?.enabled === true
      )
    ) {
      return false;
    }
    return (
      configApi.getOptionalBoolean(
        'witty.scaffolder.smartSuggestions.enabled',
      ) ?? false
    );
  }, [pickerSchema, configApi]);

  const invalidConfig = useMemo(
    () => schemaEnabled && (!wittyForm || !wittyTable?.isProvided),
    [wittyForm, wittyTable?.isProvided, schemaEnabled],
  );

  // Side effect: post an alert when the configuration is invalid.
  useEffect(() => {
    if (invalidConfig) {
      alertApi.post({
        message: `You are using a Witty enabled picker outside of a Witty table. Please adjust field definition for '${pickerSchema.name}' in your template.`,
        severity: 'warning',
      });
    }
  }, [alertApi, invalidConfig, pickerSchema.name]);

  return { enabled: schemaEnabled && !invalidConfig, wittyForm };
}
