import {
  ConfirmDialog,
  TableCellProps,
  WbCardActionButton,
  WbTable,
  WbTableFilters,
} from '@agilelab/plugin-wb-platform';
import { RoleEntity } from '@agilelab/plugin-wb-rbac-common';
import React, { useCallback, useState } from 'react';
import { ErrorPanel, Progress } from '@backstage/core-components';
import { useRolesTableContext } from './RolesTableContext';
import { useNavigate } from 'react-router-dom';
import { useRouteRef } from '@backstage/core-plugin-api';
import { roleDetailRouteRef } from '../../../routes';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { NewEditRoleDrawer } from '../RoleDrawer';
import { RoleFormType } from '../RoleForm';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles(
  theme => ({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexBox: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  }),
  { name: 'RolesTable' },
);

interface RolesTableProps {}

const RolesActions: React.FC = () => {
  const {
    selectedItems,
    openDrawer,
    setOpenDrawer,
    error,
    canDeleteNewRolePermission,
    canAddNewRolePermission,
    deleteRoles,
    setSelectedItems,
    addRole,
    addRoleState,
  } = useRolesTableContext();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const onSubmit = useCallback(
    (newValues: RoleFormType) => {
      addRole(newValues);
    },
    [addRole],
  );
  return (
    <>
      <Box display="flex" style={{ gap: '8px' }} height="fit-content">
        {!!selectedItems?.length
          ? canDeleteNewRolePermission && (
              <>
                <WbCardActionButton
                  label="Delete selected"
                  startIcon={<DeleteIcon />}
                  onClick={() => setOpenConfirmDialog(true)}
                  color="secondary"
                  size="small"
                  variant="outlined"
                />
              </>
            )
          : canAddNewRolePermission && (
              <WbCardActionButton
                label="Add role"
                startIcon={<AddCircleIcon />}
                onClick={() => setOpenDrawer(true)}
                color="primary"
                size="small"
              />
            )}
      </Box>
      <NewEditRoleDrawer
        onSubmit={onSubmit}
        dataState={addRoleState}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        error={error}
      />

      <ConfirmDialog
        title="Delete roles"
        description={`Are you sure you want to delete ${selectedItems.length} roles `}
        onConfirm={() => {
          deleteRoles(selectedItems.map(i => i.id));
          setSelectedItems([]);
          setOpenConfirmDialog(false);
        }}
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        confirmButtonText="Delete"
      />
    </>
  );
};

export const RolesCardHeader: React.FC = () => {
  const classes = useStyles();
  const {
    selectedItems,
    setSelectedItems,
    filters,
    setFilters,
    setPagination,
  } = useRolesTableContext();

  return (
    <Box className={classes.header}>
      <Box className={classes.flexBox}>
        <WbTableFilters
          style={{ marginBottom: '0' }}
          searchValue={filters.text}
          onSearch={search => {
            setFilters({ text: search });
            setPagination(p => ({ ...p, offset: 0 }));
          }}
          onClear={() => setFilters({})}
        />
        {selectedItems && selectedItems?.length > 0 && (
          <Box className={classes.flexBox}>
            <Typography variant="body2" style={{ fontWeight: 'medium' }}>
              {selectedItems.length} Roles selected
            </Typography>
            <WbCardActionButton
              variant="text"
              label="Clear selection"
              onClick={() => setSelectedItems([])}
            />
          </Box>
        )}
      </Box>
      <RolesActions />
    </Box>
  );
};

export const RolesTable: React.FC<RolesTableProps> = () => {
  const {
    selectedItems,
    setSelectedItems,
    rolesLoaded,
    pagination,
    setPagination,
    canDeleteNewRolePermission,
  } = useRolesTableContext();

  const navigate = useNavigate();
  const roleDetailPageRef = useRouteRef(roleDetailRouteRef);

  const columns: TableCellProps<RoleEntity>[] = [
    {
      field: 'id',
      cellProps: {
        size: 'small',
        align: 'left',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'ID',
    },
    {
      field: 'displayName',
      cellProps: {
        size: 'small',
        align: 'left',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'Name',
    },
    {
      field: 'description',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'Description',
    },
  ];

  const handleCheck = (row: RoleEntity) => {
    return row.visibility === 'internal';
  };

  if (rolesLoaded.error)
    return (
      <ErrorPanel
        error={
          rolesLoaded.error instanceof Error
            ? rolesLoaded.error
            : new Error('Something went wrong')
        }
      />
    );
  const onClickHandle = (
    row: RoleEntity,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    event.preventDefault();

    event.stopPropagation();
    navigate({ pathname: roleDetailPageRef({ roleId: row.id }) });
  };

  const getRowId = (r: RoleEntity) => r.id;

  return (
    <WbTable<RoleEntity>
      selection={canDeleteNewRolePermission ? 'multiple' : undefined}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      checkIfDisabled={handleCheck}
      tooltipIfDisabled="This is a platform permission. You cannot delete"
      styles={{
        container: { height: '100%', flex: '1 1 0' },
      }}
      components={{
        tableLoader: {
          loading: rolesLoaded.loading,
          loader: <Progress />,
        },
        tableContent: {
          columns,
          getRowId,
          rows: rolesLoaded.value?.roles,
          onRowClick: onClickHandle,
        },
      }}
      pagination={{
        rowsPerPageOptions: [15, 25, 50],
        count: rolesLoaded.value?.total || 0,
        limit: pagination.limit,
        offset: pagination.offset,
        onPageChange: (page: number) => {
          setPagination({
            ...pagination,
            offset: page * pagination.limit,
          });
        },
        onRowsPerPageChange: (rowsPerPage: number) => {
          setPagination({
            offset: 0,
            limit: rowsPerPage,
          });
        },
      }}
    />
  );
};
