import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { EntityKindSelector } from './EntityKindSelector';
import { SelectableEntityTable } from './SelectableEntityTable';
import {
  GenericEntityType,
  Provider,
  TemplateEntity,
  TextFieldValue,
} from '../types';
import { WbCardContent } from '../../WbCard';
import { WbNoData } from '../../WbNoData';
import { ProviderFilters } from './CommonFilter';
import { selectTemplateEntity } from './utils';
import { useGeneralPurposePickerContext } from '../GeneralPurposePickerContext';

interface SingleSelectionProps {
  availableKinds: TemplateEntity[];
  providerMapper: Map<string, Provider<GenericEntityType[], ProviderFilters>>;
  parentTemplateRef?: string;
  selectedEntityProvider?:
    | Provider<GenericEntityType[], ProviderFilters>
    | undefined;
}

export const SingleSelectionTable = ({
  selectedKind,
  providerMapper,
  entities,
  setEntities,
}: {
  selectedKind: string;
  providerMapper: Map<string, Provider<GenericEntityType[], ProviderFilters>>;
  entities: TextFieldValue[];
  setEntities: (values: TextFieldValue[]) => void;
}) => {
  const provider = providerMapper.get(selectedKind);
  const context = provider?.useContext();
  return (
    <SelectableEntityTable
      loading={context?.entitiesState.loading ?? false}
      data={(context?.entitiesState.value as GenericEntityType[]) ?? []}
      entities={entities}
      onChangeHandler={setEntities}
      filtersSection={provider?.filtersSection!}
      pagination={context?.pagination!}
      setPagination={context?.setPagination!}
      count={context?.count ?? 0}
      nextCursor={context?.nextCursor}
      prevCursor={context?.prevCursor}
      columns={context?.columns ?? []}
      templateEntity={context?.templateKind!}
    />
  );
};

export function SingleSelection(props: SingleSelectionProps) {
  const {
    availableKinds,
    selectedEntityProvider,
    providerMapper,
    parentTemplateRef,
  } = props;
  const { selectedKind, selectedValues, setSelectedValues, setOpenDialog } =
    useGeneralPurposePickerContext();

  const renderedComponent = selectedEntityProvider?.renderContextProvider(
    selectTemplateEntity(availableKinds, selectedKind),
    <SingleSelectionTable
      selectedKind={selectedKind}
      providerMapper={providerMapper}
      entities={selectedValues?.tableValues ?? []}
      setEntities={e => {
        setSelectedValues({ tableValues: e });
        setOpenDialog(false);
      }}
    />,
    parentTemplateRef,
  );

  return (
    <WbCardContent
      style={{
        height: '64vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box display="flex" justifyContent="space-between" paddingY="8px">
        <Typography variant="body1">
          Select an entity from the list below
        </Typography>
        {availableKinds.length > 1 && (
          <EntityKindSelector availableKinds={availableKinds} />
        )}
      </Box>

      {renderedComponent ?? <WbNoData />}
    </WbCardContent>
  );
}
