import React, { useEffect, useMemo } from 'react';
import { FormControl, List, ListItem, FormHelperText } from '@material-ui/core';
import {
  WbMarkdownHelperText,
  WbTextField,
} from '@agilelab/plugin-wb-platform';
import WrapIfAdditional from '../ArrayFieldTemplate/WrapIfAdditional';
import { extractCustomProperties, isHidden } from '../../utils';
import {
  useWittyAutocomplete,
  useWittyTable,
  useWittyTableRow,
  WittyInputAdornment,
  WittyNoContextTooltip,
} from '@agilelab/plugin-wb-witty-react';
import { FieldProps, UiSchema } from '@rjsf/core';

export const WittyTextPicker = (props: FieldProps<string>) => {
  const {
    id,
    classNames,
    disabled,
    displayLabel,
    hidden,
    label,
    onDropPropertyClick,
    onKeyChange,
    readonly,
    required,
    rawErrors = [],
    rawHelp,
    rawDescription,
    schema,
    uiSchema,
    formData,
    onChange,
    idSchema,
  } = props;

  const customProps = extractCustomProperties(uiSchema as UiSchema);

  const witty = useWittyAutocomplete({
    fieldId: idSchema.$id,
    schema: { ...schema, ...uiSchema },
    onChange,
  });
  const fieldId = useMemo(() => `${idSchema.$id}`, [idSchema.$id]);

  const wittyTable = useWittyTable();
  const wittyTableRow = useWittyTableRow();
  const canAutocomplete = wittyTableRow?.canAutocomplete;
  const updateWittyFields = wittyTable?.updateWittyFields;
  const triggeringColumns = wittyTable?.triggeringColumns ?? [];

  useEffect(() => {
    if (updateWittyFields && witty.enabled && canAutocomplete) {
      updateWittyFields({
        type: 'ADD_FIELD',
        payload: { fieldId: fieldId, type: 'text', notify: onChange },
      });
    }

    return () => {
      updateWittyFields?.({ type: 'REMOVE_FIELD', payload: fieldId });
    };
  }, [canAutocomplete, fieldId, onChange, updateWittyFields, witty.enabled]);

  if (hidden) {
    return <></>;
  }

  return (
    <WrapIfAdditional
      classNames={classNames}
      disabled={disabled}
      id={idSchema.$id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
    >
      <FormControl fullWidth error={rawErrors.length > 0} required={required}>
        <WbTextField
          id={id}
          style={{
            display: isHidden(uiSchema as UiSchema) ? 'none' : undefined,
          }}
          label={label}
          placeholder={uiSchema ? uiSchema['ui:placeholder'] : undefined}
          helperText={rawDescription}
          disabled={disabled || witty.loading}
          required={required}
          value={formData ?? ''}
          onChange={({ target: { value } }) => onChange(value)}
          error={rawErrors?.length > 0 && !formData}
          {...customProps}
          InputProps={{
            ...customProps.InputProps,
            startAdornment: witty.enabled && (
              <WittyNoContextTooltip
                triggeringColumns={triggeringColumns}
                hide={canAutocomplete}
              >
                <span>
                  <WittyInputAdornment
                    disabled={!canAutocomplete}
                    loading={witty.loading}
                    onClick={witty.suggestText}
                  />
                </span>
              </WittyNoContextTooltip>
            ),
          }}
        />
        {displayLabel && rawDescription && (
          <WbMarkdownHelperText helperText={rawDescription} />
        )}
        {rawErrors.length > 0 && (
          <List dense disablePadding>
            {rawErrors.map((error: any, i: number) => {
              return (
                <ListItem key={`${idSchema.$id}_${i}`} disableGutters>
                  <FormHelperText id={id}>{error}</FormHelperText>
                </ListItem>
              );
            })}
          </List>
        )}
        {rawHelp && <FormHelperText id={id}>{rawHelp}</FormHelperText>}
      </FormControl>
    </WrapIfAdditional>
  );
};
