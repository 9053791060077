import {
  ComponentRegister,
  ComponentRegisters,
} from '@agilelab/plugin-wb-platform-common';

export enum CustomViewPageCategory {
  MARKETPLACE = 'MARKETPLACE',
  BUILDER = 'BUILDER',
  PRACTICE_SHAPER = 'PRACTICE_SHAPER',
}

type CustomViewPreviewItem = {
  src: string;
};

export type CustomViewPageMeta = {
  label: string;
  category: CustomViewPageCategory;
  preview: {
    thumbnail: {
      src: string;
    };
    // typed like this to enforce the array containing atleat one item
    items: [CustomViewPreviewItem, ...CustomViewPreviewItem[]];
  };
};

type PageEntry = {
  id: string;
  meta: CustomViewPageMeta;
  function?: Function;
};

class CustomViewPagesRegister {
  private registry: ComponentRegister;
  constructor() {
    this.registry = ComponentRegisters.getComponentRegister('customPages');
  }
  exists(pageId: string): boolean {
    return this.registry.exists(pageId);
  }
  get(pageId: string): Function | undefined {
    return this.registry.get(pageId);
  }
  getMetadata(pageId: string): CustomViewPageMeta | undefined {
    return this.registry.getMetadata(pageId) as CustomViewPageMeta;
  }
  register(entry: PageEntry): void {
    this.registry.register({
      id: entry.id,
      metadata: entry.meta,
      function: entry.function,
    });
  }
  getAll() {
    return this.registry.listIds();
  }
}

export const CustomViewPages = new CustomViewPagesRegister();
