import { Dialog, IconButton } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { WbCard } from '../WbCard';
import { MultiSelectionContent } from './MultiSelectionContent';
import { MultiSelectionPickerContextProvider } from './MultiSelectionPickerContext';

export interface WbMultiSelectionPickerDialogProps<T> {
  title?: string;
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedValues: T[];
  setSelectedValues: React.Dispatch<React.SetStateAction<T[]>>;
  children: React.ReactNode;
  fetchAiData?: () => Promise<T[]>;
}

export function WbMultiSelectionPickerDialog<T>(
  props: WbMultiSelectionPickerDialogProps<T>,
) {
  const {
    openDialog,
    setOpenDialog,
    selectedValues,
    setSelectedValues,
    children,
    title,
    fetchAiData,
  } = props;

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
      }}
      maxWidth="xl"
      fullWidth
    >
      <WbCard
        title={`${title} Picker`}
        headerContentStyle={{ height: 'auto', padding: '8px 16px' }}
        cardStyle={{
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'column',
        }}
        actions={
          <IconButton
            aria-label="clear"
            onClick={() => setOpenDialog(false)}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        }
      >
        <MultiSelectionPickerContextProvider<T>
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          fetchAIData={fetchAiData}
        >
          <MultiSelectionContent
            aiEnabled={!!fetchAiData}
            title={title}
            setOpen={setOpenDialog}
            contentHeight="65vh"
          >
            {/* left content */}
            {children}
          </MultiSelectionContent>
        </MultiSelectionPickerContextProvider>
      </WbCard>
    </Dialog>
  );
}
