import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import {
  Action,
  OptionsSetsCache,
  WittyFieldSubscription,
  WittyTable,
  WittyTableContextProviderProps,
} from './types';
import { syncOptionsSetsCacheWithSubscribers } from './utils';
import { WittyOption } from '@agilelab/plugin-wb-witty-common';

const defaultWittyTableContext: WittyTable = {
  tablePath: '',
  additionalContext: [],
  updateWittyFields: () => {},
  wittyFields: [],
  triggeringColumns: [],
  maxRowsPerChunk: 0,
  rowsNumber: 0,
  getOptionsSet: () => {
    throw new Error('Not in a witty context');
  },
  getOptionsSets: () => {
    throw new Error('Not in a witty context');
  },
  isProvided: false, // Explicitly mark default as not provided
};

export const WittyTableContext = createContext<WittyTable>(
  defaultWittyTableContext,
);

export const useWittyTable = (): WittyTable | undefined => {
  const context = useContext(WittyTableContext);

  if (!context.isProvided) {
    return undefined;
  }

  return context;
};

export const WittyTableContextProvider = ({
  children,
  ...props
}: {
  children: ReactNode;
} & WittyTableContextProviderProps) => {
  const [subscribers, subscribe] = useReducer(
    (state: WittyFieldSubscription[], action: Action) => {
      switch (action.type) {
        case 'ADD_FIELD': {
          const alreadySubscribed = state.some(
            sub => sub.fieldId === action.payload.fieldId,
          );
          return alreadySubscribed ? state : [...state, action.payload];
        }
        case 'REMOVE_FIELD':
          return state.filter(sub => sub.fieldId !== action.payload);
        case 'RESET_FIELDS':
          return [];

        default:
          return state;
      }
    },
    [],
  );

  const optionsSetsCache = useRef<OptionsSetsCache>(new Map());

  useEffect(() => {
    syncOptionsSetsCacheWithSubscribers(optionsSetsCache.current, subscribers);
  }, [subscribers]);

  /**
   * Get an optionSet given an optionSet identifier, while handling chaching as well
   */
  const getOptionsSet = useCallback(async (optionsSetId: string) => {
    const curr = optionsSetsCache.current;
    const val = curr.get(optionsSetId);
    if (!val) throw new Error('Something went wrong');
    // checks if there are options cached for this id
    if (val.options) return val.options;
    // otherwise fetch them and cache them
    const fetchedOptions = await val.fetchFn();
    val.options = fetchedOptions;
    return fetchedOptions;
  }, []);

  /**
   * Get an optionSets object filled with the given identifiers, while handling caching as well
   */
  const getOptionsSets = useCallback(
    async (optionsSetIds: string[]): Promise<Record<string, WittyOption[]>> => {
      const sets = await Promise.all(optionsSetIds.map(o => getOptionsSet(o)));
      return optionsSetIds.reduce<Record<string, WittyOption[]>>(
        (acc, curr, i) => {
          acc[curr] = sets[i];
          return acc;
        },
        {},
      );
    },
    [getOptionsSet],
  );

  const value = useMemo(
    () => ({
      ...props,
      isProvided: true,
      updateWittyFields: subscribe,
      wittyFields: subscribers,
      getOptionsSet,
      getOptionsSets,
    }),
    [props, subscribers, subscribe, getOptionsSet, getOptionsSets],
  );

  return (
    <WittyTableContext.Provider value={value}>
      {children}
    </WittyTableContext.Provider>
  );
};
