import {
  CustomViewComponent,
  CustomViewPageCategory,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import preview from './preview/builder_system/builder_system.png';
import thumbnail from './preview/builder_system/builder_system_thumbnail.png';

CustomViewPages.register({
  id: 'builder_system',
  meta: {
    label: 'System Detail Page',
    category: CustomViewPageCategory.BUILDER,
    preview: {
      thumbnail: { src: thumbnail },
      items: [{ src: preview }],
    },
  },
  function: function Entity() {
    return (
      <>
        <CustomViewComponent type="catalog_warnings" />
        <CustomViewComponent type="grid_list">
          <CustomViewComponent type="hbox">
            <CustomViewComponent type="include" id="builder_system_general" />
            <CustomViewComponent type="catalog_relations" />
          </CustomViewComponent>
          <CustomViewComponent type="catalog_component_card" title="Components">
            <CustomViewComponent
              type="no_data"
              label="No Components"
              path="components.length"
            >
              <CustomViewComponent type="grid_sequence" path="components">
                <CustomViewComponent type="sub_card" title="{{label}}">
                  <CustomViewComponent
                    type="table"
                    path="components"
                    click="showComponent"
                  >
                    <CustomViewComponent
                      type="string"
                      label="Name"
                      value="{{ spec.mesh.name if spec.mesh.name else metadata.name }}"
                      width="30%"
                    />
                    <CustomViewComponent
                      type="link"
                      label="Owner"
                      path="owner"
                      width="30%"
                    />
                    <CustomViewComponent
                      type="string"
                      label="Description"
                      path="metadata.description"
                      width="40%"
                    />
                  </CustomViewComponent>
                </CustomViewComponent>
              </CustomViewComponent>
            </CustomViewComponent>
          </CustomViewComponent>
        </CustomViewComponent>
      </>
    );
  },
});
