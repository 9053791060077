import React, { useState } from 'react';
import {
  Box,
  Button,
  DialogActions,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { WbCardContent } from '../../../WbCard';
import { WbCardActionButton } from '../../../WbCardActionButton';
import {
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
} from '../../../WbDockable';
import { useGeneralPurposePickerContext } from '../../GeneralPurposePickerContext';
import {
  GenericEntityType,
  Provider,
  TemplateEntity,
  TextFieldValue,
} from '../../types';
import { SelectedEntities } from './SelectedEntities';
import { ProviderFilters } from '../CommonFilter';
import { EntityKindSelector } from '../EntityKindSelector';
import { WbNoData } from '../../../WbNoData';
import { SelectableEntityTable } from '../SelectableEntityTable';
import { selectTemplateEntity } from '../utils';

const useStyles = makeStyles(
  () => ({
    cardContent: {
      overflow: 'auto',
      padding: '0px',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '8px 0px',
      alignItems: 'center',
    },
  }),
  { name: 'EntityListMultiPicker' },
);

interface MultiSelectionProps {
  providerMapper: Map<string, Provider<GenericEntityType[], ProviderFilters>>;
  selectedEntityProvider?:
    | Provider<GenericEntityType[], ProviderFilters>
    | undefined;
  availableKinds: TemplateEntity[];
  parentTemplateRef?: string;
}

export const MultipleSelectionTable = ({
  selectedKind,
  providerMapper,
  entities,
  setEntities,
}: {
  selectedKind: string;
  providerMapper: Map<string, Provider<GenericEntityType[], ProviderFilters>>;
  entities: TextFieldValue[];
  setEntities: React.Dispatch<React.SetStateAction<TextFieldValue[]>>;
}) => {
  const provider = providerMapper.get(selectedKind);
  const context = provider?.useContext();

  return (
    <SelectableEntityTable
      multiple
      columns={context?.columns ?? []}
      loading={context?.entitiesState.loading ?? false}
      data={(context?.entitiesState.value as GenericEntityType[]) ?? []}
      entities={entities}
      onChangeHandler={setEntities}
      filtersSection={provider?.filtersSection!}
      pagination={context?.pagination!}
      setPagination={context?.setPagination!}
      count={context?.count ?? 0}
      nextCursor={context?.nextCursor}
      prevCursor={context?.prevCursor}
      templateEntity={context?.templateKind!}
    />
  );
};

export function MultiSelection(props: MultiSelectionProps) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    availableKinds,
    selectedEntityProvider,
    parentTemplateRef,
    providerMapper,
  } = props;

  const { setSelectedValues, setOpenDialog, selectedKind, selectedValues } =
    useGeneralPurposePickerContext();

  const [entities, setEntities] = useState<TextFieldValue[]>(
    (selectedValues?.tableValues as TextFieldValue[]) ?? [],
  );

  const renderedComponent = selectedEntityProvider?.renderContextProvider(
    selectTemplateEntity(availableKinds, selectedKind),
    <MultipleSelectionTable
      selectedKind={selectedKind}
      providerMapper={providerMapper}
      entities={entities}
      setEntities={setEntities}
    />,
    parentTemplateRef,
  );

  return (
    <WbCardContent
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '64vh',
        padding: 0,
      }}
    >
      <WbDockableGroup direction="horizontal">
        <WbDockablePanel defaultSize={70}>
          <WbCardContent className={classes.cardContent}>
            <Box className={classes.header}>
              <Typography variant="body1"> Search for entities</Typography>
              {availableKinds.length > 1 && (
                <EntityKindSelector availableKinds={availableKinds} />
              )}
            </Box>
            {renderedComponent ?? <WbNoData />}
          </WbCardContent>
        </WbDockablePanel>

        <WbDockableResizeHandle
          showIconOnHover
          divider
          size={4}
          direction="horizontal"
        />

        <WbDockablePanel maxSize={30}>
          <SelectedEntities
            entities={entities ?? []}
            setEntities={setEntities}
          />
        </WbDockablePanel>
      </WbDockableGroup>
      <DialogActions
        style={{
          height: '56px',
          position: 'sticky',
          bottom: 0,
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          background: theme.palette.white,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          Cancel
        </Button>
        <WbCardActionButton
          onClick={() => {
            setSelectedValues(preValue => {
              return {
                tableValues: entities,
                arbitraryValues: preValue?.arbitraryValues ?? [],
              };
            });
            setOpenDialog(false);
          }}
          variant="contained"
          size="small"
          color="primary"
          label="Apply"
        />
      </DialogActions>
    </WbCardContent>
  );
}
