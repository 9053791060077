import React from 'react';

// default system
export const SystemIcon = () => (
  <svg viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.0003 11C32.8395 11 30.8401 12.1252 29.7445 13.9578L2.84194 58.9578C1.71936 60.8355 1.71935 63.1645 2.84193 65.0422L29.7445 110.042C30.8401 111.875 32.8395 113 35.0003 113H88.9997C91.1605 113 93.1599 111.875 94.2555 110.042L121.158 65.0422C122.281 63.1645 122.281 60.8355 121.158 58.9578L94.2555 13.9578C93.1599 12.1252 91.1605 11 88.9997 11H35.0003Z"
      fill="currentColor"
    />
  </svg>
);

export const ComponentIcon = () => (
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 4L44 24L24 44L4 24L24 4ZM7.9245 24L24 40.0755L40.0755 24L24 7.9245L7.9245 24Z"
      fill="#4E6980"
    />
  </svg>
);
