/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  createExternalRouteRef,
  createRouteRef,
  createSubRouteRef,
} from '@backstage/core-plugin-api';

export const registerComponentRouteRef = createExternalRouteRef({
  id: 'register-component',
  optional: true,
});

export const createComponentRouteRef = createExternalRouteRef({
  id: 'create-component',
  optional: true,
});

export const viewTechDocRouteRef = createExternalRouteRef({
  id: 'view-techdoc',
  optional: true,
  params: ['namespace', 'kind', 'name'],
});

export const blueprintsRootRouteRef = createExternalRouteRef({
  id: 'blueprints',
});

export const templatesRootRouteRef = createExternalRouteRef({
  id: 'templates',
});

export const rootRouteRef = createRouteRef({
  id: 'catalog',
});

export const myProjectsRouteRef = createRouteRef({
  id: 'my-projects',
});

export const releaseDetailRouteRef = createSubRouteRef({
  id: 'catalog-release',
  parent: rootRouteRef,
  path: '/release/:kind/:namespace/:name',
});

export const projectImportRouteRef = createSubRouteRef({
  id: 'my-project-import',
  parent: myProjectsRouteRef,
  path: '/import',
});
