import {
  Card,
  CardContent,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MeshCardHeader } from '@agilelab/plugin-wb-platform';
import { OkFilledIcon, ProgressRunningIcon } from '../ControlPanel/Icons';
import { useDataContractEditorContext } from './context/useDataContractEditorContext';

export const approvalsList = [
  { id: 'lorenzo.pirazzini_agilelab.it', name: 'Lorenzo Pirazzini' },
  { id: 'roberto.coluccio_agilelab.it', name: 'Roberto Coluccio' },
  { id: 'antonio.murgia_agilelab.it', name: 'Antonio Murgia' },
  { id: 'federico.morreale_agilelab.it', name: 'Federico Morreale' },
];

export const ApprovalsCard = () => {
  const theme = useTheme();
  const cardStyle = {
    marginBottom: '20px',
  };

  const textStyle = {
    fontWeight: 'bold',
    paddingLeft: '30px',
    position: 'relative',
  } as React.CSSProperties;

  const circleStyle = {
    width: '30px',
    height: '30px',
    marginRight: '10px',
  } as React.CSSProperties;

  const rowContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    marginTop: '10px',
  } as React.CSSProperties;

  const { hasApproved } = useDataContractEditorContext();
  const approvals: string[] = approvalsList.map(a => a.name);

  const [iconIndicesToChange, setIconIndicesToChange] = useState<number[]>(
    approvals.map((_, index) => index),
  );
  const [hasStarted, setHasStarted] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(
    undefined,
  );

  useEffect(() => {
    if (intervalId && iconIndicesToChange.length !== 0 && hasStarted) {
      return;
    }
    if (iconIndicesToChange.length === 0 && intervalId) {
      clearInterval(intervalId);
      setHasStarted(false);
      return;
    }
    if (hasStarted && !intervalId) {
      const remainingIndices = [...iconIndicesToChange];

      const id = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * remainingIndices.length);
        const removedIndex = remainingIndices.splice(randomIndex, 1)[0];

        setIconIndicesToChange(prevIndices => {
          return prevIndices.filter(index => index !== removedIndex);
        });
      }, 2000);
      setIntervalId(id);
    }
  }, [
    hasStarted,
    intervalId,
    iconIndicesToChange,
    approvals,
    setIconIndicesToChange,
    setIntervalId,
  ]);

  useEffect(() => {
    if (approvals && hasApproved) {
      setHasStarted(true);
    }
  }, [approvals, hasApproved, setHasStarted]);

  return hasApproved && approvals ? (
    <Card style={cardStyle}>
      <MeshCardHeader title="Approvals" />
      <CardContent>
        <Paper elevation={0}>
          {approvals.map((approval, index) => (
            <div key={approval} style={rowContainerStyle}>
              {!iconIndicesToChange.includes(index) ? (
                <Tooltip title={`${approval} approved`}>
                  <OkFilledIcon
                    htmlColor={theme.palette.success.main}
                    style={circleStyle}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={`Waiting for ${approval} approval`}>
                  <ProgressRunningIcon
                    htmlColor={theme.palette.accent.main}
                    style={circleStyle}
                  />
                </Tooltip>
              )}
              <Typography style={textStyle}>{approval}</Typography>
            </div>
          ))}
        </Paper>
      </CardContent>
    </Card>
  ) : (
    <></>
  );
};
