import React from 'react';
import { createSvgIcon } from '@material-ui/core/utils';

export const WittyIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 19">
    <path
      d="M3.328 1.51989C3.21302 1.27202 2.80939 1.27202 2.6944 1.51989C2.56385 1.8013 2.40645 2.08692 2.24906 2.24333C2.09167 2.39974 1.80423 2.55616 1.52105 2.6859C1.27162 2.80016 1.27163 3.20127 1.52106 3.31554C1.80424 3.44526 2.09167 3.60167 2.24906 3.75808C2.40645 3.91449 2.56385 4.20012 2.6944 4.48152C2.80939 4.7294 3.21302 4.7294 3.328 4.48152C3.45854 4.20012 3.61595 3.91449 3.77335 3.75808C3.93283 3.59959 4.2134 3.4411 4.48575 3.31038C4.72796 3.19412 4.72796 2.80733 4.48575 2.69106C4.21341 2.56032 3.93283 2.40183 3.77335 2.24333C3.61595 2.08692 3.45854 1.8013 3.328 1.51989Z"
      fill="url(#paint0_linear_280_10869)"
    />
    <path
      d="M7.46905 4.82232C7.39004 4.61673 7.07646 4.61673 6.99746 4.82232C6.66338 5.69186 6.02109 7.20403 5.37881 7.83794C4.73652 8.47188 3.20438 9.10582 2.32338 9.43565C2.11509 9.51362 2.11509 9.82313 2.32339 9.90111C3.20439 10.2308 4.73652 10.8647 5.37881 11.4986C6.02109 12.1326 6.66338 13.6448 6.99746 14.5143C7.07646 14.7199 7.39004 14.7199 7.46905 14.5143C7.80313 13.6448 8.44541 12.1326 9.0877 11.4986C9.73166 10.8631 11.1866 10.2276 12.0156 9.89855C12.218 9.81819 12.218 9.51847 12.0156 9.43812C11.1867 9.10912 9.73166 8.47353 9.0877 7.83794C8.44541 7.20403 7.80313 5.69186 7.46905 4.82232Z"
      fill="url(#paint1_linear_280_10869)"
    />
    <path
      d="M12.4645 1.50272C12.37 1.27774 12.015 1.27774 11.9206 1.50272C11.7245 1.97019 11.4463 2.54465 11.1682 2.82001C10.89 3.09537 10.3098 3.37074 9.83758 3.56489C9.61039 3.65835 9.61039 4.00984 9.83758 4.10327C10.3098 4.29741 10.89 4.57277 11.1682 4.84813C11.4463 5.12349 11.7245 5.69795 11.9206 6.16541C12.015 6.3904 12.37 6.3904 12.4645 6.16541C12.6605 5.69795 12.9387 5.12349 13.2168 4.84813C13.4966 4.57106 14.0538 4.29401 14.5018 4.09969C14.7225 4.00393 14.7225 3.66426 14.5018 3.56848C14.0538 3.37414 13.4966 3.09707 13.2168 2.82001C12.9387 2.54465 12.6605 1.97019 12.4645 1.50272Z"
      fill="url(#paint2_linear_280_10869)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_280_10869"
        x1="8.00065"
        y1="1.33398"
        x2="8.00065"
        y2="14.6685"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE72D3" />
        <stop offset="1" stopColor="#7C4DFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_280_10869"
        x1="8.00065"
        y1="1.33398"
        x2="8.00065"
        y2="14.6685"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE72D3" />
        <stop offset="1" stopColor="#7C4DFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_280_10869"
        x1="8.00065"
        y1="1.33398"
        x2="8.00065"
        y2="14.6685"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE72D3" />
        <stop offset="1" stopColor="#7C4DFF" />
      </linearGradient>
    </defs>
  </svg>,
  'LlmHelperIcon',
);
