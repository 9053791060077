import { get as lodashGet, set as lodashSet, cloneDeep } from 'lodash';

export class JsonFormData {
  constructor(public readonly formData: Record<string, any>) {}

  clone(): JsonFormData {
    return new JsonFormData(cloneDeep(this.formData));
  }

  /**
   * key is a react json schema form path
   * e.g. root_schema_0_description means { root: { schema: [{ description: "" }]}}
   * @param key
   */
  get(key: string): any {
    const path = key.split('_');
    return lodashGet(this.formData, path);
  }

  /**
   * if a key does not exist, value will be written at the given path anyway
   * @param key
   * @param value
   * @param map - a mapping function
   */
  set(
    key: string,
    value: any,
    map: (value: any) => any = (val: any) => val,
  ): void {
    const path = key.split('_');
    lodashSet(this.formData, path, map(value));
  }

  /**
   * Returns a new updated formData object.
   * Does not modify the current instance.
   *
   * @param key
   * @param value
   * @param map - a mapping function
   */
  setImmutable(
    key: string,
    value: any,
    map: (value: any) => any = (val: any) => val,
  ): Record<string, any> {
    const newFormData = cloneDeep(this.formData);
    const path = key.split('_');
    lodashSet(newFormData, path, map(value));
    return newFormData;
  }
}
