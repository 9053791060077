import React, { ReactNode, useState } from 'react';
import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuItemProps,
  MenuProps as MenuPropsType,
  SvgIconProps,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export type WbMoreOptionsItem = {
  onClick: MenuItemProps['onClick'];
  icon: ReactNode;
  label: ReactNode;
  dividerBefore?: boolean;
  dividerAfter?: boolean;
  id: string;
};

export type WbMoreOptionsProps = {
  className?: string;
  style?: React.CSSProperties;
  open: boolean;
  onClick: IconButtonProps['onClick'];
  onClose: () => void;
  children: ReactNode;
  IconProps?: SvgIconProps;
  MenuProps?: Omit<MenuPropsType, 'open' | 'onClose'>;
};

export const WbMoreOptions: React.FC<WbMoreOptionsProps> = ({
  className,
  style,
  open,
  onClick,
  onClose,
  children,
  IconProps,
  MenuProps,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton
        className={className}
        style={style}
        size="small"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        ref={setAnchorEl}
        onClick={onClick}
      >
        <MoreVertIcon {...IconProps} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        onClose={onClose}
        {...MenuProps}
      >
        {children}
      </Menu>
    </>
  );
};
