import { Box, Switch, Typography, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { BooleanTableFilter } from './types';
import clsx from 'clsx';

const useStyles = makeStyles<Theme, { value: boolean }>(
  theme => ({
    root: {
      height: '36px',
      borderRadius: '4px',
      padding: '6px 8px 6px 12px',
      border: `1px solid ${theme.palette.grey[300]}`,
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      cursor: 'pointer',
      background: props =>
        props.value
          ? theme.palette.grey[100]
          : theme.palette.background.default,
    },
  }),
  { name: 'BooleanFilter' },
);

export const BooleanFilter = ({
  value,
  style,
  className,
  startIcon,
  field,
  color,
  onChange,
}: BooleanTableFilter) => {
  const [innerValue, setInnerValue] = useState<boolean>(value ?? false);
  const classes = useStyles({ value: innerValue });

  React.useEffect(() => {
    setInnerValue(value ?? false);
  }, [value]);

  return (
    <Box
      style={style}
      className={clsx(classes.root, className)}
      onClick={() => {
        onChange(!innerValue);
      }}
    >
      {startIcon && startIcon}
      <Typography style={{ color }} component="span">
        {field}
      </Typography>

      <Switch color="primary" style={{ color }} checked={innerValue} name="" />
    </Box>
  );
};
