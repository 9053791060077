import { WittyChat } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { useEditorPageContext } from '../../context/useEditorPageContext';
import { Box, Drawer, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  chatContainer: {
    width: '100%',
    height: '100%',
  },
}));

export const AssistantDrawer: React.FC = () => {
  const classes = useStyles();
  const {
    setIsAssistantDrawerOpen,
    isAssistantDrawerOpen,
    assistantState,
    entity,
  } = useEditorPageContext();

  return (
    <Drawer
      open={isAssistantDrawerOpen}
      onClose={() => {
        setIsAssistantDrawerOpen(false);
      }}
      PaperProps={{
        style: { width: `calc(min(30%, 450px))`, padding: 0 },
      }}
      anchor="right"
    >
      <Box className={classes.chatContainer}>
        <WittyChat
          setOpen={setIsAssistantDrawerOpen}
          messages={[
            {
              sender: 'you',
              contentMessage: `Test Data Product **${
                (entity.spec?.mesh as any)?.name
              }** against
            Governance Guardrails`,
            },
            { sender: 'witty', contentMessage: assistantState.value || '' },
          ]}
          setChatExpanded={() => {}}
          onNewSession={() => {}}
        />
      </Box>
    </Drawer>
  );
};
