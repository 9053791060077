import {
  WbBreadcrumb,
  WbBreadcrumbs,
  WbHeader,
} from '@agilelab/plugin-wb-platform';
import { Page, RoutedTabs } from '@backstage/core-components';
import { useRouteRef, useRouteRefParams } from '@backstage/core-plugin-api';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { roleDetailRouteRef, rolesPermissionsRouteRef } from '../../../routes';
import { AssignmentsTab } from './AssignmentsTab';
import { OverviewContextProvider } from './OverviewContext';
import { OverviewTab } from './OverviewTab';

export const useStyles = makeStyles(_ => ({
  removeFromFlow: {
    // this allow the div that has this class to not be on the layout flow, its purpose is to only override its children styles
    display: 'contents',
  },
  resizableCard: {
    '& > article': {
      minHeight: 0,
    },
  },
}));

export interface RoleDetailPageProps {}

export const RoleDetailPage: React.FC<RoleDetailPageProps> = ({}) => {
  const classes = useStyles();

  const [name, setName] = useState<string>();
  const routes = useMemo(() => {
    const overviewSection = {
      path: '/overview',
      title: 'Role details',
      children: <OverviewTab />,
    };

    const assignmentsSection = {
      path: '/assignments',
      title: 'Assignments',
      children: <AssignmentsTab />,
    };

    return [overviewSection, assignmentsSection];
  }, []);
  const rolesPermissionsRoute = useRouteRef(rolesPermissionsRouteRef);
  const { roleId } = useRouteRefParams(roleDetailRouteRef);

  return (
    <Page themeId="home">
      <WbHeader
        title={name}
        titleContainerStyle={{ flex: 'none' }}
        breadcrumbs={
          <WbBreadcrumbs aria-label="breadcrumb">
            <WbBreadcrumb>Administration</WbBreadcrumb>
            <WbBreadcrumb to={rolesPermissionsRoute()}>
              Roles & Permissions
            </WbBreadcrumb>
            <WbBreadcrumb>{name} </WbBreadcrumb>
          </WbBreadcrumbs>
        }
      />
      <OverviewContextProvider roleId={roleId} setName={setName}>
        <Box className={clsx(classes.removeFromFlow, classes.resizableCard)}>
          <RoutedTabs routes={routes} />
        </Box>
      </OverviewContextProvider>
    </Page>
  );
};
