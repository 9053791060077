import { Card, Grid, Paper, TextField } from '@material-ui/core';
import React from 'react';
import { GenericField, MeshCardHeader } from '@agilelab/plugin-wb-platform';
import { useDataContractEditorContext } from './context/useDataContractEditorContext';
import MissingKeyCardContent from './MissingKeyCardContent';
import { camelToSpacedCase } from '@agilelab/plugin-wb-platform-common';

export const DataSharingAgreementCard = () => {
  const { formData, handleFieldChange } = useDataContractEditorContext();
  return (
    <>
      <Card style={{ marginTop: '20px', background: 'white' }}>
        <MeshCardHeader title="Data Sharing Agreement" />
        <Paper
          elevation={0}
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            marginRight: '15px',
            marginLeft: '15px',
          }}
        >
          {formData.dataAgreementValues ? (
            Object.keys(formData.dataAgreementValues).map(key => (
              <Grid container spacing={2} key={key}>
                <Grid item xs={6}>
                  <GenericField
                    value={camelToSpacedCase(key)}
                    contentProps={{
                      style: { wordBreak: 'break-word' },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    fullWidth
                    size="small"
                    key={key}
                    value={formData.dataAgreementValues[key] ?? ''}
                    onChange={e =>
                      handleFieldChange(
                        'dataAgreementValues',
                        key,
                        e.target.value,
                      )
                    }
                  />
                </Grid>
              </Grid>
            ))
          ) : (
            <MissingKeyCardContent keyProp="Data Sharing Agreement" />
          )}
        </Paper>
      </Card>
    </>
  );
};
