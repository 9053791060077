import { Box, Link, makeStyles } from '@material-ui/core';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { WbTagsModal } from './WbTagsModal';
import { Tag, TagKind, WbTag } from './WbTag';

interface WbTagsTableCellProps {
  tags: Array<Tag | string>;
  kind?: TagKind;
  maxWidth?: number;
}

// the gap between tags, declared as constant because it is also used in the function that measures how many tags can fit when maxWidth is provided
const GAP = 8;

const useStyle = makeStyles(
  () => ({
    root: {
      display: 'flex',
      gap: GAP,
      maxWidth: '100%',
      flexWrap: 'wrap',
      position: 'relative',
    },
    remaining: {
      height: '24px',
      fontSize: '13px',
      display: 'flex',
      alignItems: 'center',
    },
    measureLayer: {
      position: 'absolute',
      visibility: 'hidden',
      display: 'flex',
      gap: GAP,
    },
  }),
  { name: 'WbTagsTableCell' },
);

export const WbTagsTableCell: React.FC<WbTagsTableCellProps> = ({
  tags,
  maxWidth,
  kind = TagKind.generic,
}) => {
  const classes = useStyle();
  const [open, setOpen] = React.useState<boolean>(false);

  // used only when maxWidth is provided, contains the count of tags that can be shown without exceeding maxWidth
  const [fitCount, setFitCount] = useState<number | null>(null);
  const parsedTags: Tag[] = tags.map(tag =>
    typeof tag === 'string' ? { tagFQN: tag } : tag,
  );

  const measureLayerRef = useRef<HTMLDivElement>(null);

  const handleClick:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLSpanElement>)
    | undefined = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // if a maxWidth is provided, render all the tags at first and then measure them in useLayoutEffect to show only the ones that fit in maxWidth
  useLayoutEffect(() => {
    if (!measureLayerRef.current || !maxWidth || !tags.length) return;

    const tagsElements = measureLayerRef.current.children;

    let remaining = maxWidth;

    let count = 0;

    for (let i = 0; i < tagsElements.length; i++) {
      const elWidth = tagsElements[i].getBoundingClientRect().width;
      const subtract = elWidth + (i > 0 ? GAP : 0);

      if (remaining - subtract <= 0) break;

      remaining -= subtract;
      count++;
    }

    setFitCount(count);
  }, [maxWidth, tags]);

  if (maxWidth)
    return (
      <>
        <Box
          className={classes.root}
          style={{ overflow: 'hidden', flexWrap: 'nowrap' }}
        >
          {!!fitCount && fitCount > 0 && (
            <>
              {/* render only the tags that fit */}
              {parsedTags.slice(0, fitCount).map((tag, i) => (
                <WbTag key={i} tag={tag} kind={kind} tooltip />
              ))}
              {!!fitCount && fitCount < tags.length && (
                <Link className={classes.remaining} onClick={handleClick}>
                  {`+${tags.length - fitCount}`}
                </Link>
              )}
            </>
          )}
          {/* render all tags in an invisible div so they can be measured */}
          <div className={classes.measureLayer} ref={measureLayerRef}>
            {parsedTags.map((tag, i) => (
              <div key={i}>
                <WbTag tag={tag} kind={kind} tooltip />
              </div>
            ))}
          </div>
        </Box>
        <WbTagsModal
          maxWidth="md"
          open={open}
          onClose={handleClose}
          tags={parsedTags}
          kind={kind}
        />
      </>
    );

  const exceed = tags.length > 3;

  return (
    <>
      <Box className={classes.root}>
        {(exceed ? parsedTags.slice(0, 3) : parsedTags).map((tag, i) => (
          <WbTag key={i} tag={tag} kind={kind} tooltip />
        ))}

        {exceed && (
          <Link className={classes.remaining} onClick={handleClick}>
            {`+${tags.length - 3}`}
          </Link>
        )}
      </Box>
      {exceed && (
        <WbTagsModal
          maxWidth="md"
          open={open}
          onClose={handleClose}
          tags={parsedTags}
          kind={kind}
        />
      )}
    </>
  );
};
