import React, { ReactElement, useState } from 'react';

import { platformCustomViewEditPermission } from '@agilelab/plugin-wb-rbac-common';
import { usePermission } from '@backstage/plugin-permission-react';
import { MenuItem, MenuList, Paper } from '@material-ui/core';
import { extractIncludeFromCode } from '../utils';
import { CustomViewPages } from '../pages';
import { WbMoreOptions } from '../../WbMoreOptions';
import { CustomViewSubMenu } from '../CustomViewSubMenu';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

type CustomViewSummaryMenuProps = {
  customViewIds: string[];
  withMenu?: boolean;
  getUrlByid: (id: string) => string;
};

export const CustomViewSummaryMenu = ({
  customViewIds,
  withMenu,
  getUrlByid,
}: CustomViewSummaryMenuProps) => {
  const { allowed: canEdit } = usePermission({
    permission: platformCustomViewEditPermission,
  });
  const [menuOpen, setMenuOpen] = useState(false);

  const onMenuClose = () => setMenuOpen(false);

  const customViewChildren = customViewIds?.map(customViewId => {
    const getCustomViewChildren = CustomViewPages?.get(customViewId);
    return getCustomViewChildren!();
  });
  const included = customViewChildren?.flatMap(child =>
    extractIncludeFromCode(child as ReactElement, '', ''),
  );
  const menu = customViewIds
    ?.map(id => {
      return {
        id: id,
        label: CustomViewPages.getMetadata(id)?.label ?? id,
      };
    })
    .concat(
      included?.map((inc: any) => ({
        id: inc.id,
        label: CustomViewPages.getMetadata(inc.id)?.label ?? inc.id,
      })),
    );

  if (!canEdit) return <></>;
  const subMenu = (
    <CustomViewSubMenu text="Custom Views" icon={<EditIcon fontSize="small" />}>
      <MenuList>
        <Paper style={{ borderRadius: 0, background: 'white' }}>
          {menu?.map(el => (
            <MenuItem key={el.id} component={Link} to={getUrlByid(el.id)}>
              {el.label}
            </MenuItem>
          ))}
        </Paper>
      </MenuList>
    </CustomViewSubMenu>
  );
  if (!withMenu) return subMenu;

  return (
    <WbMoreOptions
      MenuProps={{ MenuListProps: { style: { paddingBlock: '4px' } } }}
      onClick={e => {
        e.stopPropagation();
        setMenuOpen(true);
      }}
      open={menuOpen}
      onClose={onMenuClose}
    >
      {subMenu}
    </WbMoreOptions>
  );
};
