import React, { ReactNode, useState } from 'react';
import { Box, Modal, darken, makeStyles, ModalProps } from '@material-ui/core';
import clsx from 'clsx';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { WbCardActionButton } from '../WbCardActionButton';
import { Dots } from './Dots';

type Item = {
  src: string;
  alt: string;
};

type WbLightboxProps = {
  className?: string;
  style?: React.CSSProperties;
  items: Item[];
  open: boolean;
  onClose?: ModalProps['onClose'];
  render?: (item: Item) => ReactNode;
};

const PADDING_VERTICAL = 30;

const PADDING_HORIZONTAL = 60;

const useStyles = makeStyles(
  theme => ({
    root: {
      // disable pointer events to allow click on backdrop
      pointerEvents: 'none',
      width: '100vw',
      height: '100vh',
      display: 'grid',
      gridTemplateColumns: `${PADDING_HORIZONTAL}px 1fr ${PADDING_HORIZONTAL}px`,
      gridTemplateRows: `${PADDING_VERTICAL}px 1fr ${PADDING_VERTICAL}px`,
      gridTemplateAreas: `
        "left . right"
        "left img right"
        "left dots right"
      `,
    },
    slideButton: {
      pointerEvents: 'auto',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      '&:hover:not([disabled])': {
        backgroundColor: darken(theme.palette.background.default, 0.1),
      },
    },
    imgContainer: {
      '& > *': {
        // re enables pointer events for discendents
        pointerEvents: 'auto',
      },
      overflow: 'hidden',
      gridArea: 'img',
      display: 'grid',
      placeItems: 'center',
    },
    side: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dots: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    img: {
      overflow: 'hidden',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }),
  { name: 'WbLightbox' },
);

export const WbLightbox: React.FC<WbLightboxProps> = ({
  className,
  style,
  items,
  open,
  render,
  onClose,
}) => {
  const classes = useStyles();

  const [active, setActive] = useState(0);

  const handleBack = () => {
    setActive(old => old - 1);
  };

  const handleNext = () => {
    setActive(old => old + 1);
  };

  const isMultiple = items.length > 1;

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={clsx(classes.root, className)} style={style}>
        {open && (
          <Box className={classes.imgContainer}>
            {render ? (
              render(items[active])
            ) : (
              <img
                loading="lazy"
                className={classes.img}
                src={items[active].src}
                alt={items[active].alt}
              />
            )}
          </Box>
        )}
        <Box className={classes.side} style={{ gridArea: 'left' }}>
          {isMultiple && (
            <WbCardActionButton
              className={classes.slideButton}
              color="secondary"
              onClick={handleBack}
              disabled={active === 0}
              icon={<KeyboardArrowLeftIcon />}
            />
          )}
        </Box>
        <Box className={classes.side} style={{ gridArea: 'right' }}>
          {isMultiple && (
            <WbCardActionButton
              className={classes.slideButton}
              color="secondary"
              onClick={handleNext}
              disabled={active === items.length - 1}
              icon={<KeyboardArrowRightIcon />}
            />
          )}
        </Box>
        <Box className={classes.dots} style={{ gridArea: 'dots' }}>
          {isMultiple && <Dots n={items.length} active={active} />}
        </Box>
      </Box>
    </Modal>
  );
};
