import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { wittyAssistantApiRef } from '../api';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { WittyMessage } from '@agilelab/plugin-wb-platform';
import { handleWittyError } from '../hooks';

export type WittyAssistantHook = {
  messages: WittyMessage[];
  open: boolean;
  loading: boolean;
  onNewMessage: (message: WittyMessage) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  expanded: boolean;
  setExpanded: Function;
  onNewSession: Function;
};

export function useWittyAssistant(): WittyAssistantHook {
  const wittyAssistantApi = useApi(wittyAssistantApiRef);
  const alertApi = useApi(alertApiRef);

  const [messages, setMessages] = useState<WittyMessage[]>([]);
  const [loading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>();
  const [expanded, setExpanded] = useState(false);

  const [open, setOpen] = useState(false);

  const onNewMessage = useCallback(
    (message: WittyMessage) => {
      // Handle the new message
      setMessages(oldMessages => [...oldMessages, message]);
      setIsLoading(true);

      wittyAssistantApi
        .chat({
          request: {
            message: message.contentMessage,
            ...(sessionId ? { session_id: sessionId } : {}),
          },
        })
        .then(response => {
          const newMessage: WittyMessage = {
            sender: 'witty',
            contentMessage: response.message,
          };
          setMessages(oldMessages => [...oldMessages, newMessage]);
          if (!sessionId) {
            setSessionId(response.session_id);
          }
        })
        .catch((error: any) => {
          handleWittyError(error, alertApi);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [wittyAssistantApi, alertApi, sessionId],
  );

  const onNewSession = useCallback(() => {
    setSessionId(null);
    setMessages([]);
  }, []);

  return {
    loading,
    messages,
    open,
    onNewMessage,
    setOpen,
    expanded,
    setExpanded,
    onNewSession,
  };
}
