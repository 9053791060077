import {
  createElement,
  FunctionComponent,
  ReactNode,
  ReactElement,
} from 'react';
import _ from 'lodash';
import yaml from 'yaml';

const isEmptyNode = (n: any) =>
  Object.keys(n).filter(np => np !== 'children').length === 0;

export const dumpJsx = (node: ReactElement): any => {
  const dump: Record<string, any> = {};
  if (Array.isArray(node)) {
    return {
      children: (node as ReactElement[]).map((n: ReactElement) => dumpJsx(n)),
    };
  }
  Object.keys(node.props || {}).forEach(p => {
    if (p !== 'children') dump[p] = node.props[p];
  });
  if (node.props?.children) {
    if (node.props.children instanceof Array)
      dump.children = node.props.children.map((n: any) => dumpJsx(n));
    else dump.children = [dumpJsx(node.props.children)];
    dump.children = dump.children.flatMap((n: any) => {
      // intercept bogus empty nodes...
      if (isEmptyNode(n)) return n.children;
      return [n];
    });
  }
  return dump;
};

/*
NOTE:
when dumping the segment recorded in the registry, we need to recreate the right conditions, inducing a fake rendering:
the use of two fake components instead of one is due to need attach the right DataRootPathContextProvider
*/
export const dumpJsxAsYaml = (node: any): string => {
  return yaml.stringify({ view: { children: [dumpJsx(node)] } });
};
export const buildJsx = (
  CustomView: FunctionComponent,
  view: any,
): ReactNode => {
  if (!view) return undefined;
  const children = (view.children || []).map((c: ReactNode) =>
    buildJsx(CustomView, c),
  );
  if (!view.type) return children; // top level node
  const nodeProps: Record<string, any> = _.omit(Object.assign({}, view), [
    'children',
  ]);
  // this is required for make the test working...
  if (children.length > 0)
    return createElement(CustomView, nodeProps, children);
  return createElement(CustomView, nodeProps);
};
