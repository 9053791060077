import React, { useEffect, useMemo } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { WbCard } from '../../WbCard';
import CloseIcon from '@material-ui/icons/Close';
import { useGeneralPurposePickerContext } from '../GeneralPurposePickerContext';
import { GenericEntityType, Provider, TemplateEntity } from '../types';
import { SingleSelection } from './SingleSelection';
import { MultiSelection } from './MultiSelection/MultiSelection';
import { CustomSnackbarError } from '../../CustomSnackbarError';
import { ProviderFilters } from './CommonFilter';

export interface GeneralEntityPickerDialogProps {
  title: string;
  multiSelection?: boolean;
  providerMapper: Map<string, Provider<GenericEntityType[], ProviderFilters>>;
  availableKinds: TemplateEntity[];
  parentTemplateRef?: string;
}

export const GeneralEntityPickerDialog = (
  props: GeneralEntityPickerDialogProps,
) => {
  const {
    openDialog,
    setOpenDialog,
    errorMessage,
    setErrorMessage,
    selectedKind,
  } = useGeneralPurposePickerContext();

  const entityProv = useMemo(() => {
    return props.providerMapper.get(selectedKind);
  }, [props.providerMapper, selectedKind]);

  useEffect(() => {
    if (!entityProv) {
      setErrorMessage({
        type: 'error',
        title: 'Error',
        content:
          'Error while loading entity provider. Please contact the platform team.',
      });
    }
  }, [entityProv, setErrorMessage]);

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
      }}
      maxWidth="xl"
      fullWidth
    >
      <WbCard
        title={props.title}
        cardStyle={{
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'column',
          height: '70vh',
        }}
        actions={
          <IconButton
            aria-label="clear"
            onClick={() => setOpenDialog(false)}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        }
      >
        {errorMessage && <CustomSnackbarError message={errorMessage} />}
        {props.multiSelection ? (
          <MultiSelection
            {...props}
            selectedEntityProvider={entityProv}
            parentTemplateRef={props.parentTemplateRef}
          />
        ) : (
          <SingleSelection
            {...props}
            selectedEntityProvider={entityProv}
            parentTemplateRef={props.parentTemplateRef}
          />
        )}
      </WbCard>
    </Dialog>
  );
};
