import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles(theme => ({
  codeSkeletonContainer: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

export function CodeComparisonSkeleton({
  height,
}: {
  height?: string | number;
}) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.codeSkeletonContainer}>
      <Grid item xs={6}>
        <Skeleton variant="rect" width="100%" height={height} />
      </Grid>

      <Grid item xs={6}>
        <Skeleton variant="rect" width="100%" height={height} />
      </Grid>
    </Grid>
  );
}
