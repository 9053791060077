import { z } from 'zod';
import { AdditionalFilters } from './policy-filters';
import yaml from 'yaml';

const getDescriptor = z.object({
  resource: z.object({ descriptor: z.string() }),
});

export interface ResourceInput {
  resource?: {
    descriptor: string;
  };
}

export interface PolicyTestRequestBodyInput
  extends AdditionalFilters,
    ResourceInput {}

const descriptorParser = z.object({
  id: z.string(),
  environment: z.string(),
  kind: z.string(),
});

export interface AdditionalResource {
  resource?: {
    id: string;
    environment: string;
    resourceType: string;
    descriptor: string;
  };
}

export interface PolicyTestRequestBody
  extends AdditionalFilters,
    AdditionalResource {}

export function resourceToPolicyTestRequestBody(
  resource: ResourceInput,
): AdditionalResource {
  const validatedResource = getDescriptor.parse(resource);
  const descriptor = validatedResource.resource.descriptor;
  const parsedDescriptor = yaml.parse(descriptor);
  const validatedDescriptor = descriptorParser.parse(parsedDescriptor);
  return {
    resource: {
      id: validatedDescriptor.id,
      environment: validatedDescriptor.environment,
      resourceType: validatedDescriptor.kind,
      descriptor: descriptor,
    },
  };
}
