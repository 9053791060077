import React from 'react';
import { MultiSelectionContent } from './MultiSelectionContent';
import { MultiSelectionPickerContextProvider } from './MultiSelectionPickerContext';
import { WbDrawer } from '../WbDrawer';
import { PermissionEntity } from '@agilelab/plugin-wb-rbac-common';

export interface WbMultiSelectionPickerDrawerProps<T> {
  title: string;
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  selectedValues: T[];
  setSelectedValues: React.Dispatch<React.SetStateAction<T[]>>;
  children: React.ReactNode;
  fetchAiData?: () => Promise<T[]>;
  contentHeader?: React.JSX.Element;
  onApply?: (permissions: PermissionEntity[]) => {};
}

export function WbMultiSelectionPickerDrawer<T>(
  props: WbMultiSelectionPickerDrawerProps<T>,
) {
  const {
    openDrawer,
    setOpenDrawer,
    selectedValues,
    setSelectedValues,
    children,
    title,
    fetchAiData,
    contentHeader,
    onApply,
  } = props;

  return (
    <WbDrawer
      open={openDrawer}
      setOpen={setOpenDrawer}
      title={title}
      contentContainerStyle={{ padding: 0 }}
    >
      <MultiSelectionPickerContextProvider<T>
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        fetchAIData={fetchAiData}
      >
        <MultiSelectionContent
          aiEnabled={!!fetchAiData}
          title={title}
          setOpen={setOpenDrawer}
          contentHeader={contentHeader}
          contentHeight="95vh"
          onApply={onApply}
        >
          {/* left content */}
          {children}
        </MultiSelectionContent>
      </MultiSelectionPickerContextProvider>
    </WbDrawer>
  );
}
