import { useLastVisitedMainPage, MainPageType } from './useLastVisitedMainPage';
import { RouteFunc } from '@backstage/core-plugin-api';
import { useSessionQueryString } from './useSessionQueryString';
import { MARKETPLACE_PROJECTS_DEFAULT_FILTERS } from '@agilelab/plugin-wb-search-common';

type MarketplaceProjectsDefaultFilters =
  typeof MARKETPLACE_PROJECTS_DEFAULT_FILTERS;

const ALL_TAXONOMIES_FILTER = 'all';

type Filters = {
  [K in MarketplaceProjectsDefaultFilters[number]['label']]: any;
};

export const useDynamicBreadcrumbLink = (
  fallbackRoute: RouteFunc<undefined> | string,
  mainPage: MainPageType,
  queryParams?: {
    filters?: Filters;
    category?: string;
  },
): string => {
  const [lastVisitedPage] = useLastVisitedMainPage(mainPage);
  const [queryString] = useSessionQueryString(lastVisitedPage);

  let dynamicBreadcrumbLink = lastVisitedPage;

  if (!dynamicBreadcrumbLink) {
    dynamicBreadcrumbLink =
      typeof fallbackRoute === 'string' ? fallbackRoute : fallbackRoute();
  }

  const searchParams = new URLSearchParams();
  if (queryParams) {
    searchParams.set('category', queryParams.category ?? ALL_TAXONOMIES_FILTER);
    if (queryParams.filters) {
      Object.entries(queryParams.filters).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v, i) =>
            searchParams.append(`filters[${key}][${i}]`, v),
          );
        } else {
          searchParams.set(`filters[${key}]`, value);
        }
      });
    }
    dynamicBreadcrumbLink += `?${searchParams.toString()}`;
  } else if (queryString.startsWith('?')) {
    dynamicBreadcrumbLink += queryString;
  }

  return dynamicBreadcrumbLink;
};
