import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import React from 'react';
import { TableContent } from './contents/TableContent';
import { TableHeader } from './contents/TableHeader';
import { TableContentLoader } from './loaders/TableContentLoader';
import { WbNoData } from '../WbNoData';
import {
  WbTableContextProvider,
  useWbTableContext,
} from './context/WbTableContext';
import { WbTableLoader, WbTableProps } from './types';

function TableLoader({ loader, columnCount, rowCount = 10 }: WbTableLoader) {
  if (loader) {
    return loader;
  }
  if (columnCount) {
    return <TableContentLoader rows={rowCount} tableCells={columnCount} />;
  }
  return <></>;
}

function WbTableHeader<T>({
  styles,
}: Omit<WbTableProps<T>, 'classNames' | 'pagination'>) {
  const { components, columns } = useWbTableContext<T>();
  if (components?.tableHeader) {
    return components.tableHeader;
  }

  if (columns.length) {
    return <TableHeader style={{ ...styles?.header }} />;
  }
  return <></>;
}

function WbTableContent<T>({ styles, classNames }: WbTableProps<T>) {
  const { columns, rows, components } = useWbTableContext<T>();
  if (components?.tableContent?.body) {
    return components.tableContent.body;
  }
  if (columns.length && rows.length) {
    return (
      <TableContent<T>
        rowClassName={classNames?.body}
        rowStyle={styles?.body}
      />
    );
  }
  return <></>;
}

export function WbTable<T>({
  components,
  noDataLabel,
  noDataComponent,
  pagination,
  styles,
  classNames,
  onSort,
  stickyHeader,
  selectedItems,
  selection,
  setSelectedItems,
  checkIfDisabled,
  tooltipIfDisabled,
}: WbTableProps<T>) {
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    pagination?.onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    pagination?.onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  if (
    !components?.tableLoader?.loading &&
    !components?.tableContent?.body &&
    !components?.tableContent?.rows?.length
  )
    return noDataComponent || <WbNoData fillContainer text={noDataLabel} />;

  return (
    <>
      <WbTableContextProvider
        components={components}
        onSort={onSort}
        selection={selection}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        checkIfDisabled={checkIfDisabled}
        tooltipIfDisabled={tooltipIfDisabled}
      >
        <TableContainer
          className={classNames?.container}
          style={styles?.container}
        >
          <Table stickyHeader={stickyHeader} size="small">
            <WbTableHeader styles={styles} />
            {components?.tableLoader?.loading ? (
              <TableBody>
                <TableLoader
                  columnCount={components?.tableContent?.columns?.length}
                  {...components?.tableLoader}
                />
              </TableBody>
            ) : (
              <WbTableContent styles={styles} classNames={classNames} />
            )}
          </Table>
        </TableContainer>

        {!!pagination?.count && (
          <TablePagination
            rowsPerPageOptions={
              pagination.rowsPerPageOptions || [25, 50, 75, 100, 200]
            }
            component="div"
            count={pagination.count}
            rowsPerPage={pagination.limit}
            labelDisplayedRows={
              pagination.countlessOptions
                ? ({ from, to }) => `${from}-${to}`
                : undefined
            }
            nextIconButtonProps={
              pagination.countlessOptions
                ? {
                    disabled: !pagination.countlessOptions.hasNextPage(),
                  }
                : undefined
            }
            backIconButtonProps={
              pagination.countlessOptions
                ? {
                    disabled: pagination.countlessOptions.isFirstPage(),
                  }
                : undefined
            }
            page={
              'currentPage' in pagination
                ? pagination.currentPage
                : Math.floor(pagination.offset / pagination.limit)
            }
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ alignSelf: 'flex-end' }}
          />
        )}
      </WbTableContextProvider>
    </>
  );
}
