import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { WbCardContent } from '@agilelab/plugin-wb-platform';
import { useRouteRefParams } from '@backstage/core-plugin-api';
import { roleDetailRouteRef } from '../../../routes';
import { AssignmentsContextProvider } from './AssignmentsContext';
import {
  AssignmentsCardHeader,
  AssignmentsTable,
  DeleteConfirmDialog,
} from './AssignmentsTable';

const useStyles = makeStyles(
  theme => ({
    paper: {
      height: '100%',
    },
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexBox: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  }),
  { name: 'AssignmentsTab' },
);

export const AssignmentsTab: React.FC = () => {
  const { roleId } = useRouteRefParams(roleDetailRouteRef);
  const classes = useStyles();

  return (
    <AssignmentsContextProvider roleId={roleId}>
      <Paper className={classes.paper}>
        <WbCardContent className={classes.container}>
          <AssignmentsCardHeader />
          <AssignmentsTable />
        </WbCardContent>
      </Paper>
      <DeleteConfirmDialog />
    </AssignmentsContextProvider>
  );
};
