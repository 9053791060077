import {
  CountlessOptions,
  decodeBase64PageCursor,
  encodeBase64PageCursor,
  useElementSize,
  WbCard,
  WbCardActionButton,
  WbDivider,
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
  WbNoData,
  WbPagination,
  WbPaginationProps,
  WbSearch,
} from '@agilelab/plugin-wb-platform';
import { useTaxonomySelection } from '@agilelab/plugin-wb-practice-shaper';
import { OrderBySelector, SearchFilters } from '@agilelab/plugin-wb-search';
import {
  MARKETPLACE_PROJECTS_CONSUMABLE,
  MARKETPLACE_PROJECTS_DATA_LANDSCAPE,
  MARKETPLACE_PROJECTS_ENVIRONMENT,
  MARKETPLACE_PROJECTS_KIND,
  MARKETPLACE_PROJECTS_SYSTEM_URN,
  MARKETPLACE_PROJECTS_URN,
} from '@agilelab/plugin-wb-search-common';
import { parseEntityRef } from '@backstage/catalog-model';
import { ErrorPanel } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Box, List, makeStyles, Typography } from '@material-ui/core';
import { default as React, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { ViewTypeSelector } from '../components/ViewTypeSelector';
import { useSearchContext } from '../context/SearchContext';
import { MarketplaceSearchListItem } from '../SearchResult/MarketplaceSearchListItem';
import { SearchResultsLoader } from './loaders/SearchResultsLoader';
import { SearchResultsTableLoader } from './loaders/SearchResultsTableLoader';
import { SearchResultsTable } from './SearchResultsTable';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import LayersClearIcon from '@material-ui/icons/LayersClear';

type SearchResultsContentProps = {};

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      gap: theme.spacing(1),
    },
    resultContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      height: '100%',
      overflow: 'hidden',
    },
    divider: {
      marginBlock: theme.spacing(1.5),
    },
    resultsHeader: {
      paddingRight: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    resultsHeaderText: {
      color: theme.palette.secondary.dark,
      '& > b': {
        fontWeight: 500,
      },
      fontWeight: 400,
    },
    resultsHeaderBox: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    iconContainer: {
      border: `1px solid ${theme.palette.softened.primary}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '32px',
      borderRadius: '100%',
      borderStyle: 'dashed',
      // to separate the icon from the header and text
      margin: theme.spacing(2.5),
    },
    icon: {
      fontSize: '105px',
      color: theme.palette.softened.primary,
    },
    textNoDataContent: {
      textAlign: 'center',
      maxWidth: '400px',
    },
  }),
  { name: 'SearchResultsContent' },
);

const DefaultNoDataComponent = () => {
  const classes = useStyles();

  return (
    <div style={{ flex: 1 }}>
      <WbNoData
        fillContainer
        header="Sorry, no results were found"
        icon={
          <Box className={classes.iconContainer}>
            <LayersClearIcon className={classes.icon} />
          </Box>
        }
        text={
          <Typography className={classes.textNoDataContent}>
            Try running the search again, using a different combination of terms
            or filters.
          </Typography>
        }
      />
    </div>
  );
};

export const SearchResultsContent = ({}: SearchResultsContentProps) => {
  const classes = useStyles();
  const { selectedTaxonomyRef } = useTaxonomySelection();

  const resultsRef = useRef<HTMLDivElement>(null);
  const { clientHeight: resultsHeight } = useElementSize(resultsRef);

  const configApi = useApi(configApiRef);

  const prototypePageEnabled = configApi.getOptionalBoolean(
    'mesh.builder.ui.prototypePage.enabled',
  );

  // only show the link to prototype creation if the taxonomy is data-mesh (hardcoded for prototype demo)
  const showNewPrototypeLink = useMemo(() => {
    let show = true;
    try {
      show =
        !!prototypePageEnabled &&
        parseEntityRef(selectedTaxonomyRef).name.includes('data-mesh');
    } catch {
      show = false;
    }

    return show;
  }, [prototypePageEnabled, selectedTaxonomyRef]);

  const navigate = useNavigate();

  const {
    view,
    setView,
    value,
    loading,
    error,
    environment,
    limit,
    cursor,
    setLimit,
    setCursor,
    query,
    setQuery,
    filters,
    setFilters,
    setFavoriteFilter,
    setOrderByOption,
    orderByOption,
    favoriteFilter,
    filtersContext,
    filterStateValid,
  } = useSearchContext();

  const handleChangePage = (newPage: number) => {
    setCursor(encodeBase64PageCursor(newPage));
  };

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
  };

  const countlessOptions = useMemo<CountlessOptions>(
    () => ({
      hasNextPage: () => !!value?.nextPageCursor,
      hasPrevPage: () => !!value?.previousPageCursor,
    }),
    [value],
  );

  const currentPage = decodeBase64PageCursor(cursor);

  const displayedRowsLabel = `${currentPage * limit + 1}-${
    currentPage * limit + (value?.results.length ?? 0)
  }${value?.numberOfResults ? ` of ${value.numberOfResults}` : ''}`;

  const numberOfResults = value?.numberOfResults;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // TODO:: use breakpoints of theme
  const isMobile = useMemo(() => windowWidth < 1024, [windowWidth]);

  useEffect(() => {
    if (isMobile) {
      setView('table');
    }
  }, [isMobile, setView]);

  // decide which pagination mode to use, countless if the total number of results is not returned by the query
  const paginationProps = useMemo(() => {
    let props: Pick<
      WbPaginationProps,
      'count' | 'countlessOptions' | 'labelDisplayedRows'
    >;
    // countless mode (-1)
    if (numberOfResults === undefined) {
      props = {
        count: -1,
        countlessOptions: countlessOptions,
        labelDisplayedRows: () => displayedRowsLabel,
      };
    } else {
      props = { count: Number(numberOfResults) };
    }
    return props;
  }, [numberOfResults, countlessOptions, displayedRowsLabel]);

  const renderResults = () => {
    if (
      loading ||
      filtersContext.availableValues.loading ||
      // If the filter state is currenlt not valid, it means it's being updated to a valid state so instead of showing its results we show the Loader until it's valid again
      filterStateValid === false
    )
      return view === 'table' ? (
        <SearchResultsTableLoader />
      ) : (
        <SearchResultsLoader />
      );
    if (error) return <ErrorPanel error={error} />;
    if (!value) return <ErrorPanel error={new Error('Something went wrong')} />;

    let resultsComponent = <DefaultNoDataComponent />;

    if (value.results.length && view === 'table')
      resultsComponent = <SearchResultsTable rows={value.results} />;

    if (value.results.length && view === 'cards')
      resultsComponent = (
        <div style={{ flex: 1, overflowY: 'scroll' }}>
          <List>
            {value.results.map(r => (
              <MarketplaceSearchListItem
                key={r.document.documentId}
                searchResult={r}
              />
            ))}
          </List>
        </div>
      );

    return (
      <>
        <Box className={classes.resultsHeader}>
          <Typography component="span" className={classes.resultsHeaderText}>
            <b>{value?.numberOfResults ?? ''} Results</b>{' '}
            {environment && environment.name ? (
              <>
                in <b>{snakeCaseToTitleCase(environment.name)}</b>
              </>
            ) : (
              <b>List</b>
            )}
          </Typography>
          <Box className={classes.resultsHeaderBox}>
            <OrderBySelector
              onChange={setOrderByOption}
              orderByOption={orderByOption}
            />
            {!isMobile && (
              <>
                <WbDivider
                  orientation="vertical"
                  style={{ marginLeft: '8px' }}
                />
                <ViewTypeSelector />
              </>
            )}
          </Box>
        </Box>
        {resultsComponent}
        {!!value.results.length && (
          <>
            <WbDivider orientation="horizontal" />
            <WbPagination
              {...paginationProps}
              rowsPerPageOptions={[15, 25, 50]}
              limit={limit}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              currentPage={currentPage}
            />
          </>
        )}
      </>
    );
  };

  const hiddenFilters = [
    MARKETPLACE_PROJECTS_URN.label,
    MARKETPLACE_PROJECTS_SYSTEM_URN.label,
    MARKETPLACE_PROJECTS_ENVIRONMENT.label,
    MARKETPLACE_PROJECTS_DATA_LANDSCAPE.label,
    MARKETPLACE_PROJECTS_CONSUMABLE.label,
    MARKETPLACE_PROJECTS_KIND.label,
  ];

  return (
    <Box className={classes.root}>
      <WbSearch value={query} debounced onChange={setQuery} />
      <WbCard
        title="Search Results"
        cardStyle={{ flex: 1, overflow: 'hidden' }}
        actions={
          showNewPrototypeLink ? (
            <WbCardActionButton
              label="Request a Data Product"
              onClick={() => navigate('/prototypes/edit')}
              variant="outlined"
            />
          ) : undefined
        }
      >
        <WbDockableGroup direction="horizontal">
          <WbDockablePanel
            defaultSize={16}
            maxSize={50}
            minSize={0}
            style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
          >
            <SearchFilters
              context={filtersContext}
              hiddenFilterLabels={hiddenFilters}
              filters={filters ?? {}}
              setFilters={setFilters}
              modalAnchorEl={resultsRef.current}
              modalHeight={resultsHeight}
              setFavoriteFilter={setFavoriteFilter}
              favoriteFilter={favoriteFilter}
            />
          </WbDockablePanel>
          <WbDockableResizeHandle
            showIconOnHover
            divider
            direction="horizontal"
          />
          <WbDockablePanel defaultSize={84}>
            <div className={classes.resultContainer} ref={resultsRef}>
              {renderResults()}
            </div>
          </WbDockablePanel>
        </WbDockableGroup>
      </WbCard>
    </Box>
  );
};
