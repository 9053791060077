import {
  ApiRef,
  createApiFactory,
  createApiRef,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { RbacApi, RbacClient } from '@agilelab/plugin-wb-rbac-common';
import { rootRouteRef } from './routes';

export const rbacApiRef: ApiRef<RbacApi> = createApiRef({
  id: 'plugin.rbac.service',
});

/** @public */
export const rbacPlugin = createPlugin({
  id: 'rbac',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: rbacApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) => new RbacClient(discoveryApi),
    }),
  ],
});

/**
 * The Router and main entrypoint to the plugin.
 *
 * @public
 */
export const RbacPanel = rbacPlugin.provide(
  createRoutableExtension({
    name: 'RBAC Panel',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
