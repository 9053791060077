import { InstanceEntity } from '@agilelab/plugin-wb-marketplace-common';
import {
  CustomView,
  CustomViewComponent,
  CustomViewCtrls,
  CustomViewPageCategory,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import preview1 from '../preview/marketplace_system_popover_overview/marketplace_system_popover_overview.png';
import preview2 from '../preview/marketplace_system_popover_overview/marketplace_system_popover_overview2.png';
import thumbnail from '../preview/marketplace_system_popover_overview/marketplace_system_popover_overview_thumbnail.png';

CustomViewPages.register({
  id: 'marketplace_system_popover_overview',
  meta: {
    label: 'System Popover',
    category: CustomViewPageCategory.MARKETPLACE,
    preview: {
      thumbnail: { src: thumbnail },
      items: [{ src: preview1 }, { src: preview2 }],
    },
  },
  function: function Entity() {
    return (
      <CustomViewComponent type="container">
        <CustomViewComponent
          label="Version"
          value="{{version}}"
          type="string"
          colSpan="2"
        />
        <CustomViewComponent
          type="date"
          value="{{_computedInfo.published_at}}"
          label="Published Date"
          colSpan="2"
        />
        <CustomViewComponent type="hline" />
        <CustomViewComponent
          label="Owner"
          value="{{_computedInfo.owner_display_name}}"
          type="string"
          colSpan="2"
        />
        <CustomViewComponent
          label="Email"
          value="{{email}}"
          type="string"
          colSpan="2"
        />
        <CustomViewComponent
          type="hline"
          showWhen={{
            value: '{{ tags.length }}',
            notEquals: 0,
          }}
        />
        <CustomViewComponent
          showWhen={{
            value: '{{tags.length}}',
            notEquals: 0,
          }}
          label="Tags"
          type="tags"
          path="tags"
        />
        <CustomViewComponent type="marketplace_flag_and_score" />
      </CustomViewComponent>
    );
  },
});

export type SystemPopoverOverviewProps = {
  data: InstanceEntity;
};

export const SystemPopoverOverview: React.FC<SystemPopoverOverviewProps> = ({
  data,
}) => {
  return (
    <CustomView
      id="marketplace_system_popover_overview"
      typeId={data.descriptor.kind}
      templateId={data.descriptor.useCaseTemplateId}
      data={data}
    />
  );
};

CustomViewCtrls.register({
  id: 'marketplace_system_popover_overview',
  function: (data: InstanceEntity) => {
    return {
      ...data.descriptor,
      _computedInfo: data,
    };
  },
});
