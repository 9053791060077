import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const AttachFileIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_15_4855"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="currentColor" />
    </mask>
    <g>
      <path
        d="M16 11V6H18V11H16ZM11 17.9C10.4167 17.7333 9.9375 17.4083 9.5625 16.925C9.1875 16.4417 9 15.8833 9 15.25V6H11V17.9ZM11.75 22C10.0167 22 8.54167 21.3917 7.325 20.175C6.10833 18.9583 5.5 17.4833 5.5 15.75V6.5C5.5 5.25 5.9375 4.1875 6.8125 3.3125C7.6875 2.4375 8.75 2 10 2C11.25 2 12.3125 2.4375 13.1875 3.3125C14.0625 4.1875 14.5 5.25 14.5 6.5V14H12.5V6.5C12.4833 5.8 12.2375 5.20833 11.7625 4.725C11.2875 4.24167 10.7 4 10 4C9.3 4 8.70833 4.24167 8.225 4.725C7.74167 5.20833 7.5 5.8 7.5 6.5V15.75C7.48333 16.9333 7.89167 17.9375 8.725 18.7625C9.55833 19.5875 10.5667 20 11.75 20C12.1667 20 12.5625 19.9458 12.9375 19.8375C13.3125 19.7292 13.6667 19.5667 14 19.35V21.575C13.65 21.7083 13.2875 21.8125 12.9125 21.8875C12.5375 21.9625 12.15 22 11.75 22ZM16 21V18H13V16H16V13H18V16H21V18H18V21H16Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'AttachFileIcon',
);

export const MicIcon = createSvgIcon(
  <>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_15_4870"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="currentColor" />
      </mask>
      <g>
        <path
          d="M12 14C11.1667 14 10.4583 13.7083 9.875 13.125C9.29167 12.5417 9 11.8333 9 11V5C9 4.16667 9.29167 3.45833 9.875 2.875C10.4583 2.29167 11.1667 2 12 2C12.8333 2 13.5417 2.29167 14.125 2.875C14.7083 3.45833 15 4.16667 15 5V11C15 11.8333 14.7083 12.5417 14.125 13.125C13.5417 13.7083 12.8333 14 12 14ZM11 21V17.925C9.26667 17.6917 7.83333 16.9167 6.7 15.6C5.56667 14.2833 5 12.75 5 11H7C7 12.3833 7.4875 13.5625 8.4625 14.5375C9.4375 15.5125 10.6167 16 12 16C13.3833 16 14.5625 15.5125 15.5375 14.5375C16.5125 13.5625 17 12.3833 17 11H19C19 12.75 18.4333 14.2833 17.3 15.6C16.1667 16.9167 14.7333 17.6917 13 17.925V21H11ZM12 12C12.2833 12 12.5208 11.9042 12.7125 11.7125C12.9042 11.5208 13 11.2833 13 11V5C13 4.71667 12.9042 4.47917 12.7125 4.2875C12.5208 4.09583 12.2833 4 12 4C11.7167 4 11.4792 4.09583 11.2875 4.2875C11.0958 4.47917 11 4.71667 11 5V11C11 11.2833 11.0958 11.5208 11.2875 11.7125C11.4792 11.9042 11.7167 12 12 12Z"
          fill="currentColor"
        />
      </g>
    </svg>
  </>,
  'MicIcon',
);

export const SendIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_15_4879"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="currentColor" />
    </mask>
    <g>
      <path
        d="M3 20V4L22 12L3 20ZM5 17L16.85 12L5 7V10.5L11 12L5 13.5V17Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'SendIcon',
);

export const BrandIcon = createSvgIcon(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g mask="url(#mask0_15_1499)">
      <path
        d="M10.6667 8.66675V7.33342H13.3333V8.66675H10.6667ZM11.4667 13.3334L9.33333 11.7334L10.1333 10.6667L12.2667 12.2667L11.4667 13.3334ZM10.1333 5.33341L9.33333 4.26675L11.4667 2.66675L12.2667 3.73341L10.1333 5.33341ZM2 10.0001V6.00008H4.66667L8 2.66675V13.3334L4.66667 10.0001H2ZM6.66667 5.90008L5.23333 7.33342H3.33333V8.66675H5.23333L6.66667 10.1001V5.90008Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'BrandIcon',
);

export const ContentCopyIcon = createSvgIcon(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g mask="url(#mask0_15_1493)">
      <path
        d="M6 11.9999C5.63333 11.9999 5.31944 11.8694 5.05833 11.6083C4.79722 11.3471 4.66667 11.0333 4.66667 10.6666V2.66659C4.66667 2.29992 4.79722 1.98603 5.05833 1.72492C5.31944 1.46381 5.63333 1.33325 6 1.33325H12C12.3667 1.33325 12.6806 1.46381 12.9417 1.72492C13.2028 1.98603 13.3333 2.29992 13.3333 2.66659V10.6666C13.3333 11.0333 13.2028 11.3471 12.9417 11.6083C12.6806 11.8694 12.3667 11.9999 12 11.9999H6ZM6 10.6666H12V2.66659H6V10.6666ZM3.33333 14.6666C2.96667 14.6666 2.65278 14.536 2.39167 14.2749C2.13056 14.0138 2 13.6999 2 13.3333V3.99992H3.33333V13.3333H10.6667V14.6666H3.33333Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'ContentCopyIcon',
);

export const DownloadIcon = createSvgIcon(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g mask="url(#mask0_15_1496)">
      <path
        d="M8.00008 10.6667L4.66675 7.33342L5.60008 6.36675L7.33342 8.10008V2.66675H8.66675V8.10008L10.4001 6.36675L11.3334 7.33342L8.00008 10.6667ZM4.00008 13.3334C3.63341 13.3334 3.31953 13.2029 3.05841 12.9417C2.7973 12.6806 2.66675 12.3667 2.66675 12.0001V10.0001H4.00008V12.0001H12.0001V10.0001H13.3334V12.0001C13.3334 12.3667 13.2029 12.6806 12.9417 12.9417C12.6806 13.2029 12.3667 13.3334 12.0001 13.3334H4.00008Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'DownloadIcon',
);

export const ThumbUpIcon = createSvgIcon(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g mask="url(#mask0_15_1490)">
      <path
        d="M11.9999 14.0001H4.66659V5.33341L9.33325 0.666748L10.1666 1.50008C10.2444 1.57786 10.3083 1.68341 10.3583 1.81675C10.4083 1.95008 10.4333 2.07786 10.4333 2.20008V2.43341L9.69992 5.33341H13.9999C14.3555 5.33341 14.6666 5.46675 14.9333 5.73341C15.1999 6.00008 15.3333 6.31119 15.3333 6.66675V8.00008C15.3333 8.07786 15.3221 8.16119 15.2999 8.25008C15.2777 8.33897 15.2555 8.4223 15.2333 8.50008L13.2333 13.2001C13.1333 13.4223 12.9666 13.6112 12.7333 13.7667C12.4999 13.9223 12.2555 14.0001 11.9999 14.0001ZM5.99992 12.6667H11.9999L13.9999 8.00008V6.66675H7.99992L8.89992 3.00008L5.99992 5.90008V12.6667ZM4.66659 5.33341V6.66675H2.66659V12.6667H4.66659V14.0001H1.33325V5.33341H4.66659Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'ThumbUpIcon',
);

export const ThumbDownIcon = createSvgIcon(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g mask="url(#mask0_15_1487)">
      <path
        d="M4.00008 2H11.3334V10.6667L6.66675 15.3333L5.83342 14.5C5.75564 14.4222 5.69175 14.3167 5.64175 14.1833C5.59175 14.05 5.56675 13.9222 5.56675 13.8V13.5667L6.30008 10.6667H2.00008C1.64453 10.6667 1.33341 10.5333 1.06675 10.2667C0.800081 10 0.666748 9.68889 0.666748 9.33333V8C0.666748 7.92222 0.677859 7.83889 0.700081 7.75C0.722304 7.66111 0.744526 7.57778 0.766748 7.5L2.76675 2.8C2.86675 2.57778 3.03341 2.38889 3.26675 2.23333C3.50008 2.07778 3.74453 2 4.00008 2ZM10.0001 3.33333H4.00008L2.00008 8V9.33333H8.00008L7.10008 13L10.0001 10.1V3.33333ZM11.3334 10.6667V9.33333H13.3334V3.33333H11.3334V2H14.6667V10.6667H11.3334Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'ThumbDownIcon',
);

export const WittyExpandIcon = createSvgIcon(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 10.7867L13.7325 12.5542L11.0892 9.91083L9.91083 11.0892L12.5542 13.7325L10.7867 15.5H15.5V10.7867ZM0.5 5.21333L2.2675 3.44583L4.91083 6.08917L6.08917 4.91083L3.44583 2.2675L5.21333 0.5H0.5V5.21333ZM15.5 0.5H10.7867L12.5542 2.2675L9.91083 4.91083L11.0892 6.08917L13.7325 3.44583L15.5 5.21333V0.5ZM0.5 15.5H5.21333L3.44583 13.7325L6.08917 11.0892L4.91083 9.91083L2.2675 12.5542L0.5 10.7867V15.5Z"
      fill="currentColor"
    />
  </svg>,
  'WittyExpandIcon',
);

export const WittyNewSessionIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H11.6042L9.9375 4.16667H4.16667V15.8333H15.8333V10.0417L17.5 8.375V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM7.5 12.5V8.95833L15.1458 1.3125C15.3125 1.14583 15.5 1.02083 15.7083 0.9375C15.9167 0.854167 16.125 0.8125 16.3333 0.8125C16.5556 0.8125 16.7674 0.854167 16.9688 0.9375C17.1701 1.02083 17.3542 1.14583 17.5208 1.3125L18.6875 2.5C18.8403 2.66667 18.9583 2.85069 19.0417 3.05208C19.125 3.25347 19.1667 3.45833 19.1667 3.66667C19.1667 3.875 19.1285 4.07986 19.0521 4.28125C18.9757 4.48264 18.8542 4.66667 18.6875 4.83333L11.0417 12.5H7.5ZM9.16667 10.8333H10.3333L15.1667 6L14.5833 5.41667L13.9792 4.83333L9.16667 9.64583V10.8333Z"
      fill="currentColor"
    />
  </svg>,
  'WittyNewSessionIcon',
);
