import { entityRefToUrn } from '@agilelab/plugin-wb-builder-common';
import { ConsumableMode } from '@agilelab/plugin-wb-marketplace-common';
import { metricsApiRef } from '@agilelab/plugin-wb-metrics';
import {
  Logo,
  useEnvironmentsContext,
  WbCard,
  WbDivider,
} from '@agilelab/plugin-wb-platform';
import {
  ALL_TAXONOMIES_FILTER,
  practiceShaperApiRef,
  useTaxonomySelection,
} from '@agilelab/plugin-wb-practice-shaper';
import { witboostSearchApiRef } from '@agilelab/plugin-wb-search';
import {
  AtomicFilter,
  CollatorType,
  ComplexFilter,
  FilterOperator,
  JsonObjectSearchFilterVisitor,
  MARKETPLACE_PROJECTS_CONSUMABLE,
  MARKETPLACE_PROJECTS_DATA_LANDSCAPE,
  MARKETPLACE_PROJECTS_ENVIRONMENT,
  QueryOperator,
  WitboostMarketplaceSearchResultSet,
} from '@agilelab/plugin-wb-search-common';
import { useApolloClient } from '@apollo/client';
import { ErrorPanel } from '@backstage/core-components';
import {
  identityApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import { marketplaceSearchRef } from '../../../routes';
import { Loader } from '../CustomViews/Loader';
import { MarketplaceSearchDocumentEnrichedResultSet } from '../types';
import { enrichSearchResults, getTopReportIDs } from '../utils';
import { HomepageEmptyState } from './EmptyStates/HomepageEmptyState';
import { SearchFavoriteListItem } from './SearchFavoriteListItem';
import { SearchPageAutocomplete } from './SearchPageAutocomplete';

const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: theme.palette.white,
    },
    container: {
      marginTop: '10vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      rowGap: theme.spacing(1),
    },
    logoContainer: {
      width: '200px',
    },
    form: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    gridContainer: {
      marginTop: '8vh',
      display: 'grid',
      gridTemplateColumns: '32% 32% 32%',
      width: '100%',
      padding: '2rem',
      columnGap: '2%',
      [theme.breakpoints.down('md')]: {
        padding: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '100%',
        rowGap: '2rem',
      },
    },
    title: {
      marginBottom: '20px',
      fontSize: '2.2rem',
      fontWeight: 400,
    },
    widget: {
      width: '100%',
    },
  }),
  { name: 'WbSearchContent' },
);

export const SearchPageContent = () => {
  const [query, setQuery] = useState('');
  const classes = useStyles();
  const { starredEntities } = useStarredEntities();
  const [favorites, setFavorites] = useState<string[]>([]);

  const navigate = useNavigate();
  const marketplaceSearch = useRouteRef(marketplaceSearchRef);

  const searchApi = useApi(witboostSearchApiRef);
  const metricsApi = useApi(metricsApiRef);
  const identityApi = useApi(identityApiRef);
  const apolloClient = useApolloClient();
  const practiceShaperApi = useApi(practiceShaperApiRef);
  const { priorityEnvironment, setEnvironment } = useEnvironmentsContext();
  const { selectedTaxonomyRef } = useTaxonomySelection();

  const handleSubmit = (event: any, search?: string) => {
    if (event) event.preventDefault();
    const q = search ?? query;
    setEnvironment(priorityEnvironment);
    navigate({
      pathname: marketplaceSearch(),
      // we don't set the query in the query params if it's empty
      search: !!q.length
        ? createSearchParams({
            query: q,
          }).toString()
        : undefined,
    });
  };
  useEffect(() => {
    if ([...starredEntities].length) {
      setFavorites(
        [...starredEntities]
          .filter(el => el.startsWith('search:'))
          .map(el => el.replace('search:', '')),
      );
    }
  }, [starredEntities]);
  const metrics = useAsync(async () => {
    const getMetrics = async () => {
      const credentials = await identityApi.getCredentials();
      const result = await metricsApi.getReportDetails(
        { report_manager: 'UserStatsReportManager', group_by: 'user' },
        credentials,
      );
      return getTopReportIDs(result);
    };
    return getMetrics();
  }, [identityApi, metricsApi]);
  const { topByCount, topByTimestamp } = metrics.value ?? {};
  const enrich = async (resultSet: WitboostMarketplaceSearchResultSet) => {
    const enriched = await enrichSearchResults(
      resultSet.results,
      apolloClient,
      practiceShaperApi,
    );
    return enriched;
  };
  const result = useAsync(async () => {
    const getResults = async (filters: any) => {
      const res = (await searchApi.query({
        term: '',
        filters,
        types: [CollatorType.MARKETPLACE_PROJECTS],
        orderBy: { field: 'rank', order: 'desc' },
      })) as WitboostMarketplaceSearchResultSet;
      const enrichedResultSet: MarketplaceSearchDocumentEnrichedResultSet = {
        ...result,
        results: await enrich(res),
      };
      return enrichedResultSet;
    };
    const taxonomyFilter =
      selectedTaxonomyRef === ALL_TAXONOMIES_FILTER
        ? null
        : new AtomicFilter(
            MARKETPLACE_PROJECTS_DATA_LANDSCAPE.field,
            FilterOperator.IN,
            [entityRefToUrn(selectedTaxonomyRef)],
          );
    const complexFilter = new ComplexFilter(QueryOperator.AND, [
      new AtomicFilter('id', FilterOperator.IN, [
        ...new Set([
          ...favorites,
          ...(topByCount ?? []),
          ...(topByTimestamp ?? []),
        ]),
      ]),
      new AtomicFilter(
        MARKETPLACE_PROJECTS_ENVIRONMENT.field,
        FilterOperator.EQ,
        priorityEnvironment.name,
      ),
      new AtomicFilter(
        MARKETPLACE_PROJECTS_CONSUMABLE.field,
        FilterOperator.IN,
        [ConsumableMode.Consumable, ConsumableMode.HasConsumableChild],
      ),
      ...(taxonomyFilter ? [taxonomyFilter] : []),
    ]).accept(new JsonObjectSearchFilterVisitor());
    if (favorites.length || topByCount?.length || topByTimestamp?.length)
      return getResults(complexFilter);
    return {} as MarketplaceSearchDocumentEnrichedResultSet;
  }, [
    apolloClient,
    practiceShaperApi,
    searchApi,
    favorites,
    priorityEnvironment,
    metrics.value,
    topByCount,
    topByTimestamp,
    selectedTaxonomyRef,
  ]);

  const loading = result.loading || metrics.loading;
  const recentlyVisited = useMemo(() => {
    if (loading)
      return (
        <>
          <Loader />
          <Loader />
        </>
      );
    return result?.value?.results && topByTimestamp?.length ? (
      (() => {
        const filteredItems = topByTimestamp
          .slice(0, 5)
          .map(el => {
            const entity = result?.value?.results.find(
              e => e.document.id === el,
            );
            return entity ? (
              <SearchFavoriteListItem key={el} entity={entity} />
            ) : null;
          })
          .filter(Boolean);

        return filteredItems.length > 0 ? (
          filteredItems
            .filter(el => el !== null)
            .slice(0, 5)
            .map((el, i) => {
              return (
                el && (
                  <React.Fragment key={`${el.key}-recent`}>
                    {el}
                    {i < filteredItems.length - 1 && i < 4 && (
                      <WbDivider orientation="horizontal" />
                    )}
                  </React.Fragment>
                )
              );
            })
        ) : (
          <HomepageEmptyState kind="recent" />
        );
      })()
    ) : (
      <HomepageEmptyState kind="recent" />
    );
  }, [loading, result?.value?.results, topByTimestamp]);
  const topVisited = useMemo(() => {
    if (loading)
      return (
        <>
          <Loader />
          <Loader />
        </>
      );
    return result?.value?.results && topByCount?.length ? (
      (() => {
        const filteredItems = topByCount
          .slice(0, 5)
          .map(el => {
            const entity = result?.value?.results.find(
              e => e.document.id === el,
            );
            return entity ? (
              <SearchFavoriteListItem key={el} entity={entity} />
            ) : null;
          })
          .filter(Boolean);

        return filteredItems.length > 0 ? (
          filteredItems
            .filter(el => el !== null)
            .slice(0, 5)
            .map((el, i) => {
              return (
                el && (
                  <React.Fragment key={`${el.key}-topVisited`}>
                    {el}
                    {i < filteredItems.length - 1 && i < 4 && (
                      <WbDivider orientation="horizontal" />
                    )}
                  </React.Fragment>
                )
              );
            })
        ) : (
          <HomepageEmptyState kind="top" />
        );
      })()
    ) : (
      <HomepageEmptyState kind="top" />
    );
  }, [loading, result?.value?.results, topByCount]);
  const favoriteItems = useMemo(() => {
    if (result.loading)
      return (
        <>
          <Loader />
          <Loader />
        </>
      );
    return result?.value?.results && favorites.length ? (
      (() => {
        const filteredItems = favorites
          .map(el => {
            const entity = result?.value?.results.find(
              e => e.document.id === el,
            );
            return entity ? (
              <SearchFavoriteListItem key={el} entity={entity} />
            ) : null;
          })
          .filter(Boolean);

        return filteredItems.length > 0 ? (
          filteredItems
            .filter(el => el !== null)
            .slice(0, 5)
            .map((el, i) => {
              return (
                el && (
                  <React.Fragment key={`${el.key}-favorite`}>
                    {el}
                    {i < filteredItems.length - 1 && i < 4 && (
                      <WbDivider orientation="horizontal" />
                    )}
                  </React.Fragment>
                )
              );
            })
        ) : (
          <HomepageEmptyState kind="favorites" />
        );
      })()
    ) : (
      <HomepageEmptyState kind="favorites" />
    );
  }, [result.loading, result?.value?.results, favorites]);
  if (result.error || metrics.error)
    return <ErrorPanel error={(result.error || metrics.error) as Error} />;

  return (
    <Box className={classes.container}>
      <Box className={classes.logoContainer}>
        <Logo />
      </Box>
      <Typography variant="h4" className={classes.title}>
        Marketplace Search
      </Typography>
      <form onSubmit={handleSubmit} className={classes.form}>
        <SearchPageAutocomplete
          query={query}
          onChange={setQuery}
          handleSubmit={handleSubmit}
        />
      </form>
      <Box className={classes.gridContainer}>
        <WbCard title="Top Visited" cardClassName={classes.widget}>
          <Box style={{ padding: '0.5rem', flexGrow: 1 }}>{topVisited}</Box>
        </WbCard>
        <WbCard title="Recently Visited" cardClassName={classes.widget}>
          <Box style={{ padding: '0.5rem', flexGrow: 1 }}>
            {recentlyVisited}
          </Box>
        </WbCard>
        <WbCard title="Your Favorites" cardClassName={classes.widget}>
          <Box style={{ padding: '0.5rem', flexGrow: 1 }}>{favoriteItems}</Box>
        </WbCard>
      </Box>
    </Box>
  );
};
