/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Box, Theme, Typography, makeStyles } from '@material-ui/core';

import pluralize from 'pluralize';
import { camelToSpacedCase } from '@agilelab/plugin-wb-platform-common';
import {
  WbSelectInline,
  WbSelectInlineItem,
} from '@agilelab/plugin-wb-platform';
import { useGeneralPurposePickerContext } from '../GeneralPurposePickerContext';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

interface EntityKindSelectorProps {
  availableKinds: Record<string, any>[];
}

export function EntityKindSelector({
  availableKinds,
}: EntityKindSelectorProps) {
  const classes = useStyles();
  const { selectedKind, setSelectedKind } = useGeneralPurposePickerContext();

  // Before allKinds is loaded, or when a kind is entered manually in the URL, selectedKind may not
  // be present in allKinds. It should still be shown in the dropdown, but may not have the nice
  // enforced casing from the catalog-backend. This makes a key/value record for the Select options,
  // including selectedKind if it's unknown - but allows the selectedKind to get clobbered by the
  // more proper catalog kind if it exists.
  const kinds = availableKinds.map(kind => ({
    key: kind.displayName ?? kind.type,
    value: kind.type,
  }));

  return (
    <Box className={classes.container}>
      <Typography variant="body2" color="secondary">
        Entity type:
      </Typography>

      <WbSelectInline value={selectedKind} onChange={setSelectedKind}>
        {...kinds.map(i => (
          <WbSelectInlineItem {...i}>
            <Typography variant="body2">
              {camelToSpacedCase(pluralize(i.key), true)}
            </Typography>
          </WbSelectInlineItem>
        ))}
      </WbSelectInline>
    </Box>
  );
}
