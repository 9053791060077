import { ResponseError } from '@backstage/errors';
import fetch from 'cross-fetch';
import {
  GetUserParams,
  GetUsersParams,
  GuestUsersAuthApi,
  GuestUsersAuthOptions,
  GuestUsersAuthProviderInfo,
  UpsertUsersParams,
} from './guestUsersAuthApi';
import { SafeGuestUser, GuestUser } from './model';
import { DiscoveryApi } from '@backstage/core-plugin-api';

type SendRequestOptions = {
  path: string;
  method: 'POST' | 'GET' | 'DELETE';
  body?: BodyInit | null | undefined;
  token?: string;
};

export class GuestUsersAuthClient implements GuestUsersAuthApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly fetchApi: { fetch: typeof fetch } = { fetch },
  ) {}

  private composeQueryString(queryObject?: any): URLSearchParams {
    const queryString = new URLSearchParams();

    if (!queryObject) {
      return queryString;
    }
    Object.keys(queryObject).forEach(key => {
      if (queryObject[key]) {
        queryString.append(key, queryObject[key] as string);
      }
    });

    return queryString;
  }

  private async sendRequest<T>({
    path,
    method,
    token,
    body,
  }: SendRequestOptions): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'guest-users-auth',
    )}/`;
    const url = new URL(path, baseUrl);

    const response = await this.fetchApi.fetch(url.toString(), {
      method: method,
      headers: token
        ? {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        : { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: body,
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json() as Promise<T>;
  }

  async getProviderInfo(): Promise<GuestUsersAuthProviderInfo> {
    const providerInfo = await this.sendRequest<GuestUsersAuthProviderInfo>({
      path: 'info',
      method: 'GET',
    });

    return providerInfo;
  }
  async upsertUsers({
    users,
    options,
  }: UpsertUsersParams): Promise<GuestUser[]> {
    const usersResponse = await this.sendRequest<{
      users: GuestUser[];
    }>({
      path: 'guest-users',
      method: 'POST',
      body: JSON.stringify({ users }),
      token: options?.token,
    });

    return usersResponse.users;
  }

  async disableAll({ options }: GuestUsersAuthOptions): Promise<void> {
    await this.sendRequest({
      path: 'disable',
      method: 'POST',
      token: options?.token,
    });
  }

  async getUser({
    username,
    filter,
    options,
  }: GetUserParams): Promise<SafeGuestUser | undefined> {
    const queryString = this.composeQueryString(filter);
    try {
      const user = await this.sendRequest<SafeGuestUser>({
        path: `guest-users/${username}?${queryString}`,
        method: 'GET',
        token: options?.token,
      });

      return user;
    } catch (err: unknown) {
      if (err instanceof ResponseError) {
        if (err.statusCode === 404) return undefined;
      }
      throw err;
    }
  }

  async getUsers({ filter, options }: GetUsersParams): Promise<GuestUser[]> {
    const queryString = this.composeQueryString(filter);

    try {
      const { users } = await this.sendRequest<{ users: GuestUser[] }>({
        path: `guest-users?${queryString}`,
        method: 'GET',
        token: options?.token,
      });

      return users;
    } catch (err: unknown) {
      if (err instanceof ResponseError) {
        if (err.statusCode === 404) return [];
      }
      throw err;
    }
  }

  async refresh({ options }: GuestUsersAuthOptions): Promise<void> {
    await this.sendRequest({
      path: 'refresh',
      method: 'POST',
      token: options?.token,
    });
  }
}
