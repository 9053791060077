import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import {
  WbCardActionButton,
  WbTable,
  WbTableFilters,
  ConfirmDialog,
  TableCellProps,
} from '@agilelab/plugin-wb-platform';
import { useRolePermissionsTableContext } from './RolePermissionsTableContext';
import { PermissionEntity } from '@agilelab/plugin-wb-rbac-common';
import { ErrorPanel, Progress } from '@backstage/core-components';
import { useOverviewContext } from './OverviewContext';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles(
  theme => ({
    container: {
      height: '100%',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexBox: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    tableContainer: {
      flex: 1,
      overflowY: 'auto',
    },
  }),
  { name: 'RolePermissionsTable' },
);

export const DeleteConfirmDialog = () => {
  const {
    selectedDeleteItems,
    setSelectedDeleteItems,
    removePermissions,
    setOpenConfirmDialog,
    openConfirmDialog,
  } = useRolePermissionsTableContext();
  return (
    <ConfirmDialog
      title="Remove permission"
      description={`Are you sure you want to remove ${selectedDeleteItems.length} permissions from this role `}
      onConfirm={() => {
        removePermissions(selectedDeleteItems.map(i => i.id));
        setSelectedDeleteItems([]);
        setOpenConfirmDialog(false);
      }}
      open={openConfirmDialog}
      onClose={() => setOpenConfirmDialog(false)}
      confirmButtonText="Remove"
    />
  );
};

const RolePermissionActions: React.FC = () => {
  const {
    selectedDeleteItems,
    setOpenConfirmDialog,
    setOpenManageDrawer,
    permissionsLoaded,
    setSelectedPermissions,
  } = useRolePermissionsTableContext();
  const { canEditRolePermission } = useOverviewContext();

  return (
    <Box display="flex" style={{ gap: '8px' }} height="fit-content">
      {canEditRolePermission && (
        <>
          {!!selectedDeleteItems?.length ? (
            <WbCardActionButton
              label="Remove selected"
              startIcon={<CancelIcon />}
              onClick={() => setOpenConfirmDialog(true)}
              color="secondary"
              size="small"
              variant="outlined"
            />
          ) : (
            <WbCardActionButton
              label="Manage Permissions"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setOpenManageDrawer(true);
                setSelectedPermissions(
                  permissionsLoaded?.value?.permissions ?? [],
                );
              }}
              color="primary"
              size="small"
            />
          )}
        </>
      )}
    </Box>
  );
};

export const RolePermissionCardHeader: React.FC = () => {
  const classes = useStyles();
  const { selectedDeleteItems, setSelectedDeleteItems, filters, setFilters } =
    useRolePermissionsTableContext();

  return (
    <Box className={classes.header}>
      <Box className={classes.flexBox}>
        <WbTableFilters
          style={{ marginBottom: '0' }}
          searchValue={filters.text}
          onSearch={search => {
            setFilters({ text: search });
          }}
          onClear={() => setFilters({})}
        />
        {selectedDeleteItems && selectedDeleteItems?.length > 0 && (
          <Box className={classes.flexBox}>
            <Typography variant="body2" style={{ fontWeight: 'medium' }}>
              {selectedDeleteItems.length} Permissions selected
            </Typography>
            <WbCardActionButton
              variant="text"
              label="Clear selection"
              onClick={() => setSelectedDeleteItems([])}
            />
          </Box>
        )}
      </Box>
      <RolePermissionActions />
    </Box>
  );
};

export const RolePermissionsTable: React.FC = ({}) => {
  const classes = useStyles();
  const {
    defaultColumns,
    permissionsLoaded,
    loadPermissions,
    selectedDeleteItems,
    setSelectedDeleteItems,
    setOpenConfirmDialog,
  } = useRolePermissionsTableContext();

  useEffect(() => {
    loadPermissions();
  }, [loadPermissions]);

  const columns = useMemo(() => {
    return [
      ...defaultColumns,
      {
        field: 'id',
        tooltip: true,

        cellProps: {
          size: 'small',
          align: 'right',
          width: '5%',
        },
        fieldRender: field => {
          return (
            <IconButton
              onClick={() => {
                setSelectedDeleteItems([field]);
                setOpenConfirmDialog?.(true);
              }}
            >
              <CancelIcon />
            </IconButton>
          );
        },
        headerName: '',
      } as TableCellProps<PermissionEntity>,
    ];
  }, [defaultColumns, setOpenConfirmDialog, setSelectedDeleteItems]);

  if (permissionsLoaded.error)
    return (
      <ErrorPanel
        error={
          permissionsLoaded.error instanceof Error
            ? permissionsLoaded.error
            : new Error('Something went wrong')
        }
      />
    );
  const getRowId = (r: PermissionEntity) => r.id;

  return (
    <WbTable<PermissionEntity>
      selection="multiple"
      stickyHeader
      classNames={{ container: classes.tableContainer }}
      selectedItems={selectedDeleteItems}
      setSelectedItems={setSelectedDeleteItems}
      components={{
        tableLoader: {
          loading: permissionsLoaded.loading,
          loader: <Progress />,
        },
        tableContent: {
          getRowId,
          columns,
          rows: permissionsLoaded.value?.permissions,
        },
      }}
    />
  );
};
