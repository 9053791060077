import { Config } from '@backstage/config';
import fetch from 'cross-fetch';
import _ from 'lodash';
import { AutocompleteOption, FetchOptionsResponse } from './types';
import hashObject from 'object-hash';

export enum CustomUrlOperations {
  VALIDATION = 'validation',
  RETRIEVAL = 'retrieval',
}

/**
 * An interface representing the Microservice configuration
 */
export interface MicroserviceConfig {
  microserviceId: string;
  baseUrl: string;
  retrievalPath?: string;
  validationPath?: string;
  retrievalMethod?: string;
  validationMethod?: string;
  apiKey?: string;
}

/**
 * This function enables to extract dynamic fields, i.e. fields that are identified by some special characters "${{ }}".
 * It extracts the values that are inside the above special characters and resolve them by using the formContext.
 * @param formContext
 * @param params
 * @returns
 */
export function parseDynamicFields(
  formContext: any,
  prevFormContext: any,
  resetValues: (value: string[]) => void,
  setEnableSearch: (value: boolean) => void,
  setOffset: (value: number) => void,
  setMessages?: React.Dispatch<
    React.SetStateAction<
      Map<string, { line: string; severity: 'info' | 'warning' | 'error' }>
    >
  >,
  params?: any,
): any | undefined {
  const dynamicFieldChecker = /^\${1}\{{2}\s*[a-zA-Z0-9_\-]+\s*\}{2}$/g;

  const dynamicFieldExtractor = /[\$\{\}\s]/g;

  if (Array.isArray(params)) {
    return params
      .map(value =>
        parseDynamicFields(
          formContext,
          prevFormContext,
          resetValues,
          setEnableSearch,
          setOffset,
          setMessages,
          value,
        ),
      )
      .filter(val => val !== undefined);
  }

  if (typeof params === 'string' && dynamicFieldChecker.test(params)) {
    const fieldName = params.replaceAll(dynamicFieldExtractor, '');
    if (fieldName) {
      const prevFieldNameValue = _.get(prevFormContext, fieldName);
      const currFieldNameValue = _.get(formContext, fieldName);
      if (!_.isEqual(prevFieldNameValue, currFieldNameValue)) {
        setOffset(0);
        setEnableSearch(true);
        resetValues([]);
      }

      if (setMessages) {
        if (!currFieldNameValue) {
          setMessages(prevErrors =>
            prevErrors.set(fieldName, {
              line: `There is a dependency to the field "${fieldName}". Remember to fill it before going to the next step.`,
              severity: 'warning',
            }),
          );
        } else {
          setMessages(prevErrors => {
            prevErrors.delete(fieldName);
            return prevErrors;
          });
        }
      }

      return currFieldNameValue;
    }

    return params;
  }

  if (typeof params === 'object') {
    return Object.entries(params)
      .map(([field, value]) => [
        field,
        parseDynamicFields(
          formContext,
          prevFormContext,
          resetValues,
          setEnableSearch,
          setOffset,
          setMessages,
          value,
        ),
      ])
      .reduce(
        (accumulator, [field, value]) => ({ ...accumulator, [field]: value }),
        {},
      );
  }

  return params;
}

/**
 * This method validates the input UI schema for the Custom URL Picker
 * @param uiSchema
 * @returns
 */
export function validateUiSchema(uiSchema: any) {
  if (!uiSchema['ui:apiSpec']) {
    throw new Error(
      "'ui:apiSpec' property must be defined. See the docs if you want additional info.",
    );
  }

  if (!uiSchema['ui:apiSpec'].retrieval) {
    throw new Error(
      "'retrieval' property should be configured under 'ui:apiSpec' property. See the docs if you want additional info.",
    );
  }

  if (!uiSchema['ui:options']?.selectedField) {
    throw new Error(
      "'selectedField' should be defined under 'ui:options' property.",
    );
  }

  if (!uiSchema['ui:displayFields']) {
    throw new Error(
      "'api:displayFields' property must be defined for CustomUrlPicker. See the docs if you want additional info.",
    );
  }

  if (!uiSchema['ui:apiSpec'].validation) {
    throw new Error(
      `'ui:apiSpec.validation' property must be defined for CustomUrlPicker. See the docs if you want additional info.`,
    );
  }

  if (!uiSchema['ui:apiSpec'].validation.microserviceId) {
    throw new Error(
      `'ui:apiSpec.validation.microserviceId' property must be defined for CustomUrlPicker. See the docs if you want additional info.`,
    );
  }

  if (uiSchema['ui:fieldsToSave']) {
    const intersection = uiSchema['ui:displayFields'].filter((field: string) =>
      uiSchema['ui:fieldsToSave'].includes(field),
    );

    if (intersection.length !== uiSchema['ui:displayFields'].length) {
      throw new Error(
        `'ui:displayFields' is not a sub-set of 'ui:fieldsToSave'. This may cause some problems in the visualization of the results in the drop-down list.`,
      );
    }
  }

  if (
    !uiSchema['ui:displayFields'].includes(
      uiSchema['ui:options']?.selectedField,
    )
  ) {
    throw new Error(
      `'ui:options'.selectedField is not included in the 'ui:displayFields' parameter. Please, choose as 'selectedField' a field that is contained also in the 'displayFields' parameter.`,
    );
  }

  return uiSchema;
}

/**
 * It checks if there is a configuration inside Witboost that has the same microserviceId of the one
 * inside the template.yaml file. If true, it takes those configurations as default values. If the user
 * specifies the 'url' and 'method' fields in the template.yaml, these lasts will take priority over configuration.
 * @param config
 * @param validatedUiSchema
 * @returns
 */
export function parseConfiguration(
  config: Config | undefined,
  validatedUiSchema: any,
  operation: CustomUrlOperations,
): MicroserviceConfig {
  if (!config) {
    throw new Error('Unable to find configuration instance.');
  }

  const microservicesConfig = config.getOptional<MicroserviceConfig[]>(
    'mesh.builder.scaffolder.microserviceConfiguration',
  );

  if (!microservicesConfig) {
    throw new Error(
      "The configuration 'mesh.builder.scaffolder.microserviceConfiguration' is not found",
    );
  }

  let apiSpec = validatedUiSchema['ui:apiSpec']?.retrieval;

  if (operation === CustomUrlOperations.VALIDATION) {
    apiSpec = validatedUiSchema['ui:apiSpec']?.validation;
  }

  const foundedMicroservice = microservicesConfig.find(
    conf => conf.microserviceId === apiSpec.microserviceId,
  );

  if (!foundedMicroservice) {
    throw new Error(
      `Unable to find the microservice specification for "${apiSpec.microserviceId}"`,
    );
  }

  return {
    ...foundedMicroservice,
    baseUrl: apiSpec.baseUrl ?? foundedMicroservice.baseUrl,
    retrievalPath:
      apiSpec.path ?? foundedMicroservice.retrievalPath ?? '/v1/resources',
    retrievalMethod:
      apiSpec.method ?? foundedMicroservice.retrievalMethod ?? 'POST',
    validationPath:
      apiSpec.path ??
      foundedMicroservice.validationPath ??
      '/v1/resources/validate',
    validationMethod:
      apiSpec.method ?? foundedMicroservice.validationMethod ?? 'POST',
  };
}

/**
 * It performs an HTTP call to a Microservice which specifications are made available in the "microserviceConfig" parameter
 * and by passing "params" in the body of the request. It returns a list of objects filtered by "filter" corresponding to the offset "offset".
 * @param microserviceConfig: the specification of the microservice
 * @param setMessages a function used to return errors to the caller
 * @param offset the offset of the call
 * @param params it contains the body of request and also the "limit" parameter to pass to the request
 * @param filter a string used to filter results
 * @returns
 */
export async function fetchMicroserviceValues(
  microserviceConfig: MicroserviceConfig,
  offset: number,
  params?: any,
  filter?: string,
): Promise<FetchOptionsResponse> {
  const queryParams = filter ? `&filter=${filter}` : '';

  const { limit, ...restParams } = params;
  const xApiKeyHeader = microserviceConfig.apiKey
    ? { 'X-API-Key': microserviceConfig.apiKey }
    : undefined;
  const response = await fetch(
    `${microserviceConfig.baseUrl}${
      microserviceConfig.retrievalPath
    }?offset=${offset}&limit=${limit ?? 5}${queryParams}`,
    {
      method: microserviceConfig.retrievalMethod,
      headers: {
        'Content-Type': 'application/json',
        ...xApiKeyHeader,
      },
      body: JSON.stringify(restParams),
    },
  );

  if (!response.ok) {
    return {
      ok: false,
      options: [],
    };
  }

  return {
    ok: true,
    options: await response.json(),
  };
}

/**
 * It performs multiple HTTP calls to a Microservice which specifications are made available in the "microserviceConfig" parameter
 * and by passing "params" in the body of the request.
 * The requests are made with an incremental offset and a predetermined limit(it overrides the ones in the params if present), until a response with 0 elements is met (it means all values are fetched)
 * @param microserviceConfig: the specification of the microservice
 * @param params it contains the body of request and also the "limit" parameter to pass to the request
 * @returns
 */
export async function fetchAllMicroserviceValues(
  microserviceConfig: MicroserviceConfig,
  params?: any,
): Promise<AutocompleteOption[]> {
  const total: AutocompleteOption[] = [];
  const LIMIT = 1000;
  let currentOffset = 0;
  let fetchMore = true;
  while (fetchMore) {
    const response = await fetchMicroserviceValues(
      microserviceConfig,
      currentOffset,
      { ...params, limit: LIMIT },
    );
    currentOffset++;
    if (response.ok) total.push(...response.options);
    if (!response.options.length) {
      fetchMore = false;
    }
  }
  return total;
}

export function getApiConfigId(
  microserviceId: string,
  params?: Record<string, any> & { limit?: number },
) {
  const filteredParams = _.cloneDeep(params ?? {});
  // we don't want limit to affect the hash, we will fetch all the options anyway
  if (filteredParams.limit) delete filteredParams.limit;
  return hashObject({ params: filteredParams, microserviceId });
}
