import {
  createExternalRouteRef,
  createRouteRef,
} from '@backstage/core-plugin-api';

export const catalogRootRouteRef = createExternalRouteRef({
  id: 'catalog',
});
export const templatesRouteRef = createExternalRouteRef({
  id: 'templates',
});

export const rootRouteRef = createRouteRef({
  id: 'wb-catalog-import',
});
