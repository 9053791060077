import {
  IdentityApi,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { practiceShaperApiRef } from '../../../../plugin';
import {
  CATALOG_FILTER_EXISTS,
  CatalogApi,
  catalogApiRef,
} from '@backstage/plugin-catalog-react';
import { ResourceType } from '../../types';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { PracticeShaperApi } from '@agilelab/plugin-wb-practice-shaper-common';
import { parseEntityRef } from '@backstage/catalog-model';
import { ComponentType, SystemType } from '@agilelab/plugin-wb-builder-common';

const detectRegisteredResourceTypes = async (
  catalogApi: CatalogApi,
  kind: 'System' | 'Component',
) => {
  // How many times a given spec.type appears in a descriptor having spec.mesh.kind defined
  const typesWithMeshKindCount = new Map(
    (
      await catalogApi.getEntityFacets({
        facets: ['spec.type'],
        filter: [{ kind, 'spec.mesh.kind': CATALOG_FILTER_EXISTS }],
      })
    ).facets['spec.type'].map(obj => [obj.value, obj.count]),
  );

  // spec.type that never appear in a descriptor having spec.mesh.kind defined
  const typesWithoutMeshKind = (
    await catalogApi.getEntityFacets({
      facets: ['spec.type'],
      filter: [{ kind }],
    })
  ).facets['spec.type'].flatMap(
    type =>
      type.count > (typesWithMeshKindCount.get(type.value) ?? 0)
        ? [type.value]
        : [], // this spec.type is always associated to a spec.mesh.kind. Let's discard it as it never appears in a final descriptor.
  );

  // spec.mesh.kind distinc values
  const meshKinds = (
    await catalogApi.getEntityFacets({
      facets: ['spec.mesh.kind'],
      filter: [{ kind }],
    })
  ).facets['spec.mesh.kind'].map(v => v.value);

  return new Set([...typesWithoutMeshKind, ...meshKinds]);
};

const fetchResourceTypesFn = async (
  catalogApi: CatalogApi,
  practiceShaper: PracticeShaperApi,
  identityApi: IdentityApi,
) => {
  // System types tracked by the practice shaper
  const { items: systemTypes } = await practiceShaper.getSystemTypes(
    {},
    await identityApi.getCredentials(),
  );

  const resourceTypeToSystemType = systemTypes.reduce(
    (typesMap, systemType) => {
      typesMap.set(systemType.spec.resourceTypeId ?? '', systemType);
      return typesMap;
    },
    new Map<string, SystemType>(),
  );

  // System types tracked by the practice shaper
  const { items: componentTypes } = await practiceShaper.getComponentTypes({});

  const resourceTypeToComponentType = componentTypes.reduce(
    (typesMap, componentType) => {
      typesMap.set(componentType.spec.resourceTypeId ?? '', componentType);
      return typesMap;
    },
    new Map<string, ComponentType>(),
  );

  // All the system types detected looking at the instances
  const registeredSystemTypes = await detectRegisteredResourceTypes(
    catalogApi,
    'System',
  );

  // All the component types detectted looking at the instances
  const registeredComponentTypes = await detectRegisteredResourceTypes(
    catalogApi,
    'Component',
  );

  // Types referenced by a template but that have never been instantiated
  // We consider them as component types (assumption)
  const templatesComponentTypes = (
    await catalogApi.getEntityFacets({
      facets: ['spec.type'],
      filter: [{ kind: 'Template' }],
    })
  ).facets['spec.type'].flatMap(type =>
    !registeredSystemTypes.has(type.value) &&
    !registeredComponentTypes.has(type.value)
      ? [type.value]
      : [],
  );

  const detectedSystemTypes = [...registeredSystemTypes].map(resourceType => {
    const referenceEntity = resourceTypeToSystemType.get(resourceType);
    const entityRef =
      referenceEntity &&
      parseEntityRef({
        kind: referenceEntity.kind,
        namespace: referenceEntity.metadata.namespace,
        name: referenceEntity.metadata.name,
      });
    return {
      name: resourceType,
      kind: 'system',
      referencedBy: entityRef,
    } as ResourceType;
  });

  const detectedComponentTypes = [
    ...registeredComponentTypes,
    ...templatesComponentTypes,
  ].map(resourceType => {
    const referenceEntity = resourceTypeToComponentType.get(resourceType);
    const entityRef =
      referenceEntity &&
      parseEntityRef({
        kind: referenceEntity.kind,
        namespace: referenceEntity.metadata.namespace,
        name: referenceEntity.metadata.name,
      });
    return {
      name: resourceType,
      kind: 'component',
      referencedBy: entityRef,
    } as ResourceType;
  });

  return [...detectedSystemTypes, ...detectedComponentTypes];
};

export const useFetchResourceTypes = () => {
  const practiceShaperApi = useApi(practiceShaperApiRef);
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const [fetchResourceTypesState, fetchResourceTypes] = useAsyncFn(
    () => fetchResourceTypesFn(catalogApi, practiceShaperApi, identityApi),
    [catalogApi, practiceShaperApi],
  );
  return {
    fetchResourceTypes,
    fetchResourceTypesState,
  };
};
