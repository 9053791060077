import { createContext } from 'react';

const drawerWidthClosed = 60;
const iconPadding = 12;
const userBadgePadding = 20;
const itemSidePadding = 8;
const itemHeight = 32;

/** @public */
export type SidebarOptions = {
  drawerWidthClosed?: number;
  drawerWidthOpen?: number;
};

/** @public */
export type SubmenuOptions = {
  drawerWidthClosed?: number;
  drawerWidthOpen?: number;
};

/** @internal */
export type SidebarConfig = {
  drawerWidthClosed: number;
  drawerWidthOpen: number;
  itemSidePadding: number;
  defaultOpenDelayMs: number;
  defaultCloseDelayMs: number;
  defaultFadeDuration: number;
  logoHeight: number;
  iconContainerWidth: number;
  iconSize: number;
  iconPadding: number;
  userBadgePadding: number;
  userBadgeDiameter: number;
  itemHeight: number;
};

export const sidebarConfig = {
  itemSidePadding,
  drawerWidthClosed,
  drawerWidthOpen: 228,
  defaultOpenDelayMs: 100,
  defaultCloseDelayMs: 0,
  defaultFadeDuration: 200,
  logoHeight: 32,
  iconContainerWidth: drawerWidthClosed - itemSidePadding * 2,
  iconSize: drawerWidthClosed - iconPadding * 2,
  iconPadding,
  userBadgePadding,
  userBadgeDiameter: drawerWidthClosed - userBadgePadding * 2,
  itemHeight,
};

export const makeSidebarConfig = (
  customSidebarConfig: Partial<SidebarOptions>,
) => ({
  ...sidebarConfig,
  ...customSidebarConfig,
});

/** @internal */
export type SubmenuConfig = {
  drawerWidthClosed: number;
  drawerWidthOpen: number;
  defaultOpenDelayMs: number;
};

export const submenuConfig = {
  drawerWidthClosed: 0,
  drawerWidthOpen: 228,
  defaultOpenDelayMs: 0,
};

export const makeSidebarSubmenuConfig = (
  customSubmenuConfig: Partial<SubmenuOptions>,
) => ({
  ...submenuConfig,
  ...customSubmenuConfig,
});

export type SidebarConfigContextType = {
  sidebarConfig: SidebarConfig;
  submenuConfig: SubmenuConfig;
};

export const WbSidebarConfigContext = createContext<SidebarConfigContextType>({
  sidebarConfig,
  submenuConfig,
});

export enum SubmenuModeEnum {
  overlay = 'overlay',
  side = 'side',
}

export type WbSidebarItemWithSubmenuContextType = {
  isSubmenuOpen: boolean;
  submenuMode: SubmenuModeEnum;
  level?: number;
};

export const WbSidebarItemWithSubmenuContext =
  createContext<WbSidebarItemWithSubmenuContextType>({
    submenuMode: SubmenuModeEnum.side,
    isSubmenuOpen: false,
  });
