import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Dot } from './Dot';

type DotsProps = {
  n: number;
  active: number;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      gap: theme.spacing(1),
    },
  }),
  { name: 'Dots' },
);

export const Dots: React.FC<DotsProps> = ({ n, active }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {[...Array(n).keys()].map((_, i) => (
        <Dot active={active === i} key={i} />
      ))}
    </Box>
  );
};
