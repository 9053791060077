import React, { useCallback, useMemo, useState } from 'react';
import { Divider, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircle';
import { WbWidget, WbCardActionButton } from '@agilelab/plugin-wb-platform';
import {
  useWittyTable,
  wittyAutocompleteApiRef,
  useWittyFormContext,
  WittyTableContextProvider,
  isWittyMultiSelectFieldSubscription,
  AiGenerateActionButton,
} from '@agilelab/plugin-wb-witty-react';
import { useApi, alertApiRef, configApiRef } from '@backstage/core-plugin-api';
import { ArrayFieldTemplateProps, IChangeEvent } from '@rjsf/core';
import { scaffolderPlugin } from '../../../plugin';
import { createScaffolderLayout } from '../../../layouts';
import { ArrayTableContent } from '../ArrayFieldTemplate/ArrayTableTemplate';
import { getValue, upsertValues } from './fieldWriter';
import { JSONSchema7 } from 'json-schema';
import { WittySuggestFieldRequest } from '@agilelab/plugin-wb-witty-common';
import {
  WittyTableTemplateConfiguration,
  WittyTableTemplateConfigurationZod,
} from './types';
import { handleWittyError } from '@agilelab/plugin-wb-witty-react';
import { WittyNoContextTooltip } from '@agilelab/plugin-wb-witty-react';

const DEFAULT_MAX_ROWS_PER_CHUNK = 20;

export type WittyTableTemplateProps = ArrayFieldTemplateProps & {
  schema: JSONSchema7 & { witty: WittyTableTemplateConfiguration };
};

// returns a set of optionsSetIds for multi-select subscriptions
const getSubscribedOptionsSetIds = (wittyTable: any): Set<string> =>
  new Set(
    wittyTable.wittyFields
      .filter(isWittyMultiSelectFieldSubscription)
      .map((s: any) => s.optionsSetId),
  );

// converts table fields into an array of suggestion field objects
const buildSuggestFields = (wittyTable: any): WittySuggestFieldRequest[] =>
  wittyTable.wittyFields.map((sub: any) =>
    sub.type === 'text'
      ? { path: sub.fieldId, type: 'text' }
      : {
          path: sub.fieldId,
          type: 'multiselect',
          optionsSet: sub.optionsSetId,
        },
  );

// groups suggestion fields by “row”, using the substring of the fieldId up to the last underscore
const groupSuggestFieldsByRow = (
  wittyTable: any,
  suggestFields: WittySuggestFieldRequest[],
) => {
  const rowsMap: Record<string, WittySuggestFieldRequest[]> = {};
  const rowOrder: string[] = [];

  wittyTable.wittyFields.forEach((sub: any) => {
    const lastUnderscore = sub.fieldId.lastIndexOf('_');
    const rowKey = sub.fieldId.substring(0, lastUnderscore);
    if (!rowsMap[rowKey]) {
      rowsMap[rowKey] = [];
      rowOrder.push(rowKey);
    }
  });

  suggestFields.forEach(field => {
    const lastUnderscore = field.path.lastIndexOf('_');
    const rowKey = field.path.substring(0, lastUnderscore);
    if (!rowsMap[rowKey]) {
      rowsMap[rowKey] = [];
      rowOrder.push(rowKey);
    }
    rowsMap[rowKey].push(field);
  });

  return { rowsMap, rowOrder };
};

// splits the rows (as defined by rowOrder and rowsMap) into chunks,
// with each chunk containing at most `maxRowsPerChunk` rows
const createRowChunks = (
  rowsMap: Record<string, WittySuggestFieldRequest[]>,
  rowOrder: string[],
  maxRowsPerChunk: number,
): WittySuggestFieldRequest[][] => {
  const rowChunks: WittySuggestFieldRequest[][] = [];
  for (let i = 0; i < rowOrder.length; i += maxRowsPerChunk) {
    const rowsInChunk = rowOrder.slice(i, i + maxRowsPerChunk);
    const chunkFields = rowsInChunk.reduce(
      (acc: WittySuggestFieldRequest[], rowKey: string) =>
        acc.concat(rowsMap[rowKey]),
      [],
    );
    rowChunks.push(chunkFields);
  }
  return rowChunks;
};

export const WittyTableTemplate = (props: WittyTableTemplateProps) => {
  const wittyTable = useWittyTable();
  const wittyApi = useApi(wittyAutocompleteApiRef);
  const wittyForm = useWittyFormContext();
  const alertApi = useApi(alertApiRef);
  const configApi = useApi(configApiRef);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [overrideWarningOpen, setOverrideWarningOpen] = useState(false);

  // tells whether the user inserted some values into witty-enabled fields that are subscribed to this table
  const shouldOverride = useMemo(() => {
    return wittyTable?.wittyFields.some(sub => {
      const value = getValue(wittyForm?.formData ?? {}, sub.fieldId);
      return (
        value !== null &&
        value !== '' &&
        value?.length !== 0 &&
        Object.keys(value).length > 0
      );
    });
  }, [wittyTable?.wittyFields, wittyForm?.formData]);

  const wittyEnabledFieldsCount = useMemo(
    () => wittyTable?.wittyFields.length ?? 0,
    [wittyTable?.wittyFields],
  );

  const wittyEnabled = useMemo(
    () =>
      configApi.getOptionalBoolean(
        'witty.scaffolder.smartSuggestions.enabled',
      ) ?? false,
    [configApi],
  );

  const buttonDisabled = useMemo(
    () => loading || wittyEnabledFieldsCount === 0,
    [loading, wittyEnabledFieldsCount],
  );

  const updateUI = useCallback(
    (accumulatedSuggestions: { [key: string]: any }) => {
      const updatedFormData = upsertValues(
        wittyForm?.formData ?? {},
        Object.entries(accumulatedSuggestions).map(([path, value]) => ({
          path,
          value,
        })),
      );
      wittyForm?.onChange({
        formData: updatedFormData,
      } as unknown as IChangeEvent);

      // notify multi-select fields.
      wittyTable?.wittyFields.forEach((sub: any) => {
        if (sub.type === 'text') return;
        if (accumulatedSuggestions[sub.fieldId] !== undefined) {
          sub.notify(
            accumulatedSuggestions[sub.fieldId],
            accumulatedSuggestions[sub.fieldId],
          );
        }
      });
    },
    [wittyForm, wittyTable],
  );

  const processChunk = useCallback(
    (
      chunk: WittySuggestFieldRequest[],
      optionsSets: any,
      accumulatedSuggestions: { [key: string]: any },
    ) => {
      if (!wittyTable) throw new Error('Not in a table context');
      return wittyApi
        .autocomplete({
          tablePath: wittyTable?.tablePath,
          formData: { schema: props.formData },
          suggestFields: chunk,
          optionsSets,
          additionalContext: wittyTable?.additionalContext,
        })
        .then((response: any) => {
          Object.assign(accumulatedSuggestions, response.suggestions);
          updateUI(accumulatedSuggestions);
        });
    },
    [wittyApi, wittyTable, props.formData, updateUI],
  );

  // memoize derived values so that they are recomputed only when dependencies change.
  const subscribedOptionsSetIds = useMemo(
    () => getSubscribedOptionsSetIds(wittyTable),
    [wittyTable],
  );
  const allSuggestFields = useMemo(
    () => buildSuggestFields(wittyTable),
    [wittyTable],
  );
  const { rowsMap, rowOrder } = useMemo(
    () => groupSuggestFieldsByRow(wittyTable, allSuggestFields),
    [wittyTable, allSuggestFields],
  );

  const rowChunks = useMemo(
    () =>
      createRowChunks(
        rowsMap,
        rowOrder,
        wittyTable?.maxRowsPerChunk ?? DEFAULT_MAX_ROWS_PER_CHUNK,
      ),
    [rowsMap, rowOrder, wittyTable?.maxRowsPerChunk],
  );

  const suggest = useCallback(async () => {
    setLoading(true);
    try {
      if (!wittyTable) throw new Error('Not in a table context');

      // retrieve the necessary options sets.
      const optionsSets = await wittyTable.getOptionsSets(
        Array.from(subscribedOptionsSetIds),
      );

      // accumulate suggestions as each chunk is processed.
      const accumulatedSuggestions: { [key: string]: any } = {};
      const promises = rowChunks.map(chunk =>
        processChunk(chunk, optionsSets, accumulatedSuggestions),
      );

      // wait for all chunk requests to finish.
      await Promise.all(promises);
      alertApi.post({
        message: `Autocomplete done!`,
        severity: 'success',
      });
    } catch (error: any) {
      handleWittyError(error, alertApi);
    } finally {
      setLoading(false);
    }
  }, [wittyTable, subscribedOptionsSetIds, rowChunks, alertApi, processChunk]);

  // handle the user clicking the "Autocomplete" button
  const handleSuggestClick = useCallback(() => {
    // if we detect the user already has some data => open the override warning
    if (shouldOverride) {
      setOverrideWarningOpen(true);
    } else {
      // otherwise, just call suggest
      suggest();
    }
  }, [shouldOverride, suggest]);

  const handleOverrideConfirm = useCallback(() => {
    setOverrideWarningOpen(false);
    suggest();
  }, [suggest]);

  const handleOverrideCancel = useCallback(() => {
    setOverrideWarningOpen(false);
  }, []);

  return (
    <WbWidget
      title={props.uiSchema['ui:title'] || props.title}
      cardStyle={{ borderBottom: props.items.length > 0 ? 'none' : 'auto' }}
      actions={
        <>
          {wittyEnabled && (
            <WittyNoContextTooltip
              triggeringColumns={wittyTable?.triggeringColumns ?? []}
              hide={!buttonDisabled}
            >
              <span
              // disabled elements won't fire mouse events so we use a span
              >
                <AiGenerateActionButton
                  style={{ marginRight: theme.spacing(1) }}
                  loading={loading}
                  disabled={buttonDisabled}
                  onClick={handleSuggestClick}
                />
              </span>
            </WittyNoContextTooltip>
          )}
          {props.canAdd ? (
            <WbCardActionButton
              label="Add"
              disabled={!props.canAdd}
              icon={<AddIcon />}
              onClick={props.onAddClick}
              color="secondary"
            />
          ) : undefined}
        </>
      }
    >
      <ArrayTableContent {...props} disabled={loading || props.disabled} />
      <Dialog open={overrideWarningOpen} onClose={handleOverrideCancel}>
        <DialogTitle style={{ backgroundColor: theme.palette.bkg.primary }}>
          Override Warning
        </DialogTitle>
        <DialogContent>
          <strong>Override values?</strong>
          <br />
          Generating AI autocomplete will override already entered values in
          Witty-aware fields.
          <br />
          <strong>This action can't be undone.</strong>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleOverrideCancel}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleOverrideConfirm}
            variant="contained"
            color="secondary"
          >
            Override
          </Button>
        </DialogActions>
      </Dialog>
    </WbWidget>
  );
};

export const WithContextWittyTableTemplate = (
  props: WittyTableTemplateProps,
) => {
  const alertApi = useApi(alertApiRef);

  const tableConfig = useMemo(() => {
    const parsedConfig = WittyTableTemplateConfigurationZod.safeParse(
      props.schema,
    );

    if (parsedConfig.success === false) {
      alertApi.post({
        message: `Witty Table configuration error: ${parsedConfig.error.message}`,
        severity: 'error',
      });
      return null;
    }

    return parsedConfig.data;
  }, [props.schema, alertApi]);

  const additionalContext: string[] = useMemo(() => {
    if (!tableConfig) {
      return [];
    }

    return tableConfig.context ?? [];
  }, [tableConfig]);

  const triggeringColumns: { columnName: string; columnKey: string }[] =
    useMemo(() => {
      const schemaItems = props.schema.items as Record<string, any>; // injured typescript devs after reading this line: 13
      // key is column key, value is column title
      const allColumnsMap: Record<
        string,
        { columnName: string; columnKey: string }
      > = Object.entries(schemaItems.properties).reduce((acc, [key, value]) => {
        acc[key] = {
          columnName: ((value as Record<string, any>).title as string) ?? key,
          columnKey: key,
        };
        return acc;
      }, {} as Record<string, { columnName: string; columnKey: string }>);

      const allColumns = Object.values(allColumnsMap);
      if (allColumns.length === 0) {
        alertApi.post({
          message: `Witty Table configuration error: No columns found in the schema.`,
          severity: 'error',
        });
        return [];
      }
      const defaultColumn = allColumns[0];

      if (!tableConfig) {
        // by default let's take the first column
        return [defaultColumn];
      }

      const validatedTriggeringColumns =
        tableConfig.triggeringColumns?.flatMap(col => {
          const column = allColumnsMap[col];
          if (!column) {
            return [];
          }
          return [column];
        }) ?? [];

      if (validatedTriggeringColumns.length === 0) {
        return [defaultColumn];
      }
      return validatedTriggeringColumns;
    }, [alertApi, props.schema.items, tableConfig]);

  /**
   * The maximum number of rows to process in a single chunk.
   * This is a configurable parameter, with a default value of 20.
   * It can be set in the schema under the `witty.maxRowsPerChunk` key.
   */
  const maxRowsPerChunk = useMemo(() => {
    if (!tableConfig) {
      return DEFAULT_MAX_ROWS_PER_CHUNK;
    }

    return tableConfig.maxRowsPerChunk ?? DEFAULT_MAX_ROWS_PER_CHUNK;
  }, [tableConfig]);

  return (
    <WittyTableContextProvider
      additionalContext={additionalContext}
      tablePath={props.idSchema.$id}
      rowsNumber={props.items.length}
      triggeringColumns={triggeringColumns}
      maxRowsPerChunk={maxRowsPerChunk}
    >
      <WittyTableTemplate {...props} />
    </WittyTableContextProvider>
  );
};

export const WittyTableTemplateLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'WittyTableTemplate',
    component: WithContextWittyTableTemplate as any,
  }),
);
