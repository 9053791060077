import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { WbBadge } from '../WbBadge';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';

type WbEnabledBadgeProps = {
  className?: string;
  style?: React.CSSProperties;
  enabled: boolean;
};

const useStyles = makeStyles(
  theme => ({
    statusBadge: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: theme.spacing(0.75),
      fontWeight: 400,
      color: theme.palette.black,
      paddingBlock: theme.spacing(0.25),
      paddingInline: theme.spacing(0.75),
    },
    enabledStatus: {
      backgroundColor: theme.palette.success.light,
    },
    disabledStatus: {
      backgroundColor: theme.palette.blueGrey['100'],
    },
  }),
  { name: 'WbEnabledBadge' },
);

export const WbEnabledBadge: React.FC<WbEnabledBadgeProps> = ({
  className,
  style,
  enabled,
}) => {
  const classes = useStyles();

  return (
    <WbBadge
      style={style}
      className={clsx(
        className,
        classes.statusBadge,
        enabled ? classes.enabledStatus : classes.disabledStatus,
      )}
    >
      {enabled ? (
        <CheckIcon fontSize="small" />
      ) : (
        <CancelIcon fontSize="small" />
      )}
      {enabled ? 'Enabled' : 'Disabled'}
    </WbBadge>
  );
};
