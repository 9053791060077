import {
  OptionsSetsCache,
  WittyFieldSubscription,
  isWittyMultiSelectFieldSubscription,
} from './types';

/**
 * updates the optionsSetMap based on current subscribers
 */
export const syncOptionsSetsCacheWithSubscribers = (
  optionsSetMap: OptionsSetsCache,
  subscribers: WittyFieldSubscription[],
) => {
  const subscribedOptionsSetIds = new Map(
    subscribers
      .filter(isWittyMultiSelectFieldSubscription)
      .map(s => [s.optionsSetId, s]),
  );

  // removes optionsSets for which there no longer are subscribers
  for (const [k] of optionsSetMap.entries()) {
    if (!subscribedOptionsSetIds.has(k)) optionsSetMap.delete(k);
  }

  // add missing ones
  for (const [k, v] of subscribedOptionsSetIds.entries()) {
    if (!optionsSetMap.has(k))
      optionsSetMap.set(k, { fetchFn: v.fetchOptions });
  }
};
