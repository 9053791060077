import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { CustomPresentationRetrieverApiRef } from '../apis';

export function useCustomPresentation(
  id: string,
  typeId: string = '',
  templateId: string = '',
  included: Array<any> = [],
): any {
  const CPRetriever = useApi(CustomPresentationRetrieverApiRef);
  const customPresentation = useAsync(async () => {
    const viewsIds = [{ id, typeId, templateId }].concat(included);
    const views = await Promise.all(
      viewsIds.map(async (inc: any) => ({
        id: inc.id,
        typeId: inc.typeId,
        templateId: inc.templateId,
        code: await CPRetriever.retrieve(inc.id, inc.typeId, inc.templateId),
      })),
    );
    return {
      view: views[0].code,
      included: views.slice(1),
    };
  }, [id, typeId, templateId]);
  return customPresentation;
}
