import {
  MainPageType,
  useLastVisitedMainPage,
  useSearchParamChangeObserver,
  useSessionQueryString,
  WbHeader,
} from '@agilelab/plugin-wb-platform';
import { Content, Page } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { SearchPageContent } from './components/SearchPageContent';
import { marketplaceSearchRef } from '../../routes';
import { useRouteRef } from '@backstage/core-plugin-api';

const useStyles = makeStyles(
  () => ({
    content: {
      minHeight: 0,
    },
  }),
  { name: 'SearchPage' },
);

export const SearchPage = () => {
  const classes = useStyles();
  const [_, setLastVisitedMainPage] = useLastVisitedMainPage(
    MainPageType.MARKETPLACE,
  );

  const searchResultsRoute = useRouteRef(marketplaceSearchRef);
  setLastVisitedMainPage(searchResultsRoute());

  const [_sessionQueryString, setSessionQueryString] = useSessionQueryString();
  useSearchParamChangeObserver(
    (_previousSearch: string, currentSearch: string) => {
      setSessionQueryString(currentSearch);
    },
  );

  return (
    <Page themeId="home">
      <Content className={classes.content}>
        <WbHeader title="Search" hidden />
        <SearchPageContent />
      </Content>
    </Page>
  );
};
