import { z } from 'zod';
import { createTopic } from '../lib';

const externalLinkClickedEventSchema = z.object({
  externalUrl: z.string(),
  currentPage: z.string(),
});

export type externalLinkClickedEvent = z.infer<
  typeof externalLinkClickedEventSchema
>;

export const externalLinkClickedTopic = createTopic<externalLinkClickedEvent>(
  {
    namespace: 'platform',
    name: 'external-link-clicked',
    description: 'The user clicked on a link to an external domain',
  },
  externalLinkClickedEventSchema,
);
