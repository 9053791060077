import {
  Box,
  FormControl,
  IconButton,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import { MicIcon, SendIcon, AttachFileIcon } from './icons/icons';
import { useChatContext } from './ChatContext';
import { WbTextField } from '../WbTextField';
import { WittyMessage } from './Messages';

type TextBoxProps = {
  isMicrophoneActive?: boolean;
  isAttachContentActive?: boolean;
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(1.5),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));

export const TextBox: React.FC<TextBoxProps> = ({
  isMicrophoneActive = true,
  isAttachContentActive = true,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const { onNewMessage } = useChatContext();

  const [input, setInput] = useState<string>('');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!input) return;
    const message: WittyMessage = { sender: 'you', contentMessage: input };
    setInput('');
    onNewMessage(message);
  };

  return (
    <form onSubmit={onSubmit}>
      <FormControl style={{ width: '100%' }}>
        <WbTextField
          placeholder="Type message"
          value={input}
          onChange={event => {
            setInput(event.target.value);
          }}
          InputProps={{
            classes: {
              root: classes.container,
            },
            startAdornment: (
              <Box className={classes.actions}>
                {isMicrophoneActive && (
                  <IconButton size="small">
                    <MicIcon style={{ color: theme.palette.secondary.main }} />
                  </IconButton>
                )}
                {isAttachContentActive && (
                  <IconButton size="small">
                    <AttachFileIcon
                      fontSize="small"
                      style={{ color: theme.palette.secondary.main }}
                    />
                  </IconButton>
                )}
              </Box>
            ),
            endAdornment: (
              <IconButton size="small" type="submit">
                <SendIcon style={{ color: theme.palette.primary.main }} />
              </IconButton>
            ),
          }}
        />
      </FormControl>
    </form>
  );
};
