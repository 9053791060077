import { z } from 'zod';

export const GuestUserZod = z.object({
  username: z.string(),
  enabled: z.boolean().default(false),
});

export type GuestUser = z.infer<typeof GuestUserZod>;

export const SafeGuestUserZod = GuestUserZod.extend({
  password_hash: z.string(),
  salt: z.string(),
});

export type SafeGuestUser = z.infer<typeof SafeGuestUserZod>;

export const GuestUserRequestZod = GuestUserZod.extend({
  password: z.string(),
});

export type GuestUserRequest = z.infer<typeof GuestUserRequestZod>;

export const GUEST_USERNAMES: string[] = [
  'GUEST_ADMIN',
  'GUEST_USER',
  'GUEST_DEVELOPER',
];
