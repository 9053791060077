import { AuthApiCreateOptions } from '@backstage/core-app-api';
import {
  ApiRef,
  AuthRequestOptions,
  BackstageIdentityApi,
  BackstageIdentityResponse,
  createApiRef,
  ProfileInfo,
  ProfileInfoApi,
  SessionApi,
  SessionState,
} from '@backstage/core-plugin-api';
import { Observable } from '@backstage/types/index';
import { SessionManager } from '../../lib';
import { LdapAuthSession } from '../ldap/LdapAuth';
import jwtDecoder from 'jwt-decode';
import { GuestUsersDefaultAuthConnector } from '../../lib/DefaultHeaderAuthConnector';
import { GuestUsersRefreshingAuthSessionManager } from '../../lib/RefreshingAuthSessionManager';

/**
 * Guest Users create options.
 * @public
 */
export type GuestUsersAuthCreateOptions = AuthApiCreateOptions;

export type GuestUsersAuthResponse = {
  profile: ProfileInfo;
  backstageIdentity: BackstageIdentityResponse;
};

export type GuestUsersAuthSession = {
  profile: ProfileInfo;
  backstageIdentity: BackstageIdentityResponse;
};

const DEFAULT_PROVIDER = {
  id: 'simple_guest_users',
  title: 'Authenticated Guest User',
  icon: () => null,
};

/**
 * Implements a generic guest users flow for auth.
 *
 * @public
 */
export default class GuestUsersAuth
  implements ProfileInfoApi, BackstageIdentityApi, SessionApi
{
  static create(options: GuestUsersAuthCreateOptions) {
    const {
      discoveryApi,
      provider = DEFAULT_PROVIDER,
      environment = 'default',
    } = options;

    const connector = new GuestUsersDefaultAuthConnector({
      discoveryApi,
      environment,
      provider,
      sessionTransform(res: GuestUsersAuthResponse): GuestUsersAuthSession {
        return res;
      },
      extractHeaders(session: GuestUsersAuthSession): any {
        return {
          'x-guest-user-username': session.profile.email,
          Authorization: `Bearer ${session.backstageIdentity?.token}`,
        };
      },
    });

    const sessionManager = new GuestUsersRefreshingAuthSessionManager({
      connector,
      storageKey: `${provider.id}Session`,
      sessionShouldRefresh: (session: GuestUsersAuthSession) => {
        if (!session.backstageIdentity?.token) {
          return true;
        }
        const decodedToken: Record<string, string> = jwtDecoder(
          session.backstageIdentity.token,
        );
        const expiresInSec =
          (new Date(Number(decodedToken.exp) * 1000).getTime() - Date.now()) /
          1000;
        return expiresInSec < 60 * 5;
      },
    });

    return new GuestUsersAuth(sessionManager);
  }

  private readonly sessionManager: SessionManager<GuestUsersAuthSession>;

  private constructor(sessionManager: SessionManager<LdapAuthSession>) {
    this.sessionManager = sessionManager;
  }

  async signIn(): Promise<void> {
    await this.getBackstageIdentity();
  }

  async signOut(): Promise<void> {
    await this.sessionManager.removeSession();
  }

  sessionState$(): Observable<SessionState> {
    return this.sessionManager.sessionState$();
  }

  async getBackstageIdentity(
    options: AuthRequestOptions = {},
  ): Promise<BackstageIdentityResponse | undefined> {
    const session = await this.sessionManager.getSession(options);
    return session?.backstageIdentity;
  }

  async getProfile(
    options: AuthRequestOptions = {},
  ): Promise<ProfileInfo | undefined> {
    const session = await this.sessionManager.getSession(options);
    return session?.profile;
  }
}

export const guestUsersAuthApiRef: ApiRef<
  ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
  id: 'core.auth.guest-users',
});
