import { Checkbox, TextFieldProps } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import React, { ChangeEventHandler } from 'react';
import { WbChip } from './WbChip';
import { WbTextField } from './WbTextField';
import { useStyles } from './styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function WbAutocompleteComponent<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  {
    helperText,
    label,
    error,
    placeholder,
    FormHelperTextProps,
    InputProps,
    required,
    renderOption,
    renderTags,
    onTextFieldChange,
    ...props
  }: Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > &
    Pick<
      TextFieldProps,
      | 'label'
      | 'error'
      | 'placeholder'
      | 'helperText'
      | 'FormHelperTextProps'
      | 'required'
      | 'InputProps'
    > & {
      startAdornment?: React.ReactNode;
      onTextFieldChange?:
        | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
        | undefined;
    },
  ref?: React.Ref<HTMLInputElement>,
) {
  const classes = useStyles();
  return (
    <Autocomplete
      {...props}
      size="small"
      autoSelect
      classes={{
        root: classes.autocompleteRoot,
        popupIndicator: classes.popupIndicator,
        clearIndicator: classes.clearIndicator,
        inputRoot: (props as { small?: boolean }).small
          ? classes.smallAutocompleteInputRoot
          : classes.autocompleteInputRoot,
        endAdornment: classes.autocompleteEndAdornment,
      }}
      renderInput={p => {
        return (
          <WbTextField
            ref={ref}
            {...p}
            label={label}
            helperText={helperText}
            error={error}
            placeholder={placeholder}
            FormHelperTextProps={FormHelperTextProps}
            required={required}
            onChange={onTextFieldChange}
            InputProps={{
              ...p.InputProps,
              ...InputProps,
              startAdornment: (
                <>
                  {props.startAdornment}
                  {p.InputProps.startAdornment}
                </>
              ),
            }}
          />
        );
      }}
      popupIcon={<ExpandMoreIcon />}
      renderOption={
        renderOption
          ? renderOption
          : (option, { selected }) => (
              <React.Fragment>
                {props.multiple && (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                )}
                {props.getOptionLabel
                  ? props.getOptionLabel(option)
                  : (option as string)}
              </React.Fragment>
            )
      }
      renderTags={
        renderTags
          ? renderTags
          : (value: T[], getTagProps) =>
              value.map((option: T, index: number) => (
                <WbChip label={option as string} {...getTagProps({ index })} />
              ))
      }
    />
  );
}

export const WbAutocomplete = React.forwardRef(
  WbAutocompleteComponent,
) as typeof WbAutocompleteComponent;
