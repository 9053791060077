import { Role } from '@agilelab/plugin-wb-rbac-common';

export function adaptRole(form: {
  displayName: string;
  description?: string;
}): Role {
  return {
    id: form.displayName.split(' ').join('_').toUpperCase(),
    displayName: form.displayName,
    description: form.description,
    visibility: 'user',
  } as Role;
}
