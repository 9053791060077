import {
  Grid,
  makeStyles,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import React, { CSSProperties } from 'react';
import { WbFieldLabel } from '../WbFieldLabel';
import { ExpandableItem } from './ExpandableItem';
import { SimpleItem } from './SimpleItem';

const useStyle = makeStyles(theme => ({
  text: {
    overflow: 'hidden',
    fontSize: theme.typography.fontSize,
    wordBreak: 'break-word',
  },
  label: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '14px',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  wordBreak: {
    wordBreak: 'break-word',
  },
}));

type Props = {
  label?: string;
  value?: React.ReactNode;
  /**
   * supported types are:
   * 'string' | 'date'
   */
  type?: string;
  href?: string;
  hrefTarget?: string;
  textVariant?: 'body1' | 'body2';
  maxLines?: number;
  gridItem?: boolean;
  gridSizes?: Record<string, number>;
  contentProps?: TypographyProps;
  labelStyle?: CSSProperties;
  showMoreButton?: boolean;
  dateFormat?: string;
};

const Content = ({
  value,
  type,
  href,
  hrefTarget,
  maxLines,
  textVariant,
  contentProps,
  dateFormat = 'yyyy/MM/dd HH:mm:ss',
  showMoreButton = false,
}: Omit<Props, 'label'>) => {
  const classes = useStyle();

  if (typeof value === 'string') {
    if (type === 'string' || value.trim() === '') {
      return (
        <Typography
          variant={textVariant}
          className={classes.wordBreak}
          {...contentProps}
        >
          {(maxLines === undefined || maxLines === -1) && !showMoreButton ? (
            <SimpleItem value={value} href={href} />
          ) : (
            <ExpandableItem
              value={value}
              href={href}
              hrefTarget={hrefTarget}
              maxLines={maxLines}
              showMoreButton={showMoreButton}
            />
          )}
        </Typography>
      );
    } else if (type === 'date') {
      try {
        return (
          <Typography {...contentProps}>
            {format(parseISO(value as string), dateFormat)}
          </Typography>
        );
      } catch (e) {
        return <Typography {...contentProps}>{value}</Typography>;
      }
    } else {
      return <Typography {...contentProps}>{value}</Typography>;
    }
  } else if (typeof value === 'number') {
    return <Typography {...contentProps}>{value}</Typography>;
  } else if (typeof value === 'boolean') {
    return <Typography {...contentProps}>{value.toString()}</Typography>;
  }

  return <>{value ?? '-'}</>;
};

export const GenericField = ({
  label,
  gridSizes,
  gridItem = true,
  labelStyle,
  ...content
}: Props) => {
  const wrappedContent = (
    <>
      {label && <WbFieldLabel label={label} style={labelStyle} />}
      <Content {...content} />
    </>
  );

  if (gridItem) {
    return (
      <Grid item {...gridSizes}>
        {wrappedContent}
      </Grid>
    );
  }

  return wrappedContent;
};
