import {
  WittyAutocompleteApiRequest,
  WittyAutocompleteApiResponse,
} from '@agilelab/plugin-wb-witty-common';
import {
  ApiRef,
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

export type WittyAutocompleteApi = {
  autocomplete(
    request: WittyAutocompleteApiRequest,
  ): Promise<WittyAutocompleteApiResponse>;
};

export class WittyAutocompleteClient implements WittyAutocompleteApi {
  constructor(
    private readonly fetchApi: FetchApi,
    private readonly discoveryApi: DiscoveryApi,
  ) {}

  async autocomplete({
    tablePath,
    formData,
    optionsSets,
    suggestFields,
    additionalContext,
  }: WittyAutocompleteApiRequest): Promise<WittyAutocompleteApiResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('witty');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v1/autocomplete/table`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          tablePath,
          formData: { root: { ...formData } },
          suggestFields,
          optionsSets,
          additionalContext,
        }),
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }
}

export const wittyAutocompleteApiRef: ApiRef<WittyAutocompleteApi> =
  createApiRef<WittyAutocompleteApi>({
    id: 'wb-witty-autocomplete',
  });
