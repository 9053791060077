import React from 'react';
import { useForm, UseFormRegisterReturn } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import isEmpty from 'lodash/isEmpty';
import { InfoCard, UserIdentity } from '@backstage/core-components';
import { GridItem } from './styles';
import {
  ProviderComponent,
  ProviderLoader,
  SignInProvider,
  SignInProviderConfig,
} from './types';
import {
  useApi,
  errorApiRef,
  AuthRequestOptions,
} from '@backstage/core-plugin-api';
import { ForwardedError } from '@backstage/errors';

const useFormStyles = makeStyles(
  theme => ({
    form: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    button: {
      alignSelf: 'center',
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'BackstageCustomProvider' },
);

type Data = {
  username: string;
  password: string;
};

const asInputRef = (renderResult: UseFormRegisterReturn) => {
  const { ref, ...rest } = renderResult;
  return {
    inputRef: ref,
    ...rest,
  };
};

const Component: ProviderComponent = ({
  config,
  onSignInStarted,
  onSignInSuccess,
  onSignInFailure,
}) => {
  const classes = useFormStyles();
  const { apiRef, title } = config as SignInProviderConfig;
  const authApi = useApi(apiRef);
  const errorApi = useApi(errorApiRef);

  const { register, handleSubmit, formState } = useForm<Data>({
    mode: 'onChange',
  });

  const { errors } = formState;

  const handleLogin = async (values: any) => {
    onSignInStarted();
    try {
      const credentials = {
        username: values.username,
        password: values.password,
      };
      const identityResponse = await authApi.getBackstageIdentity(
        credentials as AuthRequestOptions,
      );
      if (!identityResponse) {
        onSignInFailure();
        throw new Error(
          `The ${title} provider is not configured to support sign-in`,
        );
      }

      const profile = await authApi.getProfile();

      onSignInSuccess(
        UserIdentity.create({
          identity: identityResponse.identity,
          profile,
          authApi,
        }),
      );
    } catch (error) {
      onSignInFailure();
      errorApi.post(new ForwardedError('Login failed', error));
    }
  };

  return (
    <GridItem>
      <InfoCard title={title} variant="fullHeight">
        <Typography>Enter your username and password.</Typography>

        <form className={classes.form} onSubmit={handleSubmit(handleLogin)}>
          <FormControl>
            <TextField
              {...asInputRef(register('username', { required: true }))}
              label="Username"
              margin="normal"
              error={Boolean(errors.username)}
            />
            {errors.username && (
              <FormHelperText error>{errors.username.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <TextField
              {...asInputRef(register('password', { required: true }))}
              label="Password"
              type="password"
              margin="normal"
              autoComplete="off"
              error={Boolean(errors.password)}
            />
            {errors.password && (
              <FormHelperText error>{errors.password.message}</FormHelperText>
            )}
          </FormControl>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            className={classes.button}
            disabled={!formState?.isDirty || !isEmpty(errors)}
          >
            Continue
          </Button>
        </form>
      </InfoCard>
    </GridItem>
  );
};

const loader: ProviderLoader = async (apis, apiRef) => {
  const authApi = apis.get(apiRef)!;

  const identityResponse = await authApi.getBackstageIdentity({
    optional: true,
  });

  if (!identityResponse) {
    return undefined;
  }

  const profile = await authApi.getProfile();

  return UserIdentity.create({
    identity: identityResponse.identity,
    profile,
    authApi,
  });
};

export const simpleProvider: SignInProvider = { Component, loader };
