import React from 'react';
import { useDataPath } from '../../context';
import { CustomViewRegister } from '../CustomView/registry';

CustomViewRegister.register({
  id: 'data_logger',
  function: function DataDebug(props: Record<string, any>) {
    const data = useDataPath('');

    // eslint-disable-next-line
    console.log(props.label ?? 'data_logger', data);
    return <></>;
  },
});
