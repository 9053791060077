import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  Popper,
  PopperProps,
  useTheme,
} from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import React, { ReactNode, useRef, useState } from 'react';

type SubMenuItemProps = MenuItemProps & {
  icon?: ReactNode;
  text: string;
  button?: true;
} & Pick<PopperProps, 'placement'>;

export const CustomViewSubMenu = (props: SubMenuItemProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLLIElement | null>(null);
  const theme = useTheme();

  return (
    <MenuItem
      {...props}
      ref={ref}
      onMouseEnter={() => {
        setOpen(true);
      }}
      onMouseLeave={() => {
        setOpen(false);
      }}
      style={{
        fontSize: 14,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
      <ListItemText primary={props.text} />
      <Popper
        anchorEl={ref.current}
        open={open}
        placement={props.placement ?? 'right-start'}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        }}
        style={{ zIndex: 9999 }}
      >
        {props.children}
      </Popper>
      <ListItemIcon>
        <ArrowForwardIos
          style={{
            marginLeft: 'auto',
            color: theme.palette.secondary.main,
          }}
          fontSize="inherit"
        />
      </ListItemIcon>
    </MenuItem>
  );
};
