import { Checkbox, Tooltip, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';

interface SelectorProps<T> {
  row: T;
  selectedItems: T[];
  setSelectedItems: React.Dispatch<React.SetStateAction<T[]>>;
  disabled?: boolean;
  tooltipIfDisabled?: string;
  getRowId: (row: T) => string;
}

const useStyles = makeStyles({
  root: {
    padding: '4px 9px',
  },
});

export const Selector = <T,>({
  row,
  selectedItems,
  setSelectedItems,
  disabled = false,
  tooltipIfDisabled,
  getRowId,
}: SelectorProps<T>) => {
  const classes = useStyles();

  const isChecked = useMemo(() => {
    return selectedItems.some((i: T) => getRowId(i) === getRowId(row));
  }, [selectedItems, row, getRowId]);

  const handleChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (isChecked) {
      setSelectedItems(
        selectedItems.filter((e: T) => getRowId(e) !== getRowId(row)),
      );
    } else {
      setSelectedItems([...selectedItems, row]);
    }
  };

  const Content = () => (
    <Checkbox
      classes={{ root: classes.root }}
      indeterminate={disabled}
      onClick={e => handleChange(e)}
      checked={isChecked}
      color="primary"
      disabled={disabled}
    />
  );

  return disabled && tooltipIfDisabled ? (
    <Tooltip title={tooltipIfDisabled}>
      <span>
        <Content />
      </span>
    </Tooltip>
  ) : (
    <Content />
  );
};
