import {
  GetRolesResponse,
  RoleEntity,
  rbacRoleCreatePermission,
  rbacRoleDeletePermission,
} from '@agilelab/plugin-wb-rbac-common';
import {
  useApi,
  identityApiRef,
  configApiRef,
  alertApiRef,
} from '@backstage/core-plugin-api';
import React, { useContext, useEffect, useState } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { rbacApiRef } from '../../..';
import { usePermission } from '@backstage/plugin-permission-react';
import { adaptRole } from '../../../utils';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { RoleFormType } from '../RoleForm';

export interface Filters {
  text?: string;
}

export interface RolesTableContextProviderProps {
  children?: React.ReactNode;
}

export interface RolesTableContextType extends RolesTableContextProviderProps {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;

  error: Error | undefined;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;

  canAddNewRolePermission: boolean;
  addRole: (form: RoleFormType) => Promise<RoleEntity[]>;
  addRoleState: AsyncState<RoleEntity[] | undefined>;

  canDeleteNewRolePermission: boolean;
  deleteRoles: (roles: string[]) => Promise<string[]>;
  deleteRolesState: AsyncState<string[]>;

  loadRoles: () => Promise<GetRolesResponse>;
  rolesLoaded: AsyncState<GetRolesResponse | undefined>;

  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  pagination: {
    limit: number;
    offset: number;
  };
  setPagination: React.Dispatch<
    React.SetStateAction<{
      limit: number;
      offset: number;
    }>
  >;

  selectedItems: RoleEntity[];
  setSelectedItems: React.Dispatch<React.SetStateAction<RoleEntity[]>>;
}

export const RolesTableContext = React.createContext<RolesTableContextType>(
  {} as RolesTableContextType,
);

export const RolesTableContextProvider = ({
  children,
}: RolesTableContextProviderProps) => {
  const rbacApi = useApi(rbacApiRef);
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);
  const alertApi = useApi(alertApiRef);

  // check if permission check is enabled
  const permissionsEnabled =
    configApi.getOptionalBoolean('permission.enabled') ?? false;

  const createPermissionAllowed = usePermission({
    permission: rbacRoleCreatePermission,
  }).allowed;

  const deletePermissionAllowed = usePermission({
    permission: rbacRoleDeletePermission,
  }).allowed;

  const canAddNewRolePermission =
    !permissionsEnabled || createPermissionAllowed;

  const canDeleteNewRolePermission =
    !permissionsEnabled || deletePermissionAllowed;

  const [error, setError] = useState<Error>();

  const [filters, setFilters] = useState<Filters>({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedItems, setSelectedItems] = useState<RoleEntity[]>([]);
  const [pagination, setPagination] = useState({
    limit: 15,
    offset: 0,
  });

  const [rolesLoaded, loadRoles] = useAsyncFn(async () => {
    const fetchRoles = async (): Promise<GetRolesResponse> => {
      const res = await rbacApi.getRoles({
        searchKeyword: filters.text,

        options: {
          offset: pagination.offset,
          limit: pagination.limit,
          token: (await identityApi.getCredentials()).token,
        },
      });
      return {
        ...res,
        roles: res.roles.map(role => ({
          displayName: role.displayName ?? role.id,
          description: role.description,
          visibility: role.visibility,
          id: role.id,
        })),
      };
    };
    return fetchRoles();
  }, [pagination, filters]);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  const [addRoleState, addRole] = useAsyncFn(async (form: RoleFormType) => {
    try {
      await rbacApi.addRoles({
        roles: [adaptRole(form)],
        options: { token: (await identityApi.getCredentials()).token },
      });
      setOpenDrawer(false);

      alertApi.post({
        message: `New Role successfully created.`,
        severity: 'success',
      });

      loadRoles();

      // TODO- navigate to detail page
    } catch (e) {
      alertApi.post({
        message: e,
        severity: 'error',
      });
    }
    return [];
  }, []);

  useEffect(() => {
    if (addRoleState.error) setError(addRoleState.error);
  }, [addRoleState.error]);

  const [deleteRolesState, deleteRoles] = useAsyncFn(
    async (roles: string[]) => {
      try {
        await rbacApi.deleteUserRoles({
          roles,
          options: { token: (await identityApi.getCredentials()).token },
        });

        alertApi.post({
          message: `Roles successfully deleted`,
          severity: 'success',
        });

        loadRoles();
      } catch (e) {
        alertApi.post({
          message: e,
          severity: 'error',
        });
      }
      return [];
    },
    [],
  );

  return (
    <RolesTableContext.Provider
      value={{
        loadRoles,
        rolesLoaded,
        error,
        setError,
        openDrawer,
        setOpenDrawer,
        canAddNewRolePermission,
        addRoleState,
        addRole,
        canDeleteNewRolePermission,
        deleteRoles,
        deleteRolesState,
        filters,
        setFilters,
        pagination,
        setPagination,
        selectedItems,
        setSelectedItems,
      }}
    >
      {children}
    </RolesTableContext.Provider>
  );
};

export const useRolesTableContext = () => {
  const context = useContext(RolesTableContext);
  if (!context) {
    throw new Error(
      'useRolesTableContext must be used within a RolesTableContextProvider',
    );
  }
  return context as RolesTableContextType;
};
