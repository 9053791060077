import { WbNoData } from '@agilelab/plugin-wb-platform';
import React from 'react';
import favoritesEmptyState from './favoritesEmptyState.svg';
import recentEmptyState from './recentEmptyState.svg';
import topEmptyState from './topEmptyState.svg';
import { makeStyles } from '@material-ui/core';

interface HomepageEmptyStateProps {
  kind: 'favorites' | 'recent' | 'top';
}

const getDataFromKind = ({ kind }: HomepageEmptyStateProps) => {
  switch (kind) {
    case 'favorites':
      return {
        title: 'You have not yet created your list of favorites',
        caption: 'All of your favorites will show up here',
        icon: favoritesEmptyState,
      };
    case 'top':
      return {
        title: "You haven't visited any page yet",
        caption: 'Start browsing and this list will automatically populate',
        icon: topEmptyState,
      };
    case 'recent':
      return {
        title: 'No pages visited recently',
        caption: 'Start browsing and this list will automatically populate',
        icon: recentEmptyState,
      };
    default:
      return {};
  }
};

const useStyles = makeStyles(theme => ({
  icon: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      transform: 'scale(0.8)',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));
export const HomepageEmptyState = ({ kind }: HomepageEmptyStateProps) => {
  const { title, caption, icon } = getDataFromKind({ kind });
  const classes = useStyles();

  return (
    <WbNoData
      fillContainer
      icon={<img className={classes.icon} src={icon} alt={`${kind} missing`} />}
      header={title}
      text={caption}
    />
  );
};
