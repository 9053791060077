import {
  CustomViewComponent,
  CustomViewPageCategory,
  CustomViewPages,
  useCustomViewInfoFromConfig,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import preview from './preview/marketplace_system_general/marketplace_system_general.png';
import thumbnail from './preview/marketplace_system_general/marketplace_system_general_thumbnail.png';

CustomViewPages.register({
  id: 'marketplace_system_general',
  meta: {
    label: 'System General Information',
    category: CustomViewPageCategory.MARKETPLACE,
    preview: {
      thumbnail: { src: thumbnail },
      items: [{ src: preview }],
    },
  },
  function: function DataProductGeneral() {
    const additionalGeneralInfo = useCustomViewInfoFromConfig(
      'mesh.marketplace.ui.dataProductPage.additionalGeneralInfo',
      { showOnlyWithValues: true },
    );

    return (
      <CustomViewComponent
        type="marketplace_info_card"
        title="General Information"
      >
        <CustomViewComponent type="hbox" responsive>
          <CustomViewComponent type="container" size={3}>
            <CustomViewComponent
              type="automatic_fields_list"
              path=""
              exclude={[
                'useCaseTemplateId',
                'infrastructureTemplateId',
                'id',
                'kind',
                'email',
                'maturity',
                'version',
                'domainId',
                'environment',
                'dataProductOwner',
                'shoppable',
                'consumable',
                'projectOwner',
                'domain',
              ]}
              defaults={{
                description: { colSpan: 4 },
                dataProductOwnerDisplayName: { label: 'Owner' },
                projectOwnerDisplayName: { label: 'Project Owner' },
              }}
              order={[
                'name',
                'description',
                'dataProductOwnerDisplayName',
                'projectOwnerDisplayName',
              ]}
            />
            <CustomViewComponent
              label="Value Proposition"
              type="string"
              value="{{business_attributes.valueProposition}}"
              colSpan="2"
              showWhenExists="business_attributes.valueProposition"
            />
            <CustomViewComponent
              label="OKR Link"
              type="string"
              value="{{business_attributes.okr}}"
              colSpan="2"
              showWhenExists="business_attributes.okr"
            />
            <CustomViewComponent
              label="Pricing"
              type="string"
              value="{{business_attributes.pricingType}}"
              showWhenExists="business_attributes.pricingType"
            />
            <CustomViewComponent
              label="Target Consumption"
              type="string"
              value="{{business_attributes.consumptionMode}}"
              showWhenExists="business_attributes.consumptionMode"
            />
            <CustomViewComponent
              label="Visibility"
              type="string"
              value="{{security_attributes.visibility}}"
              showWhenExists="security_attributes.visibility"
            />
            <CustomViewComponent
              label="Confidentiality"
              type="string"
              value="{{security_attributes.confidentiality}}"
              showWhenExists="security_attributes.confidentiality"
            />
            <CustomViewComponent
              label="Stakeholders"
              type="string"
              value="{{business_attributes.stakeholderRoles}}"
              showWhenExists="business_attributes.stakeholderRoles"
            />
            {additionalGeneralInfo}
          </CustomViewComponent>
          <CustomViewComponent type="container" borderLeft size={1}>
            <CustomViewComponent
              label="Environment"
              type="string"
              value="{{_computedInfo.environment.name}}"
              colSpan="4"
              showWhenExists="_computedInfo.environment.name"
            />
            <CustomViewComponent
              label="Version"
              type="string"
              value="{{version}}"
              colSpan="4"
              showWhenExists="version"
            />
            <CustomViewComponent
              label="Published Date"
              type="date"
              value="{{_computedInfo.published_at }}"
              colSpan="4"
              showWhenExists="_computedInfo.published_at"
            />
            <CustomViewComponent
              label="Domain"
              type="string"
              value="{{ domain }}"
              colSpan="4"
              showWhenExists="domain"
            />
            <CustomViewComponent
              type="hline"
              showWhenExists={['contacts.owner', 'contacts.support']}
            />
            <CustomViewComponent
              label="Owner Contact"
              type="string"
              value="{{contacts.owner}}"
              colSpan="4"
              showWhenExists="contacts.owner"
            />
            <CustomViewComponent
              label="Support Contact"
              type="string"
              value="{{contacts.support}}"
              colSpan="4"
              showWhenExists="contacts.support"
            />
          </CustomViewComponent>
        </CustomViewComponent>
        <CustomViewComponent type="hline" showWhenExists="businessConcepts" />
        <CustomViewComponent
          title="Related Business Concepts"
          type="tags"
          path="businessConcepts"
          showWhenExists="businessConcepts"
          showWhen={{
            value: '{{ businessConcepts.length }}',
            notEquals: 0,
          }}
        />
        <CustomViewComponent
          type="hline"
          showWhenExists="tags"
          showWhen={{
            value: '{{ tags.length }}',
            notEquals: 0,
          }}
        />
        <CustomViewComponent
          title="Tags"
          type="tags"
          path="tags"
          showWhen={{
            value: '{{ tags.length }}',
            notEquals: 0,
          }}
        />
        <CustomViewComponent type="marketplace_flag_and_score" />
      </CustomViewComponent>
    );
  },
});
