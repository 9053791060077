import { z } from 'zod';

export const WittyTableTemplateConfigurationZod = z.object({
  maxRowsPerChunk: z.number().int().positive().optional(),
  context: z.array(z.string()).optional(),
  triggeringColumns: z.array(z.string()).optional(),
});

export type WittyTableTemplateConfiguration = z.infer<
  typeof WittyTableTemplateConfigurationZod
>;
