import React, { ReactNode } from 'react';
import { CustomViewRegister } from './registry';
import { CustomViewPages } from './pages';
import { useAction } from '../../context';

type PropsInclude = {
  id: string;
  typeId?: string;
  templateId?: string;
  children?: ReactNode;
};

CustomViewRegister.register({
  id: 'include',
  function: function IncludeComponent({
    children,
    id,
    typeId,
    templateId,
  }: PropsInclude) {
    const getInclude = useAction('getInclude');
    const parentInfo = useAction('getCustomViewInfo')();
    return (
      getInclude(
        id,
        typeId || parentInfo.typeId || '',
        templateId || parentInfo.templateId || '',
      ) ||
      (CustomViewPages.get(id) || (() => <>{children}</>))() || <>{children}</>
    );
  },
});
