import React, { RefObject, useEffect, useRef } from 'react';
import { TextBox } from './TextBox';
import { Box, IconButton, makeStyles, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { WittyIcon } from './icons/WittyIcon';
import {
  ChatContextProvider,
  ChatContextType,
  useChatContext,
} from './ChatContext';
import { Message } from './Messages';
import { getOnHoverScrollbarStyle } from '../../styles';
import { IncomingMessage } from './Messages/IncomingMessage';
import { WittyEmptyChatIcon } from './icons/WittyEmptyChatIcon';
import { WittyExpandIcon, WittyNewSessionIcon } from './icons/icons';

type ChatProps = Partial<ChatContextType> & {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setChatExpanded: Function;
  isMicrophoneActive?: boolean;
  isAttachContentActive?: boolean;
  isFeedbackActive?: boolean;
  onNewSession: Function;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      height: '100%',
      width: 'inherit',
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
    },
    boxChat: {
      paddingInline: theme.spacing(2),
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      ...getOnHoverScrollbarStyle(theme, false),
    },
    wittyHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '32px',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    rightActions: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    fillMessageContainerTop: {
      flex: 1,
    },
    textboxWrapper: {
      width: '100%',
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    courtesyMessageWrapper: {
      paddingTop: theme.spacing(10),
      alignSelf: 'center',
      textAlign: 'center',
    },
    headerIconButton: {
      alignSelf: 'end',
      padding: '0',
    },
  }),
  { name: 'witty-chat' },
);

const WittyHeader = ({
  setOpen,
  setChatExpanded,
  onNewSession,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setChatExpanded: Function;
  onNewSession: Function;
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const { incomingMessage } = useChatContext();

  return (
    <Box className={classes.wittyHeader}>
      <WittyIcon />
      <Box className={classes.rightActions}>
        <IconButton
          className={classes.headerIconButton}
          title="New session"
          aria-label="new session"
          onClick={() => onNewSession()}
          color="primary"
          disabled={incomingMessage}
        >
          <WittyNewSessionIcon />
        </IconButton>
        <IconButton
          className={classes.headerIconButton}
          title="Expand chat"
          aria-label="expand chat"
          onClick={() => setChatExpanded()}
          color="primary"
          disabled={incomingMessage}
        >
          <WittyExpandIcon />
        </IconButton>
        <div
          style={{
            borderLeft: `1px solid ${theme.palette.grey[200]}`,
          }}
        >
          <IconButton
            aria-label="clear"
            onClick={() => setOpen(false)}
            size="small"
            color="primary"
            disabled={incomingMessage}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Box>
    </Box>
  );
};

type WittyContentProps = {
  isMicrophoneActive?: boolean;
  isAttachContentActive?: boolean;
  isFeedbackActive?: boolean;
};

const WittyContent: React.FC<WittyContentProps> = ({
  isMicrophoneActive,
  isAttachContentActive,
  isFeedbackActive,
}) => {
  const classes = useStyles();
  const chatEndRef: RefObject<HTMLDivElement> = useRef(null);
  const { messages, incomingMessage } = useChatContext();

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);
  return (
    <>
      <Box className={classes.boxChat}>
        {messages?.length === 0 && (
          <div className={classes.courtesyMessageWrapper}>
            <h2>How can I help you, today?</h2>
            <WittyEmptyChatIcon />
            <p>
              Ask me anything. Make your request and I will try to answer you as
              best as I can.
            </p>
          </div>
        )}
        <Box className={classes.fillMessageContainerTop} />
        {messages?.map(m => (
          <Message
            sender={m.sender}
            contentMessage={m.contentMessage}
            isFeedbackActive={isFeedbackActive}
          />
        ))}
        {incomingMessage && <IncomingMessage />}
        <div ref={chatEndRef} />
      </Box>
      <Box className={classes.textboxWrapper}>
        <TextBox
          isMicrophoneActive={isMicrophoneActive}
          isAttachContentActive={isAttachContentActive}
        />
      </Box>
    </>
  );
};

export const WittyChat: React.FC<ChatProps> = ({
  setOpen,
  setChatExpanded,
  isMicrophoneActive,
  isAttachContentActive,
  isFeedbackActive,
  onNewSession,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <ChatContextProvider {...props}>
        <WittyHeader
          setOpen={setOpen}
          setChatExpanded={setChatExpanded}
          onNewSession={onNewSession}
        />
        <WittyContent
          isMicrophoneActive={isMicrophoneActive}
          isAttachContentActive={isAttachContentActive}
          isFeedbackActive={isFeedbackActive}
        />
      </ChatContextProvider>
    </Box>
  );
};
