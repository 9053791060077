import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { WbCardContent } from '../WbCard';
import { WbCardActionButton } from '../WbCardActionButton';
import {
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
} from '../WbDockable';
import { SelectedValues } from './SelectedEntities';
import { useMultiSelectionPickerContext } from './MultiSelectionPickerContext';
import { AiIcon } from '../Icons/AiIcon';
import { BooleanFilter } from '../WbTableFilters';
import { PermissionEntity } from '@agilelab/plugin-wb-rbac-common';

interface ContentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
  title?: string;
  aiEnabled?: boolean;
  contentHeader?: React.JSX.Element;
  contentHeight?: string;
  onApply?: (permissions: PermissionEntity[]) => {};
}

const useStyles = makeStyles<Theme, { contentHeight: string }>(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: props => props.contentHeight,
      padding: 0,
    },
    title: {
      marginLeft: theme.spacing(1),
    },
    cardContent: {
      padding: theme.spacing(0),
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    childrenBox: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    aiFilterButton: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      width: 'fit-content',
      border: 'solid 1px transparent',
      borderRadius: '4px',
      background:
        'linear-gradient( #ffffff, #ffffff) padding-box,linear-gradient(90deg, #EE72D3, #7C4DFF) border-box',
    },
    dialogActions: {
      padding: theme.spacing(1),
      position: 'sticky',
      bottom: 0,
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      background: theme.palette.white,
    },
  }),
  { name: 'MultiSelection-content' },
);

export const MultiSelectionContent: React.FC<ContentProps> = ({
  setOpen,
  children,
  title,
  aiEnabled = false,
  contentHeader,
  contentHeight = '100%',
  onApply,
}) => {
  const classes = useStyles({ contentHeight });
  const theme = useTheme();
  const {
    setSelectedValues,
    contextSelected,
    setContextSelected,
    fetchAiBestMatch,
    aiState,
    setAiState,
  } = useMultiSelectionPickerContext();

  return (
    <WbCardContent className={classes.root}>
      <WbDockableGroup direction="horizontal">
        <WbDockablePanel defaultSize={70}>
          <WbCardContent className={classes.cardContent}>
            {contentHeader ?? (
              <Box className={classes.title}>
                <Typography variant="body1" style={{ fontWeight: '700' }}>
                  Search for {title}
                </Typography>
              </Box>
            )}

            {aiEnabled && (
              <BooleanFilter
                className={classes.aiFilterButton}
                color={theme.palette.accent.main}
                startIcon={
                  <AiIcon style={{ color: theme.palette.accent.main }} />
                }
                value={aiState}
                field="AI Best Match"
                onChange={checked => {
                  if (checked) {
                    fetchAiBestMatch();
                  }
                  setAiState(checked ?? false);
                }}
              />
            )}

            <Box className={classes.childrenBox}>
              {/* left content */}
              {children}
            </Box>
          </WbCardContent>
        </WbDockablePanel>

        <WbDockableResizeHandle
          showIconOnHover
          divider
          size={4}
          direction="horizontal"
        />

        <WbDockablePanel maxSize={30}>
          <SelectedValues
            values={contextSelected}
            setValues={setContextSelected}
          />
        </WbDockablePanel>
      </WbDockableGroup>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <WbCardActionButton
          onClick={() => {
            onApply?.(contextSelected);
            setSelectedValues(contextSelected);
            setOpen(false);
          }}
          variant="contained"
          size="small"
          color="primary"
          label="Apply"
        />
      </DialogActions>
    </WbCardContent>
  );
};
