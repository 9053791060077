import {
  IdentityProviders,
  SignInProviderConfig,
} from '@backstage/core-components';
import {
  ApiRef,
  atlassianAuthApiRef,
  bitbucketAuthApiRef,
  bitbucketServerAuthApiRef,
  ConfigApi,
  githubAuthApiRef,
  gitlabAuthApiRef,
  googleAuthApiRef,
  microsoftAuthApiRef,
  oktaAuthApiRef,
  oneloginAuthApiRef,
} from '@backstage/core-plugin-api';
import { ldapAuthApiRef } from '../../apis/ldap';
import { oidcAuthApiRef } from '../../apis/oidc';
import { guestUsersAuthApiRef } from '../../apis/guest_users';
import { GuestUsersAuthApi } from '@agilelab/plugin-wb-guest-users-auth-common';

const defaultProviders = new Map<
  string,
  { id: string; title: string; message: string; apiRef: ApiRef<any> }
>([
  [
    'google',
    {
      id: 'google-auth-provider',
      title: 'Google',
      message: 'Sign In using Google',
      apiRef: googleAuthApiRef,
    },
  ],
  [
    'simple_ldap',
    {
      id: 'simple-ldap-auth-provider',
      title: 'LDAP',
      message: 'Sign In using LDAP',
      apiRef: ldapAuthApiRef,
    },
  ],
  [
    'oidc',
    {
      id: 'oidc-auth-provider',
      title: 'OIDC',
      message: 'Sign In using OIDC',
      apiRef: oidcAuthApiRef,
    },
  ],
  [
    'microsoft',
    {
      id: 'microsoft-auth-provider',
      title: 'Microsoft',
      message: 'Sign In using Microsoft Azure AD',
      apiRef: microsoftAuthApiRef,
    },
  ],
  [
    'github',
    {
      id: 'github-auth-provider',
      title: 'GitHub',
      message: 'Sign In using GitHub',
      apiRef: githubAuthApiRef,
    },
  ],
  [
    'gitlab',
    {
      id: 'gitlab-auth-provider',
      title: 'GitLab',
      message: 'Sign In using GitLab',
      apiRef: gitlabAuthApiRef,
    },
  ],
  [
    'okta',
    {
      id: 'okta-auth-provider',
      title: 'Okta',
      message: 'Sign In using Okta',
      apiRef: oktaAuthApiRef,
    },
  ],
  [
    'bitbucket',
    {
      id: 'bitbucket-auth-provider',
      title: 'Bitbucket',
      message: 'Sign In using Bitbucket',
      apiRef: bitbucketAuthApiRef,
    },
  ],
  [
    'onelogin',
    {
      id: 'onelogin-auth-provider',
      title: 'OneLogin',
      message: 'Sign In using OneLogin',
      apiRef: oneloginAuthApiRef,
    },
  ],
  [
    'atlassian',
    {
      id: 'atlassian-auth-provider',
      title: 'Atlassian',
      message: 'Sign In using Atlassian',
      apiRef: atlassianAuthApiRef,
    },
  ],
  [
    'bitbucketServer',
    {
      id: 'bitbucket-server-auth-provider',
      title: 'Bitbucket Server',
      message: 'Sign In using Bitbucket Server',
      apiRef: bitbucketServerAuthApiRef,
    },
  ],
]);

const internalProviders = new Map<
  string,
  { id: string; title: string; message: string; apiRef: ApiRef<any> }
>([
  [
    'simple_guest_users',
    {
      id: 'simple-guest-users-auth-provider',
      title: 'Authenticated Guest User',
      message: 'Sign In with provided guest user credentials',
      apiRef: guestUsersAuthApiRef,
    },
  ],
]);

export function getConfiguredProviders(
  configApi: ConfigApi,
): IdentityProviders {
  const providers: IdentityProviders = [];
  const providersConfig = configApi.getOptionalConfig('auth.providers');
  const authEnvironment = configApi.getOptionalString('auth.environment');
  const configuredProviders = providersConfig?.keys() || [];
  if (providersConfig && authEnvironment) {
    configuredProviders.forEach(provider => {
      if (defaultProviders.has(provider)) {
        const defaultProvider = defaultProviders.get(provider)!;
        providers.push({
          id: defaultProvider.id,
          title:
            providersConfig.getOptionalString(
              `${provider}.${authEnvironment}.title`,
            ) ?? defaultProvider.title,
          message:
            providersConfig.getOptionalString(
              `${provider}.${authEnvironment}.message`,
            ) ?? defaultProvider.message,
          apiRef: defaultProvider.apiRef,
        });
      }
    });
  }
  if (configApi.has('auth.providers.guest')) {
    providers.push('guest');
  }

  return providers;
}

export async function getGuestUserProvider(
  guestUsersAuthApi: GuestUsersAuthApi,
): Promise<SignInProviderConfig | undefined> {
  try {
    const guestUserInfo = await guestUsersAuthApi.getProviderInfo();
    if (guestUserInfo.enabled)
      return internalProviders.get('simple_guest_users')!;
  } catch (error) {
    /* continue regardless of error */
  }
  return undefined;
}
