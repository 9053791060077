import {
  CustomViewComponent,
  CustomViewPageCategory,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import preview from './preview/marketplace_dependencies_component/marketplace_dependencies_component.png';
import thumbnail from './preview/marketplace_dependencies_component/marketplace_dependencies_component_thumbnail.png';

CustomViewPages.register({
  id: 'marketplace_dependencies_component',
  meta: {
    label: 'Dependencies Component Overview',
    category: CustomViewPageCategory.MARKETPLACE,
    preview: {
      thumbnail: { src: thumbnail },
      items: [{ src: preview }],
    },
  },
  function: function Entity() {
    return (
      <CustomViewComponent type="card_content">
        <CustomViewComponent type="hbox">
          <CustomViewComponent type="container">
            <CustomViewComponent
              label="Description"
              value="{{description}}"
              type="string"
              maxLines="100"
              colSpan="4"
            />
            <CustomViewComponent
              type="hline"
              showWhen={{
                value: '{{ tags.length }}',
                notEquals: 0,
              }}
            />
            <CustomViewComponent
              showWhen={{
                value: '{{tags.length}}',
                notEquals: 0,
              }}
              label="Tags"
              type="tags"
              path="tags"
            />
            <CustomViewComponent type="marketplace_flag_and_score" />
          </CustomViewComponent>
        </CustomViewComponent>
      </CustomViewComponent>
    );
  },
});
