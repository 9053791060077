import { Box, BoxProps, Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetApp from '@material-ui/icons/GetApp';
import React, { Fragment } from 'react';
import { WbCardActionButton } from '../../WbCardActionButton';

interface ComparisonActionsProps {
  onCopyClick?: (arg: any) => Promise<void>;
  onDownloadClick?: () => void;
  beforeActions?: JSX.Element[];
  additionalActions?: JSX.Element[];
  style?: BoxProps;
}

export const ComparisonActions: React.FC<ComparisonActionsProps> = ({
  onCopyClick,
  onDownloadClick,
  additionalActions,
  beforeActions,
  style,
}) => {
  return (
    <Box style={{ ...style, display: 'flex', alignItems: 'center' }}>
      {beforeActions?.map(action => (
        <Fragment key={action.key}>{action}</Fragment>
      ))}
      {onCopyClick && (
        <Tooltip title="Copy catalog-info.yaml from the right panel">
          <WbCardActionButton
            style={{
              marginRight: 20,
            }}
            onClick={onCopyClick}
            color="secondary"
            variant="text"
            label="Copy"
            icon={<FileCopyIcon />}
            iconColor="secondary"
          />
        </Tooltip>
      )}
      {onDownloadClick && (
        <Tooltip title="Download catalog-info.yaml from the right panel">
          <WbCardActionButton
            style={{
              marginRight: 20,
            }}
            onClick={onDownloadClick}
            color="secondary"
            variant="text"
            label="Download"
            icon={<GetApp />}
            iconColor="secondary"
          />
        </Tooltip>
      )}
      {additionalActions?.map(action => (
        <Fragment key={action.key}>{action}</Fragment>
      ))}
    </Box>
  );
};
