import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const GroupOutputPortIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2856 23.5712C16.105 23.5711 17.8843 24.1054 19.4026 25.1079C20.9208 26.1104 22.1111 27.5368 22.8257 29.21C23.5402 30.8832 23.7474 32.7295 23.4216 34.5195C23.0958 36.3094 22.2514 37.9642 20.9932 39.2784C19.7349 40.5925 18.1184 41.5081 16.3443 41.9114C14.5701 42.3146 12.7166 42.1878 11.014 41.5467C9.3113 40.9056 7.83449 39.7784 6.76697 38.3051C5.69945 36.8318 5.08825 35.0774 5.00929 33.2597L5 32.8568L5.00929 32.4538C5.11313 30.0632 6.13583 27.805 7.86409 26.1501C9.59235 24.4952 11.8928 23.5713 14.2856 23.5712ZM34.7139 23.5712C36.5332 23.5711 38.3126 24.1054 39.8308 25.1079C41.3491 26.1104 42.5394 27.5368 43.2539 29.21C43.9685 30.8832 44.1757 32.7295 43.8499 34.5195C43.5241 36.3094 42.6797 37.9642 41.4215 39.2784C40.1632 40.5925 38.5467 41.5081 36.7725 41.9114C34.9984 42.3146 33.1449 42.1878 31.4423 41.5467C29.7396 40.9056 28.2628 39.7784 27.1953 38.3051C26.1277 36.8318 25.5165 35.0774 25.4376 33.2597L25.4283 32.8568L25.4376 32.4538C25.5414 30.0632 26.5641 27.805 28.2924 26.1501C30.0206 24.4952 32.321 23.5713 34.7139 23.5712ZM24.4997 5C26.3191 4.99989 28.0984 5.53427 29.6167 6.53676C31.135 7.53925 32.3253 8.96568 33.0398 10.6389C33.7543 12.3121 33.9616 14.1583 33.6358 15.9483C33.31 17.7383 32.4655 19.3931 31.2073 20.7072C29.9491 22.0214 28.3325 22.9369 26.5584 23.3402C24.7843 23.7435 22.9308 23.6167 21.2281 22.9755C19.5254 22.3344 18.0486 21.2072 16.9811 19.7339C15.9136 18.2607 15.3024 16.5062 15.2234 14.6886L15.2141 14.2856L15.2234 13.8826C15.3273 11.492 16.35 9.23381 18.0782 7.5789C19.8065 5.92399 22.1069 5.00014 24.4997 5Z"
      fill="currentColor"
    />
  </svg>,
  'GroupOutputPortIcon',
);
