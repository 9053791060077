import { z } from 'zod';
import { GuestUser, GuestUserRequest, SafeGuestUser } from './model';

export const GetUsersQueryParamsZod = z.object({
  enabled: z.boolean().optional(),
});

export type GetUsersQueryParams = z.infer<typeof GetUsersQueryParamsZod>;

export type GuestUsersAuthOptions = {
  options?: { token?: string };
};

export type UpsertUsersParams = GuestUsersAuthOptions & {
  users: GuestUserRequest[];
};

export type GetUserParams = GuestUsersAuthOptions & {
  username: string;
  filter?: GetUsersQueryParams;
};

export type GetUsersParams = GuestUsersAuthOptions & {
  filter?: GetUsersQueryParams;
};

export type GuestUsersAuthProviderInfo = {
  id: string;
  enabled: boolean;
};

/**
 * GuestUsers Api definition for both frontend and backend clients
 */
export interface GuestUsersAuthApi {
  getProviderInfo(): Promise<GuestUsersAuthProviderInfo>;

  upsertUsers(params: UpsertUsersParams): Promise<GuestUser[]>;

  disableAll(params: GuestUsersAuthOptions): Promise<void>;

  getUser(params: GetUserParams): Promise<SafeGuestUser | undefined>;

  getUsers(params: GetUsersParams): Promise<GuestUser[]>;

  refresh(params: GuestUsersAuthOptions): Promise<void>;
}
