import React from 'react';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AiIcon } from '@agilelab/plugin-wb-platform';
import { WittyIcon } from '../WittyIcon';

const useStyles = makeStyles(theme => ({
  circularProgress: {
    color: theme.palette.accent.main,
  },
  icon: {
    height: '0.8em',
    width: '0.8em',
    color: theme.palette.accent.main,
  },
  adornedStart: {
    paddingLeft: '8px !important', // forces padding-left to 8px
  },
  iconButton: {
    minWidth: 0,
    padding: 0,
  },
}));

export type WittyInputAdornmentProps = {
  disabled?: boolean;
  loading: boolean;
  onClick: () => void;
  iconStyle?: React.CSSProperties;
};

export const WittyInputAdornment = ({
  disabled,
  loading,
  onClick,
  iconStyle,
}: WittyInputAdornmentProps) => {
  const classes = useStyles();
  const theme = useTheme();

  if (loading) {
    return (
      <InputAdornment position="start">
        <IconButton className={classes.iconButton} size="small" disabled>
          <CircularProgress size="1em" className={classes.circularProgress} />
        </IconButton>
      </InputAdornment>
    );
  }

  return (
    <InputAdornment position="start">
      <IconButton
        onClick={onClick}
        className={classes.iconButton}
        size="small"
        disabled={disabled}
      >
        {disabled ? (
          <AiIcon
            className={classes.icon}
            style={{
              ...iconStyle,
              color: theme.palette.grey[500],
            }}
          />
        ) : (
          <WittyIcon className={classes.icon} style={iconStyle} />
        )}
      </IconButton>
    </InputAdornment>
  );
};
