import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/Trait.v2alpha1.schema.json';

declare interface TraitEntity extends Entity {
  apiVersion: 'witboost.com/v2';
  kind: 'Trait';
  metadata: EntityMeta;
  spec?: {
    name?: string;
    abstract: true;
    schema?: object;
    extends?: string[];
    whitelist?: string[];
    blacklist?: string[];
  };
}

export type { TraitEntity as Trait };

export const traitV1alpha1Validator = generateAjvJsonSchemaValidator(schema);

export function isTrait(entity: Entity): entity is TraitEntity {
  return entity.apiVersion === 'witboost.com/v2' && entity.kind === 'Trait';
}
