import { useApi } from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';
import { ComponentIcon, SystemIcon } from '../icons/EntityIcons';

type Icon = () => React.JSX.Element;

type ImgIcon = { src: string; type: 'img' };

type SvgIcon = { icon: Icon; type: 'svg' };

export type EntityIconType = ImgIcon | SvgIcon | undefined;

export type GetIcon = (
  useCaseTemplateId: string,
  kind: string,
) => EntityIconType;

const KIND_TO_ICON = new Map<string, Icon>(
  Object.entries({
    system: SystemIcon,
    component: ComponentIcon,
  }),
);

const getData = async (catalogApi: CatalogApi) => {
  const response = await catalogApi.getEntities({
    filter: { kind: ['Template'] },
  });

  return response;
};

export const useEntityIcon = () => {
  const catalogApi = useApi(catalogApiRef);

  const { data } = useQuery({
    queryKey: ['templates'],
    queryFn: async () => {
      return getData(catalogApi);
    },
    // refetches if the data is needed and 30 minutes have passed from the last fetch
    staleTime: 1000 * 60 * 30,
  });

  const idToIcon = useMemo(() => {
    const map = new Map<string, string>();
    if (!data) return map;

    // build a map useCaseTemplateId -> icon from templates
    data.items.forEach(template => {
      const steps = (template.spec?.steps as any) || [];
      steps.forEach((step: any) => {
        const useCaseTemplateId = step.input?.values?.useCaseTemplateId;
        const icon = (template.metadata?.mesh as any)?.icon;
        if (useCaseTemplateId) map.set(useCaseTemplateId, icon);
      });
    });
    return map;
  }, [data]);

  // if kind is system use always the system icon, if useCaseTemplateId is in the map return that icon, otherwise fallback to KIND_TO_ICON map
  const getIcon = useCallback<GetIcon>(
    (useCaseTemplateId: string, kind: string) => {
      const icon = KIND_TO_ICON.get(kind);

      if (kind === 'system') {
        if (icon) return { icon, type: 'svg' };
      }

      const imgIcon = idToIcon.get(useCaseTemplateId);
      if (imgIcon) return { src: imgIcon, type: 'img' };

      if (icon) return { icon, type: 'svg' };

      return undefined;
    },
    [idToIcon],
  );

  return { getIcon };
};
