import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/EditTemplate.v1alpha1.schema.json';
import { JsonObject } from '@backstage/types';
import { TemplateStep } from './types';

export const EditTemplateFileName = 'edit-template.yaml';

declare interface EditTemplateEntity extends Entity {
  /**
   * v2 enables support for skeleton entities"
   */
  apiVersion: 'witboost.com/v1' | 'witboost.com/v2';
  kind: 'EditTemplate';
  metadata: EntityMeta;
  spec: {
    owner?: string;
    domain?: string;
    /**
     * witboost use case template references
     */
    useCaseTemplateRef?: string[];
    /**
     * Use Case Template Ids
     */
    useCaseTemplateId?: string[];
    /**
     * JSONSchemas which is used to render a form in the frontend
     * to collect user input and validate it against that schema.
     */
    parameters?: JsonObject[];
    steps: Array<TemplateStep>;
    /**
     * Compatibility settings
     */
    compatibility?: {
      /**
       * (backward) Compatibility settings for legacy entities used when `apiVersion` is `witboost.com/v2`
       */
      legacyEntities?: {
        /**
         * Whether the edit operation can be performed with this template on legacy entities
         */
        executionPolicy?: 'ALLOWED' | 'NOT_ALLOWED';
      };
      /**
       * (forward) Compatibility settings for skeleton entities used when `apiVersion` is `witboost.com/v1`
       */
      skeletonEntities?: {
        /**
         * Whether the edit operation can be performed with this template on skeleton entities
         */
        executionPolicy?: 'ALLOWED' | 'ALLOWED_WITH_WARNING' | 'NOT_ALLOWED';
      };
    };
  };
}

export type { EditTemplateEntity as EditTemplate };

export const editTemplateEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
