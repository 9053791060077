import {
  Pagination,
  WbTable,
  TableCellProps,
} from '@agilelab/plugin-wb-platform';
import {
  Box,
  createStyles,
  FormControl,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { useMultiSelectionPickerContext } from './MultiSelectionPickerContext';

type GenericElement = {
  name?: string;
  displayName?: string;
  domain?: string;
  description?: string;
};

interface SelectableTableProps<T extends GenericElement> {
  loading: boolean;
  data: T[];
  columns?: TableCellProps<T>[];
  pagination?: Pagination;
  setPagination?: React.Dispatch<React.SetStateAction<Pagination>>;
  getRowId: (row: T) => string;
}

const useStyles = makeStyles(
  theme =>
    createStyles({
      tableContainer: {
        flex: 1,
        overflowY: 'auto',
      },
      formControl: {
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      },
      boxBest: {
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        backgroundColor: theme.palette.white,
        border: 'solid 1px transparent',
        borderRadius: '4px',
        background:
          'linear-gradient( #ffffff, #ffffff) padding-box,linear-gradient(90deg, #EE72D3, #7C4DFF) border-box',
        whiteSpace: 'nowrap',
      },
      aiSuggestedRow: {
        backgroundColor: `${theme.palette.bkg.accent} !important`,
        '& td': {
          borderBottom: `1px solid ${theme.palette.accent.light}`,
        },
      },
    }),
  { name: 'selectionTable' },
);

export function SelectableTable<T extends GenericElement>({
  loading,
  data,
  columns,
  getRowId,
}: SelectableTableProps<T>) {
  const classes = useStyles();
  const { contextSelected, setContextSelected, isAiGenerated } =
    useMultiSelectionPickerContext();

  const defaultColumns = useMemo(() => {
    const cols = [
      {
        headerName: 'Name',
        field: 'name',
        fieldRender: (field: T) => {
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>{field.name ?? field.displayName}</Typography>
              {isAiGenerated(field) && (
                <Typography className={classes.boxBest} variant="caption">
                  Best match
                </Typography>
              )}
            </Box>
          );
        },
      },
    ] as TableCellProps<T>[];

    if (data.some(d => d.domain)) {
      cols.push({
        headerName: 'Domain',
        field: 'domain',
      });
    }
    if (data.some(d => d.description)) {
      cols.push({
        headerName: 'Description',
        field: 'description',
      });
    }

    return cols;
  }, [classes.boxBest, data, isAiGenerated]);

  return (
    <>
      <FormControl className={classes.formControl}>
        <WbTable
          stickyHeader
          classNames={{ container: classes.tableContainer }}
          selection="multiple"
          selectedItems={contextSelected}
          setSelectedItems={setContextSelected}
          components={{
            tableLoader: { loading },
            tableContent: {
              getRowId,
              columns: columns ?? defaultColumns,
              rows: data,
              rowDynamicClassName: row => {
                return isAiGenerated(row) ? classes.aiSuggestedRow : undefined;
              },
            },
          }}
        />
      </FormControl>
    </>
  );
}
