import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/Taxonomy.v2alpha1.schema.json';

declare interface TaxonomyEntity extends Entity {
  apiVersion: 'witboost.com/v2';
  kind: 'Taxonomy';
  metadata: EntityMeta & { displayName?: string };
  spec: {
    enabled: boolean;
    instanceOf?: string;
    implements?: string[];
    extends?: string[];
    schema?: object;
  };
}

export type { TaxonomyEntity as Taxonomy };

export const taxonomyEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);

export function isTaxonomy(entity: Entity): entity is TaxonomyEntity {
  return entity.apiVersion === 'witboost.com/v2' && entity.kind === 'Taxonomy';
}
